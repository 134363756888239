import GMarker from '../marker'
import track2Icon from '../../../../assets/images/track-2.png'
import { FC, useMemo } from 'react'

interface IProps {
  heading: number
  position: google.maps.LatLng | google.maps.LatLngLiteral
  isMapLoaded: boolean
  onClick?: (e: google.maps.MapMouseEvent) => void
}

const GMarkerTrack2: FC<IProps> = (props) => {
  const { isMapLoaded, position, heading, onClick } = props

  // const track2 = useMemo(() => {
  //   if (!isMapLoaded) return undefined

  //   const canvasSize = 120 // Increased canvas size
  //   const halfSize = canvasSize / 2
  //   const svg = `
  //     <svg width="${canvasSize}" height="${canvasSize}" xmlns="http://www.w3.org/2000/svg">
  //       <image href="${track2Icon}" x="${halfSize - 15}" y="${
  //     halfSize - 44
  //   }" transform="rotate(${heading}, ${halfSize}, ${halfSize})" width="30" height="88"/>
  //     </svg>
  //   `

  //   return `data:image/svg+xml,${encodeURIComponent(svg)}`
  // }, [isMapLoaded, heading])

  const track2 = useMemo(
    () =>
      isMapLoaded
        ? {
            url: track2Icon, // url
            scaledSize: new window.google.maps.Size(30, 88), // size
          }
        : undefined,
    [isMapLoaded]
  )

  return <GMarker position={position} icon={track2} onClick={onClick} />
}

export default GMarkerTrack2
