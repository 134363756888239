import {
	Button,
	Card,
	Col,
	DatePicker,
	Form,
	FormInstance,
	Input,
	Row,
	Select,
	Space
} from 'antd'
import axios from 'axios'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { CheckOutlined } from '@ant-design/icons'

import MapLocationPicker from '../../../components/common/map/location-picker'
import { IClientLight } from '../../../models/client'
import { IDriverLight } from '../../../models/driver'
import { IPaginatedList } from '../../../models/paginated-list'
import {
	DATE_TIME_FORMAT,
	DEFAULT_GOOGLE_MAP_PROPS,
	MAX_PAGE_SIZE
} from '../../../utils/constants'
import styles from './style.module.css'
import React from 'react'
import { DurationType, RequestStatus } from '../../../models/request'
import {
	IVehicleCategoryLight,
	VehicleCategoryType
} from '../../../models/vehicle-category'
import VehicleCategoryTypeTag from '../../../components/enums/vehicle-category-type-tag'

const AddRequestPage = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const formRef = React.useRef<FormInstance>(null)

	const queryClient = useQueryClient()

	const driversQuery = useQuery({
		queryKey: ['drivers-light'],
		queryFn: async () => {
			return axios.get<IPaginatedList<IDriverLight>>('/drivers', {
				params: {
					minimal: 1,
					perPage: MAX_PAGE_SIZE
				}
			})
		}
	})

	const clientsQuery = useQuery({
		queryKey: ['clients-light'],
		queryFn: async () => {
			return axios.get<IPaginatedList<IClientLight>>('/clients', {
				params: {
					minimal: 1,
					perPage: MAX_PAGE_SIZE
				}
			})
		}
	})

	const categoriesQuery = useQuery({
		queryKey: ['vehicle-categories-light'],
		queryFn: async () => {
			return axios.get<IPaginatedList<IVehicleCategoryLight>>('/category', {
				params: {
					minimal: 1,
					perPage: MAX_PAGE_SIZE
				}
			})
		}
	})

	const mutation = useMutation({
		mutationFn: (data: any) => {
			return axios.post('/request', data)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['requests'] })
			navigate(-1)
		}
	})

	const onSubmit = (data: any) => {
		mutation.mutate({
			clientId: data['clientId'],
			driverId: data['driverId'],
			durationType: data['durationType'],
			scheduledDate: data['scheduledDate'],
			categoryId: data['categoryId'],
			requiredDuration: data['requiredDuration'],
			status: data['status'],
			price: data['price'],
			weight: data['weight'],
			startLat: data['startLocation'].lat,
			startLong: data['startLocation'].lng,
			endLat: data['endLocation'].lat,
			endLong: data['endLocation'].lng,
			description: data['description'],
			notes: data['notes']
		})
	}

	const [startLocation, setStartLocation] = useState(
		DEFAULT_GOOGLE_MAP_PROPS.center
	)

	const [endLocation, setEndLocation] = useState(
		DEFAULT_GOOGLE_MAP_PROPS.center
	)

	const [durationType, setDurationType] = useState<DurationType>()

	const [vehicleCategoryType, setVehicleCategoryType] =
		useState<VehicleCategoryType>()

	return (
		<Form
			name='form_item_path'
			layout='vertical'
			onFinish={onSubmit}
			ref={formRef}
			initialValues={{
				startLocation: DEFAULT_GOOGLE_MAP_PROPS.center,
				endLocation: DEFAULT_GOOGLE_MAP_PROPS.center
			}}
		>
			<Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
				<div className={styles.headerCardTitle}>
					<h3>{t('request.add')}</h3>
					<div className={styles.headerCardTitleTrailing}>
						<Button
							type='default'
							disabled={mutation.isLoading}
							onClick={() => navigate(-1)}
						>
							{t('general.cancel')}
						</Button>
						<Button
							className={styles.saveButton}
							type='primary'
							htmlType='submit'
							icon={<CheckOutlined />}
							loading={mutation.isLoading}
						>
							{t('general.save')}
						</Button>
					</div>
				</div>
			</Card>
			<Card bodyStyle={{ padding: '1rem' }}>
				<Form.Item
					name='clientId'
					label={t('request.client')}
					required
					rules={[{ required: true, message: t('validation.required') }]}
				>
					<Select
						loading={clientsQuery.isLoading}
						options={clientsQuery.data?.data.data.map((client) => {
							return {
								label: `#${client.id}) ${client.firstName} ${client.lastName}`,
								value: client.id
							}
						})}
						showSearch
					/>
				</Form.Item>
				<Form.Item
					name='driverId'
					label={t('request.driver')}
					required
					rules={[{ required: true, message: t('validation.required') }]}
				>
					<Select
						loading={driversQuery.isLoading}
						options={driversQuery.data?.data.data.map((driver) => {
							return {
								label: `#${driver.id}) ${driver.firstName} ${driver.lastName}`,
								value: driver.id
							}
						})}
						showSearch
					/>
				</Form.Item>
				<Form.Item
					name='durationType'
					label={t('request.duration_type')}
					required
					rules={[{ required: true, message: t('validation.required') }]}
				>
					<Select
						onChange={(value) => setDurationType(value)}
						options={[
							{
								label: t('request.immediate'),
								value: DurationType.QUICKLY
							},
							{
								label: t('request.scheduled'),
								value: DurationType.SCHEDULER
							}
						]}
					/>
				</Form.Item>
				{durationType === DurationType.SCHEDULER && (
					<Form.Item
						name='scheduledDate'
						label={t('request.scheduled_date')}
						required
						rules={[{ required: true, message: t('validation.required') }]}
					>
						<DatePicker
							style={{ display: 'block' }}
							format={DATE_TIME_FORMAT}
							showTime
						/>
					</Form.Item>
				)}
				<Form.Item
					name='categoryId'
					label={t('request.vehicle_category')}
					required
					rules={[{ required: true, message: t('validation.required') }]}
				>
					<Select
						loading={categoriesQuery.isLoading}
						onChange={(value) => {
							var category = categoriesQuery.data?.data.data.find(
								(c) => c.id === value
							)
							setVehicleCategoryType(category?.type)
						}}
						options={categoriesQuery.data?.data.data.map((category) => {
							return {
								label: (
									<Space>
										{`#${category.id}) ${category.name}`}
										<VehicleCategoryTypeTag type={category.type} />
									</Space>
								),
								value: category.id
							}
						})}
						showSearch
					/>
				</Form.Item>
				{vehicleCategoryType &&
					vehicleCategoryType !== VehicleCategoryType.TRANSPORT && (
						<Form.Item
							name='requiredDuration'
							label={t('request.required_duration')}
							required
							rules={[{ required: true, message: t('validation.required') }]}
						>
							<Input type='number' />
						</Form.Item>
					)}
				<Form.Item
					name='status'
					label={t('request.status')}
					required
					rules={[{ required: true, message: t('validation.required') }]}
				>
					<Select
						options={[
							{
								label: t('request.suspended_by_driver'),
								value: RequestStatus.SUSPENDED_BY_DRIVER
							},
							{
								label: t('request.suspended_by_client'),
								value: RequestStatus.SUSPENDED_BY_CUSTOMER
							},
							{
								label: t('request.canceled_by_driver'),
								value: RequestStatus.CANCELED_BY_DRIVER
							},
							{
								label: t('request.canceled_by_client'),
								value: RequestStatus.CANCELED_BY_CUSTOMER
							},
							{
								label: t('request.approved'),
								value: RequestStatus.APPROVED
							},
							{
								label: t('request.on_the_way'),
								value: RequestStatus.ON_THE_WAY
							},
							{
								label: t('request.site_has_been_reached'),
								value: RequestStatus.SITE_HAS_BEEN_REACHED
							},
							{
								label: t('request.in_progress'),
								value: RequestStatus.IN_PROGRESS
							},
							{
								label: t('request.complete'),
								value: RequestStatus.COMPLETE
							},
							{
								label: t('request.closed'),
								value: RequestStatus.CLOSED
							}
						]}
					/>
				</Form.Item>
				<Form.Item name='weight' label={t('request.weight')}>
					<Input type='number' />
				</Form.Item>
				<Form.Item name='price' label={t('request.price')}>
					<Input type='number' />
				</Form.Item>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name='startLocation'
							label={t('request.start_location')}
							required
							rules={[{ required: true, message: t('validation.required') }]}
						>
							<MapLocationPicker
								value={startLocation}
								onChange={(value) => {
									setStartLocation(value)
									formRef.current?.setFieldValue('startLocation', value)
								}}
							/>
						</Form.Item>
					</Col>
					{vehicleCategoryType &&
						vehicleCategoryType === VehicleCategoryType.TRANSPORT && (
							<Col span={12}>
								<Form.Item
									name='endLocation'
									label={t('request.end_location')}
									required
									rules={[
										{ required: true, message: t('validation.required') }
									]}
								>
									<MapLocationPicker
										value={endLocation}
										onChange={(value) => {
											setEndLocation(value)
											formRef.current?.setFieldValue('endLocation', value)
										}}
									/>
								</Form.Item>
							</Col>
						)}
				</Row>
				<Form.Item name='description' label={t('request.description')}>
					<Input.TextArea />
				</Form.Item>
				<Form.Item name='notes' label={t('request.notes')}>
					<Input.TextArea />
				</Form.Item>
			</Card>
		</Form>
	)
}

export default AddRequestPage
