/**
 *  City
 */
export const CAN_VIEW_CITIES = 'can-view-cities'
export const CAN_CREATE_CITY = 'can-create-city'
export const CAN_UPDATE_CITY = 'can-update-city'
export const CAN_DELETE_CITY = 'can-delete-city'
export const CAN_EXPORT_CITIES = 'can-export-cities'

/**
 *  Reward
 */
export const CAN_VIEW_REWARDS = 'can-view-rewards'
export const CAN_CREATE_REWARD = 'can-create-reward'
export const CAN_UPDATE_REWARD = 'can-update-reward'
export const CAN_DELETE_REWARD = 'can-delete-reward'
export const CAN_EXPORT_REWARDS = 'can-export-rewards'

/**
 *  Coupon
 */
export const CAN_VIEW_COUPONS = 'can-view-coupons'
export const CAN_CREATE_COUPON = 'can-create-coupon'
export const CAN_UPDATE_COUPON = 'can-update-coupon'
export const CAN_DELETE_COUPON = 'can-delete-coupon'
export const CAN_EXPORT_COUPONS = 'can-export-coupons'

/**
 *  Users
 */
export const CAN_VIEW_USERS = 'can-view-users'
export const CAN_CREATE_USER = 'can-create-user'
export const CAN_UPDATE_USER = 'can-update-user'
export const CAN_DELETE_USER = 'can-delete-user'
export const CAN_EXPORT_USERS = 'can-export-users'

/**
 *  Requests
 */
export const CAN_VIEW_REQUESTS = 'can-view-requests'
export const CAN_CREATE_REQUEST = 'can-create-request'
export const CAN_UPDATE_REQUEST = 'can-update-request'
export const CAN_DELETE_REQUEST = 'can-delete-request'
export const CAN_EXPORT_REQUESTS = 'can-export-requests'

export const CAN_MANAGE_A_REQUEST = 'can-manage-a-request'
export const CAN_SEARCH_ABOUT_DRIVER_REQUEST = 'can-search-about-driver-request'
export const CAN_CREATE_REQUEST_FROM_APP = 'can-create-request-from-app'
export const CAN_SELECT_DRIVER_FOR_REQUEST = 'can-select-driver-for-request'
export const CAN_CHANGE_PRICE_REQUEST = 'can-change-price-request'
export const CAN_ACCEPT_REQUEST = 'can-accept-request'
export const CAN_CANCEL_REQUEST = 'can-cancel-request'
export const CAN_COMPLETE_REQUEST = 'can-complete-request'

/**
 *  Notification-public
 */
export const CAN_VIEW_NOTIFICATION_PUBLIC = 'can-view-notification-public'
export const CAN_CREATE_NOTIFICATION_PUBLIC = 'can-create-notification-public'
export const CAN_UPDATE_NOTIFICATION_PUBLIC = 'can-update-notification-public'
export const CAN_DELETE_NOTIFICATION_PUBLIC = 'can-delete-notification-public'
export const CAN_EXPORT_NOTIFICATION_PUBLIC = 'can-export-notification-public'

/**
 *  Capacities
 */
export const CAN_VIEW_CAPACITIES = 'can-view-capacities'
export const CAN_CREATE_CAPACITY = 'can-create-capacity'
export const CAN_UPDATE_CAPACITY = 'can-update-capacity'
export const CAN_DELETE_CAPACITY = 'can-delete-capacity'
export const CAN_EXPORT_CAPACITIES = 'can-export-capacities'

/**
 *  Categories
 */
export const CAN_VIEW_CATEGORIES = 'can-view-categories'
export const CAN_CREATE_CATEGORY = 'can-create-category'
export const CAN_UPDATE_CATEGORY = 'can-update-category'
export const CAN_DELETE_CATEGORY = 'can-delete-category'
export const CAN_EXPORT_CATEGORIES = 'can-export-categories'

/**
 *  Accident
 */
export const CAN_VIEW_ACCIDENTS = 'can-view-accidents'
export const CAN_CREATE_ACCIDENT = 'can-create-accident'
export const CAN_UPDATE_ACCIDENT = 'can-update-accident'
export const CAN_DELETE_ACCIDENT = 'can-delete-accident'
export const CAN_EXPORT_ACCIDENTS = 'can-export-accidents'

/**
 *  Role
 */
export const CAN_VIEW_ROLES = 'can-view-roles'
export const CAN_CREATE_ROLE = 'can-create-role'
export const CAN_UPDATE_ROLE = 'can-update-role'
export const CAN_DELETE_ROLE = 'can-delete-role'
export const CAN_EXPORT_ROLES = 'can-export-roles'

/**
 *  Permission
 */
export const CAN_VIEW_PERMISSIONS = 'can-view-permissions'

/**
 *  Config
 */
export const CAN_VIEW_CONFIG = 'can-view-config'
export const CAN_UPDATE_CONFIG = 'can-update-config'

/**
 *  Payments
 */
export const CAN_VIEW_PAYMENTS = 'can-view-payments'
export const CAN_CREATE_PAYMENT = 'can-create-payment'
export const CAN_EXPORT_PAYMENTS = 'can-export-payments'

/**
 *  Driver
 */
export const CAN_VIEW_DRIVERS = 'can-view-drivers'
export const CAN_VIEW_DRIVERS_WITH_TRASH = 'can-view-clients-with-trash'
export const CAN_UPDATE_DRIVER = 'can-update-driver'
export const CAN_EXPORT_DRIVERS = 'can-export-drivers'
export const CAN_DELETE_DRIVER = 'can-delete-driver'

/**
 *  Client
 */
export const CAN_VIEW_CLIENTS = 'can-view-clients'
export const CAN_VIEW_CLIENTS_WITH_TRASH = 'can-view-clients-with-trash'
export const CAN_UPDATE_CLIENT = 'can-update-client'
export const CAN_EXPORT_CLIENTS = 'can-export-clients'
export const CAN_DELETE_CLIENT = 'can-delete-client'
