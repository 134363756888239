import { isArray, xor } from 'lodash'

export const toggleLoading = (loading: any[], toggle: any[] | any) => {
  return xor(loading, isArray(toggle) ? toggle : [toggle])
}

export const removeUndefinedProps = (obj: any) => {
  return Object.entries(obj)
    .filter(([key, value]) => value !== undefined)
    .reduce((acc, [key, value]) => {
      acc[key] = value
      return acc
    }, {} as any)
}
