import axios from 'axios'
import dayjs from 'dayjs'
import HeaderCard from '../../../components/common/header-card'
import VehicleCategoryTypeTag from '../../../components/enums/vehicle-category-type-tag'
import { Card, Image, Table, Tooltip, Typography } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { IMedia } from '../../../models/media'
import { IPaginatedList } from '../../../models/paginated-list'
import { IUserLight } from '../../../models/user'
import { IVehicleCapacityLight } from '../../../models/vehicle-capacity'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DATE_TIME_FORMAT,
  DEFAULT_PAGE_SIZE,
  INITIAL_PAGE,
} from '../../../utils/constants'
import {
  IVehicleCategoryDetails,
  VehicleCategoryType,
} from '../../../models/vehicle-category'

const { Text } = Typography

const VehicleCategoryHistoryPage = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  const [page, setPage] = useState(INITIAL_PAGE)
  const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE)
  const [search, setSearch] = useState<string | undefined>()

  const query = useQuery({
    queryKey: ['vehicle-history', page, perPage, search],
    queryFn: async () => {
      return axios.get<IPaginatedList<IVehicleCategoryDetails>>(
        '/category-histories',
        {
          params: {
            page,
            perPage,
            search,
            filters: [
              {
                name: 'categoryId',
                operation: '=',
                value: id,
              },
            ],
          },
        }
      )
    },
  })

  const columns: ColumnsType<IVehicleCategoryDetails> = [
    {
      title: t('general.id'),
      dataIndex: 'id',
      render: (id) => <Link to={`/vehicle-category/${id}`}>{id}</Link>,
    },
    {
      title: t('vehicle_category.photo'),
      dataIndex: 'photo',
      render: (photo: IMedia) => {
        return (
          photo?.url && (
            <Image
              src={photo.url}
              width={42}
              height={42}
              preview
              style={{
                borderRadius: '8px',
                overflow: 'hidden',
              }}
            />
          )
        )
      },
    },
    {
      title: t('vehicle_category.name'),
      dataIndex: 'name',
    },
    {
      title: t('vehicle_category.type'),
      dataIndex: 'type',
      render: (type: VehicleCategoryType) => {
        return <VehicleCategoryTypeTag type={type} />
      },
    },
    {
      title: t('vehicle_category.capacity'),
      dataIndex: 'capacity',
      render: (capacity: IVehicleCapacityLight) => capacity.name,
    },
    {
      title: t('vehicle_category.points'),
      dataIndex: 'points',
    },
    {
      title: t('vehicle_category.note'),
      dataIndex: 'note',
      render: (note: string) => {
        return (
          <Tooltip title={note}>
            <Text
              style={{
                width: 100,
              }}
              ellipsis
            >
              {note}
            </Text>
          </Tooltip>
        )
      },
    },
    {
      title: t('general.created_at'),
      dataIndex: 'createdAt',
      render: (createdAt: Date) => {
        return dayjs(createdAt).format(DATE_TIME_FORMAT)
      },
    },
    {
      title: t('general.created_by'),
      dataIndex: 'createdBy',
      render: (createdBy: IUserLight) => {
        return createdBy?.firstName
      },
    },
    {
      title: t('general.updated_at'),
      dataIndex: 'updatedAt',
      render: (updatedAt: Date) => {
        return dayjs(updatedAt).format(DATE_TIME_FORMAT)
      },
    },
    {
      title: t('general.updated_by'),
      dataIndex: 'updatedBy',
      render: (updatedBy: IUserLight) => {
        return updatedBy?.firstName
      },
    },
  ]

  return (
    <>
      <HeaderCard
        title={t('vehicle_category.history')}
        onSearch={(value) => {
          setSearch(value.length === 0 ? undefined : value)
        }}
      />
      <Card bordered={false} bodyStyle={{ padding: 0 }}>
        <Table
          loading={query.isFetching}
          columns={columns}
          dataSource={query.data?.data.data}
          rowKey={(record) => record.id}
          style={{ overflowX: 'scroll' }}
          pagination={{
            current: page,
            pageSize: perPage,
            pageSizeOptions: [5, 10, 20, 50, 100],
            showSizeChanger: true,
            total: query.data?.data.total,
            position: ['bottomCenter'],
            onChange(page, pageSize) {
              setPage(page)
              setPerPage(pageSize)
            },
            showTotal: (total, range) => {
              return `${t('general.showing')} ${range[0]} - ${range[1]} ${t(
                'general.from'
              )} ${total} ${t('general.items')}`
            },
          }}
        />
      </Card>
    </>
  )
}

export default VehicleCategoryHistoryPage
