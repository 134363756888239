import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { DurationType } from '../../../models/request'

interface IProps {
	type: DurationType
}

const DurationTypeTag: React.FC<IProps> = ({ type }) => {
	const { t } = useTranslation()

	let label = ''
	let color = ''

	switch (type) {
		case DurationType.QUICKLY:
			label = t('request.immediate')
			color = 'orange'
			break
		case DurationType.SCHEDULER:
			label = t('request.scheduled')
			color = 'cyan'
			break
	}

	return <Tag color={color}>{label}</Tag>
}

export default DurationTypeTag
