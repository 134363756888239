import GMap from '../../common/map'
import GMarkerTrack1 from '../../common/map/custom-markers/track1'
import IDriverLocationModel from '../../../models/socket-models'
import SocketContext from '../../../contexts/socket-context/context'
import SocketEvents from '../../../utils/helpers/socket/events'
import styles from './style.module.css'
import { AimOutlined } from '@ant-design/icons'
import { Button, Col, List, Row } from 'antd'
import { DEFAULT_GOOGLE_MAP_PROPS } from '../../../utils/constants'
import { t } from 'i18next'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSocket } from '../../../utils/helpers/socket/hook'

const DriversHaveRequestMap = () => {
  const navigate = useNavigate()

  // ======================== Start API ========================
  const { actions, loading, driversHaveRequest } = useContext(SocketContext)

  const [driversHaveRequestList, setDriversHaveRequestList] =
    useState(driversHaveRequest)

  useEffect(() => {
    if (driversHaveRequest) {
      setDriversHaveRequestList(driversHaveRequest)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driversHaveRequest])

  const onRefreshData = () => {
    actions.getDriversHaveRequest()
  }

  useEffect(() => {
    onRefreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // ======================== Start API ========================

  // ======================== Start Socket ========================

  // Handle Drivers Have Request Sockets
  const [driversWithLocations, setDriversWithLocations] = useState<
    Map<string, IDriverLocationModel>
  >(new Map())

  const socket = useSocket(process.env.REACT_APP_SOCKET_URL ?? '')

  useEffect(() => {
    driversHaveRequestList &&
      driversHaveRequestList.forEach((driver) => {
        socket.on(
          `${SocketEvents.DRIVER_LOCATION_UPDATE}-${driver.id}`,
          (updatedDriver: IDriverLocationModel) => {
            setDriversWithLocations(
              (prevDrivers) =>
                new Map(
                  prevDrivers.set(`${updatedDriver.driverId}`, updatedDriver)
                )
            )
          }
        )
      })

    return () => {
      driversHaveRequestList &&
        driversHaveRequestList.forEach((driver) => {
          socket.off(
            `${SocketEvents.DRIVER_LOCATION_UPDATE}-${driver.id}`,
            () => {}
          )
        })
    }
  }, [socket, driversHaveRequestList])

  // ======================== End Socket ========================

  // ======================== Start Map ========================
  const [mapState, setMapState] = useState({
    zoom: DEFAULT_GOOGLE_MAP_PROPS.zoom,
    isMapLoaded: false,
    center: DEFAULT_GOOGLE_MAP_PROPS.center,
  })

  const onViewDriverOnMap = (driverId: number) => {
    const driver = driversWithLocations.get(`${driverId}`)

    if (driver) {
      setMapState((prev) => {
        return {
          ...prev,
          center: {
            lat: driver?.latitude,
            lng: driver?.longitude,
          },
          zoom: 15,
        }
      })
    }
  }

  // ======================== End Map ========================

  return (
    <Row
      gutter={[16, 16]}
      style={{
        paddingBottom: '1rem',
      }}
    >
      <Col span={6}>
        <List
          header={<h2>{t('map.drivers')}</h2>}
          bordered
          dataSource={driversHaveRequestList}
          className='drivers-list'
          loading={loading.includes('drivers-have-request')}
          renderItem={(driver) => (
            <List.Item
              className={styles.item}
              actions={[
                <Button
                  type='default'
                  icon={<AimOutlined />}
                  onClick={() => {
                    onViewDriverOnMap(driver.id)
                  }}
                >
                  {t('map.view_on_map')}
                </Button>,
              ]}
            >
              <List.Item.Meta
                title={
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate(`/driver/${driver.id}`)
                    }}
                  >
                    {driver.firstName}
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </Col>

      <Col span={18}>
        <GMap
          isMapLoaded={(isLoaded) => {
            setMapState((prev) => {
              return {
                ...prev,
                isMapLoaded: isLoaded,
              }
            })
          }}
          center={mapState.center}
          zoom={mapState.zoom}
        >
          {mapState.isMapLoaded && (
            <>
              {driversWithLocations &&
                Array.from(driversWithLocations.values())?.map(
                  (driver, index) => {
                    return (
                      <GMarkerTrack1
                        key={index}
                        heading={driver.heading}
                        isMapLoaded={mapState.isMapLoaded}
                        position={{
                          lat: driver.latitude,
                          lng: driver.longitude,
                        }}
                        onClick={(e) => {
                          setMapState((prev) => {
                            return {
                              ...prev,
                              center: {
                                lat:
                                  e.latLng?.lat() ??
                                  DEFAULT_GOOGLE_MAP_PROPS.center.lat,
                                lng:
                                  e.latLng?.lng() ??
                                  DEFAULT_GOOGLE_MAP_PROPS.center.lng,
                              },
                              zoom: 15,
                            }
                          })
                        }}
                      />
                    )
                  }
                )}
            </>
          )}
        </GMap>
      </Col>
    </Row>
  )
}

export default DriversHaveRequestMap
