import GMap from '../../common/map'
import GMarkerEnd from '../../common/map/custom-markers/EndMarker'
import GMarkerStart from '../../common/map/custom-markers/StartMarker'
import { Button, Modal, Spin } from 'antd'
import { DEFAULT_GOOGLE_MAP_PROPS } from '../../../utils/constants'
import { FC, useEffect, useState } from 'react'
import { IRequestDetails } from '../../../models/request'
import { useTranslation } from 'react-i18next'
import GMarkerTrack1 from '../../common/map/custom-markers/track1'
import IDriverLocationModel from '../../../models/socket-models'
import { useSocket } from '../../../utils/helpers/socket/hook'
import SocketEvents from '../../../utils/helpers/socket/events'

interface IProps {
	visible: boolean
	setVisible: (visible: boolean) => void
	loading?: boolean
	data: IRequestDetails
}

const RequestTrackModal: FC<IProps> = (props) => {
	const { visible, setVisible, data, loading = false } = props
	const { t } = useTranslation()
	const socket = useSocket(process.env.REACT_APP_SOCKET_URL ?? '')

	const [isMapLoaded, setIsMapLoaded] = useState(false)

	const [driverWithLocation, setDriverWithLocation] =
		useState<IDriverLocationModel>()

	useEffect(() => {
		data.driver &&
			socket.on(
				`${SocketEvents.DRIVER_LOCATION_UPDATE}-${data.driver.id}`,
				(updatedDriver: IDriverLocationModel) => {
					setDriverWithLocation(updatedDriver)
				}
			)

		return () => {
			data.driver &&
				socket.off(
					`${SocketEvents.DRIVER_LOCATION_UPDATE}-${data.driver.id}`,
					() => {}
				)
		}
	}, [data, socket])

	return (
		<Modal
			title={t('request.track')}
			open={visible}
			onOk={() => setVisible(false)}
			onCancel={() => setVisible(false)}
			footer={[
				<Button type='default' key='close' onClick={() => setVisible(false)}>
					{t('general.close')}
				</Button>
			]}
			destroyOnClose
			width={1000}
		>
			<Spin spinning={loading}>
				<div
					style={{
						width: '100%'
					}}
				>
					<GMap
						center={
							driverWithLocation?.latitude && driverWithLocation?.longitude
								? {
										lat: driverWithLocation.latitude,
										lng: driverWithLocation.longitude
								  }
								: DEFAULT_GOOGLE_MAP_PROPS.center
						}
						zoom={DEFAULT_GOOGLE_MAP_PROPS.zoom}
						isMapLoaded={(isLoaded) => {
							isLoaded && setIsMapLoaded(true)
						}}
					>
						{isMapLoaded && data && (
							<>
								<GMarkerStart
									isMapLoaded={isMapLoaded}
									position={{
										lat: data.startLat,
										lng: data.startLong
									}}
								/>

								{driverWithLocation && (
									<GMarkerTrack1
										heading={driverWithLocation.heading}
										isMapLoaded={isMapLoaded}
										position={{
											lat: driverWithLocation.latitude,
											lng: driverWithLocation.longitude
										}}
										onClick={(e) => {
											//
										}}
									/>
								)}

								<GMarkerEnd
									isMapLoaded={isMapLoaded}
									position={{
										lat: data.endLat,
										lng: data.endLong
									}}
								/>
							</>
						)}
					</GMap>
				</div>

				{/* <div>{data?.driver?.id}</div>  */}
			</Spin>
		</Modal>
	)
}

export default RequestTrackModal
