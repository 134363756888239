import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { PaymentType } from '../../../models/payment'

interface IProps {
	type: PaymentType
}

const PaymentTypeTag: React.FC<IProps> = ({ type }) => {
	const { t } = useTranslation()

	let label = ''
	let color = ''

	switch (type) {
		case PaymentType.INCOMING:
			label = t('payment.incoming')
			color = 'green'
			break
		case PaymentType.OUTGOING:
			label = t('payment.outgoing')
			color = 'red'
			break
	}

	return <Tag color={color}>{label}</Tag>
}

export default PaymentTypeTag
