import React, { useContext, useState } from 'react'
import {
	LogoutOutlined,
	UserOutlined,
	LockOutlined,
	DownloadOutlined
} from '@ant-design/icons'
import { Avatar, Button, Dropdown, Form, Input, MenuProps, Modal } from 'antd'
import cn from 'classnames'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import AuthContext from '../../../../../contexts/auth-context/context'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import axios from 'axios'
import FileDownload from 'js-file-download'
import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '../../../../../utils/constants'

const UserSettings: React.FC = () => {
	const { t } = useTranslation()
	const { actions, loading, authUser } = useContext(AuthContext)
	const navigate = useNavigate()

	const onLogout = async () => {
		const result = await actions.logout({})

		if (result) {
			navigate('/auth')
		}
	}

	// Change Password
	const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false)

	const onChangePasswordClick = () => {
		// Open Modal
		setOpenChangePasswordModal(true)
	}

	const onChangePasswordConfirm = async (values: any) => {
		const res = await actions.changePassword({
			oldPassword: values.oldPassword,
			password: values.password
		})
		if (res) {
			// Close Modal
			setOpenChangePasswordModal(false)
		}
	}

	// Download Backup
	const [openDownloadBackupModal, setOpenDownloadBackupModal] = useState(false)

	const onDownloadBackupClick = () => {
		// Open Modal
		setOpenDownloadBackupModal(true)
	}

	const downloadBackupMutation = useMutation({
		mutationFn: () => {
			return axios.get('/get-backup', { responseType: 'blob' })
		},
		onSuccess: (response) => {
			const timestamp = dayjs().format(DATE_TIME_FORMAT)
			FileDownload(response.data, `${timestamp}.zip`)
			setOpenDownloadBackupModal(false)
		}
	})

	const menuItems: MenuProps['items'] = [
		{
			label: t('auth.change_password'),
			key: '1',
			icon: (
				<LockOutlined
					className='site-form-item-icon'
					style={{ marginInlineEnd: '.5rem' }}
				/>
			),
			onClick: onChangePasswordClick
		},
		{
			label: t('auth.download_backup'),
			key: '2',
			icon: (
				<DownloadOutlined
					className='site-form-item-icon'
					style={{ marginInlineEnd: '.5rem' }}
				/>
			),
			onClick: onDownloadBackupClick
		},
		{
			key: '3',
			type: 'divider'
		},
		{
			label: t('auth.logout'),
			key: '4',
			icon: <LogoutOutlined />,
			danger: true,
			onClick: onLogout
		}
	]

	return (
		<>
			<Dropdown
				menu={{ items: menuItems }}
				trigger={['click']}
				className={cn(styles.userSettings)}
			>
				<div>
					{t('hi')} {authUser?.firstName} {authUser?.lastName}
					<Avatar
						shape='square'
						size='large'
						icon={<UserOutlined />}
						className={cn(styles.userAvatar)}
					/>
				</div>
			</Dropdown>

			<Modal
				title={t('auth.change_password')}
				destroyOnClose
				open={openChangePasswordModal}
				onCancel={() => {
					setOpenChangePasswordModal(false)
				}}
				footer={[]}
			>
				<Form
					preserve={false}
					name='change_password_form'
					className='login-form'
					style={{ padding: '1rem 0 0 0 ' }}
					onFinish={(values) => onChangePasswordConfirm(values)}
					size='large'
				>
					<Form.Item
						name='oldPassword'
						rules={[{ required: true, message: t('validation.required') }]}
					>
						<Input.Password
							prefix={
								<LockOutlined
									className='site-form-item-icon'
									style={{ marginInlineEnd: '.5rem' }}
								/>
							}
							placeholder={t('auth.old_password')}
						/>
					</Form.Item>

					<Form.Item
						name='password'
						rules={[{ required: true, message: t('validation.required') }]}
						style={{ padding: '1rem 0 0 0 ' }}
					>
						<Input.Password
							prefix={
								<LockOutlined
									className='site-form-item-icon'
									style={{ marginInlineEnd: '.5rem' }}
								/>
							}
							placeholder={t('auth.new_password')}
						/>
					</Form.Item>

					<Form.Item
						name='confirmPassword'
						rules={[{ required: true, message: t('validation.required') }]}
						style={{ padding: '1rem 0 0 0 ' }}
					>
						<Input.Password
							prefix={
								<LockOutlined
									className='site-form-item-icon'
									style={{ marginInlineEnd: '.5rem' }}
								/>
							}
							placeholder={t('auth.confirm_new_password')}
						/>
					</Form.Item>

					<div
						style={{
							paddingTop: '1rem',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: '100%'
						}}
					>
						<Button
							type='default'
							htmlType='button'
							style={{
								marginInlineEnd: '1rem',
								width: '40%'
							}}
							onClick={() => {
								setOpenChangePasswordModal(false)
							}}
						>
							{t('auth.cancel')}
						</Button>

						<Button
							type='primary'
							htmlType='submit'
							loading={loading.includes('change-password')}
							style={{
								width: '40%'
							}}
						>
							{t('auth.confirm')}
						</Button>
					</div>
				</Form>
			</Modal>

			<Modal
				title={t('auth.download_backup')}
				open={openDownloadBackupModal}
				onCancel={() => {
					setOpenDownloadBackupModal(false)
				}}
				onOk={() => downloadBackupMutation.mutate()}
				confirmLoading={downloadBackupMutation.isLoading}
			>
				<p>{t('auth.download_backup_confirmation')}</p>
			</Modal>
		</>
	)
}

export default UserSettings
