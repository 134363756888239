import io, { Socket } from 'socket.io-client'
import { ACCESS_TOKEN_KEY } from '../../constants'
import { useEffect, useRef } from 'react'

export const useSocket = (serverUrl: string) => {
  const socketRef = useRef<Socket | null>(null)

  useEffect(() => {
    socketRef.current = io(
      serverUrl,

      {
        auth: {
          token: localStorage.getItem(ACCESS_TOKEN_KEY),
        },
        query: {
          userType: 'admin',
        },
      }
    )
    return () => {
      socketRef.current?.disconnect()
    }
  }, [serverUrl])

  const on = (event: string, callback: (...args: any[]) => void) => {
    socketRef.current?.on(event, callback)
  }

  const off = (event: string, callback: (...args: any[]) => void) => {
    socketRef.current?.off(event, callback)
  }

  return { on, off }
}
