import { Button, Card, Form, Input, Select } from 'antd'
import React from 'react'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { CheckOutlined } from '@ant-design/icons'
import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import {
  IAddReward,
  RewardGettingWay,
  RewardStatus,
} from '../../../models/reward'
import dayjs from 'dayjs'

const AddRewardPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (data: IAddReward) => {
      return axios.post('/reward', data)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['rewards'] })
      queryClient.invalidateQueries({ queryKey: ['rewards-light'] })
      navigate(-1)
    },
  })

  const onSubmit = (data: any) => {
    mutation.mutate({
      name: {
        ku: data['name.ku'],
        ar: data['name.ar'],
        en: data['name.en'],
      },
      value: data['value'],
      status: data['status'],
      month: data['month'],
      year: data['year'],
      wayGetReward: data['wayGetReward'],
      numOfPoint: data['numOfPoint'],
      numOfRate: data['numOfRate'],
      note: data['note'],
    })
  }

  return (
    <Form name='form_item_path' layout='vertical' onFinish={onSubmit}>
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('reward.add')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Button
              type='default'
              disabled={mutation.isLoading}
              onClick={() => navigate(-1)}
            >
              {t('general.cancel')}
            </Button>
            <Button
              className={styles.saveButton}
              type='primary'
              htmlType='submit'
              icon={<CheckOutlined />}
              loading={mutation.isLoading}
            >
              {t('general.save')}
            </Button>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: '1rem' }}>
        <Form.Item
          name='name.ku'
          label={`${t('reward.name')} (${t('language.kurdish')})`}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='name.ar'
          label={`${t('reward.name')} (${t('language.arabic')})`}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='name.en'
          label={`${t('reward.name')} (${t('language.english')})`}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='value'
          label={t('reward.value')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input type='number' />
        </Form.Item>
        <Form.Item
          name='status'
          label={t('reward.status')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Select
            options={[
              {
                label: t('reward.active'),
                value: RewardStatus.ACTIVE,
              },
              {
                label: t('reward.not_active'),
                value: RewardStatus.NOT_ACTIVE,
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name='month'
          label={t('reward.month')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Select
            options={[
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ].map((e) => {
              return {
                label: e,
                value: e,
              }
            })}
          />
        </Form.Item>
        <Form.Item
          name='year'
          label={t('reward.year')}
          required
          rules={[
            { required: true, message: t('validation.required') },
            {
              validator: (_, value, callback) => {
                if (value && value < dayjs().year()) {
                  callback(t('validation.min', { value: dayjs().year() }))
                } else {
                  callback()
                }
              },
            },
          ]}
        >
          <Input type='number' />
        </Form.Item>
        <Form.Item
          name='wayGetReward'
          label={t('reward.way_get_reward')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Select
            options={[
              {
                label: t('reward.point'),
                value: RewardGettingWay.POINT,
              },
              {
                label: t('reward.rate'),
                value: RewardGettingWay.RATE,
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name='numOfPoint'
          label={t('reward.num_of_point')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input type='number' />
        </Form.Item>
        <Form.Item
          name='numOfRate'
          label={t('reward.num_of_rate')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input type='number' />
        </Form.Item>
        <Form.Item name='note' label={t('reward.note')}>
          <Input.TextArea />
        </Form.Item>
      </Card>
    </Form>
  )
}

export default AddRewardPage
