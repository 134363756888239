import axios from 'axios'
import ChartsCards from '../../components/home/charts-card'
import StatisticsCards from '../../components/home/statistics-cards'
import TopRatingDriversList from '../../components/home/top-lists/top-rating-drivers-list'
import { Col, Row } from 'antd'
import { removeUndefinedProps } from '../../utils/helpers/functions'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import {
  IFirstStatistics,
  IFirstStatisticsFilter,
  ISecondStatistics,
  ISecondStatisticsFilter,
} from '../../models/home'
import MostWantedDriversList from '../../components/home/top-lists/most-wanted-drivers-list'

const HomePage = () => {
  // First Query
  const [firstStatisticsFilter, setFirstStatisticsFilter] =
    useState<IFirstStatisticsFilter>({})

  const firstQuery = useQuery({
    queryKey: ['first-statistics', firstStatisticsFilter],
    queryFn: async () => {
      return axios.get<IFirstStatistics>('/first-statistics', {
        params: { ...removeUndefinedProps(firstStatisticsFilter) },
      })
    },
  })

  // Second Query
  const [secondStatisticsFilter, setSecondStatisticsFilter] =
    useState<ISecondStatisticsFilter>({})

  const secondQuery = useQuery({
    queryKey: ['second-statistics', secondStatisticsFilter],
    queryFn: async () => {
      return axios.get<ISecondStatistics>('/second-statistics', {
        params: { ...removeUndefinedProps(secondStatisticsFilter) },
      })
    },
  })

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <StatisticsCards
          firstQuery={firstQuery}
          firstQueryFilter={firstStatisticsFilter}
          setFirstQueryFilter={setFirstStatisticsFilter}
          secondQuery={secondQuery}
          secondQueryFilter={secondStatisticsFilter}
          setSecondQueryFilter={setSecondStatisticsFilter}
        />
      </Col>

      <Col span={24}>
        <ChartsCards
          firstQuery={firstQuery}
          firstQueryFilter={firstStatisticsFilter}
          setFirstQueryFilter={setFirstStatisticsFilter}
          secondQuery={secondQuery}
          secondQueryFilter={secondStatisticsFilter}
          setSecondQueryFilter={setSecondStatisticsFilter}
        />
      </Col>
    </Row>
  )
}
export default HomePage
