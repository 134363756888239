import React, { useContext, useState } from 'react'
import { Space, Table, Card, Button, Popconfirm } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import {
	PlusOutlined,
	EyeOutlined,
	EditOutlined,
	DeleteOutlined,
	HistoryOutlined,
	FileExcelOutlined
} from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { IPaginatedList } from '../../models/paginated-list'
import {
	DEFAULT_PAGE_SIZE,
	DEFAULT_SORT_FUNCTION,
	INITIAL_PAGE
} from '../../utils/constants'
import axios from 'axios'
import { IReward, RewardGettingWay, RewardStatus } from '../../models/reward'
import RewardStatusTag from '../../components/enums/reward-status-tag'
import RewardGettingWayTag from '../../components/enums/reward-getting-way-tag'
import HeaderCard from '../../components/common/header-card'
import { ISort } from '../../models/shared'
import { SorterResult } from 'antd/es/table/interface'
import ExportToExcelModal from '../../components/common/export-to-excel-modal'
import AuthContext from '../../contexts/auth-context/context'
import {
	CAN_CREATE_REWARD,
	CAN_DELETE_REWARD,
	CAN_EXPORT_REWARDS,
	CAN_UPDATE_REWARD
} from '../../utils/rbac/permissions'

const RewardsPage: React.FC = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const {
		actions: { can }
	} = useContext(AuthContext)

	const queryClient = useQueryClient()

	const [page, setPage] = useState(INITIAL_PAGE)
	const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE)
	const [search, setSearch] = useState<string | undefined>()
	const [sort, setSort] = useState<ISort | undefined>()
	const [exportModelVisible, setExportModalVisible] = useState(false)

	const query = useQuery({
		queryKey: ['rewards', page, perPage, search, sort],
		queryFn: async () => {
			return axios.get<IPaginatedList<IReward>>('/reward', {
				params: {
					page,
					perPage,
					search,
					orders: [sort]
				}
			})
		}
	})

	const mutation = useMutation({
		mutationFn: (id: number) => {
			return axios.delete(`/reward/${id}`)
		},
		onSuccess: (_, id) => {
			if (query.data?.data.data.length === 1) {
				setPage(INITIAL_PAGE)
			}
			queryClient.invalidateQueries({ queryKey: ['rewards'] })
			queryClient.invalidateQueries({ queryKey: ['rewards-light'] })
			queryClient.invalidateQueries({ queryKey: ['reward', id] })
		}
	})

	const columns: ColumnsType<IReward> = [
		{
			title: t('general.id'),
			dataIndex: 'id',
			key: 'id',
			render: (id) => <Link to={`/reward/${id}`}>{id}</Link>,
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('reward.name'),
			dataIndex: 'name',
			key: 'name',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('reward.value'),
			dataIndex: 'value',
			key: 'value',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('reward.status'),
			dataIndex: 'status',
			render: (status: RewardStatus) => <RewardStatusTag status={status} />
		},
		{
			title: t('reward.month'),
			dataIndex: 'month'
		},
		{
			title: t('reward.year'),
			dataIndex: 'year'
		},
		{
			title: t('reward.way_get_reward'),
			dataIndex: 'wayGetReward',
			render: (way: RewardGettingWay) => <RewardGettingWayTag way={way} />
		},
		{
			title: t('reward.num_of_point'),
			dataIndex: 'numOfPoint',
			key: 'numOfPoint',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('reward.num_of_rate'),
			dataIndex: 'numOfRate',
			key: 'numOfRate',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('general.actions'),
			render: (_, record) => (
				<Space>
					<Button
						type='link'
						icon={<HistoryOutlined />}
						onClick={() => navigate(`/reward/${record.id}/history`)}
					>
						{t('general.history')}
					</Button>
					<Button
						type='link'
						icon={<EyeOutlined />}
						onClick={() => navigate(`/reward/${record.id}`)}
					>
						{t('general.show')}
					</Button>
					{can(CAN_UPDATE_REWARD) && (
						<Button
							type='link'
							icon={<EditOutlined />}
							onClick={() => navigate(`/reward/${record.id}/edit`)}
						>
							{t('general.edit')}
						</Button>
					)}
					{can(CAN_DELETE_REWARD) && (
						<Popconfirm
							title={t('general.delete_warning')}
							description={t('general.delete_confirmation')}
							onConfirm={() => mutation.mutate(record.id)}
							okText={t('general.yes')}
							cancelText={t('general.no')}
						>
							<Button type='link' icon={<DeleteOutlined />} danger>
								{t('general.delete')}
							</Button>
						</Popconfirm>
					)}
				</Space>
			)
		}
	]

	return (
		<>
			<HeaderCard
				title={t('reward.label')}
				onSearch={(value) => {
					setSearch(value.length === 0 ? undefined : value)
				}}
				trailing={[
					can(CAN_CREATE_REWARD) ? (
						<Button
							type='primary'
							icon={<PlusOutlined />}
							onClick={() => navigate('/reward/add')}
						>
							{t('general.add')}
						</Button>
					) : null,
					can(CAN_EXPORT_REWARDS) ? (
						<>
							<Button
								type='default'
								icon={<FileExcelOutlined />}
								onClick={() => setExportModalVisible(true)}
							>
								{t('general.export_to_excel')}
							</Button>
							<ExportToExcelModal
								visible={exportModelVisible}
								setVisible={setExportModalVisible}
								exportUrl={'/reward/export'}
								columns={[
									{ name: 'id', label: 'general.id' },
									{ name: 'name', label: 'reward.name' },
									{ name: 'value', label: 'reward.value' },
									{ name: 'status', label: 'reward.status' },
									{ name: 'wayGetReward', label: 'reward.way_get_reward' },
									{ name: 'numOfPoint', label: 'reward.num_of_point' },
									{ name: 'numOfRate', label: 'reward.num_of_rate' },
									{ name: 'month', label: 'reward.month' },
									{ name: 'year', label: 'reward.year' },
									{ name: 'note', label: 'reward.note' }
								]}
							/>
						</>
					) : null
				].filter((i) => i != null)}
			/>
			<Card bordered={false} bodyStyle={{ padding: 0 }}>
				<Table
					loading={query.isFetching}
					columns={columns}
					dataSource={query.data?.data.data}
					rowKey={(record) => record.id}
					style={{ overflowX: 'scroll' }}
					onChange={(_, __, sorter, ___) => {
						const { columnKey, order } = sorter as SorterResult<IReward>

						if (!order) {
							setSort(undefined)
						} else {
							setSort({
								name: columnKey as string,
								direction: order === 'ascend' ? 'asc' : 'desc'
							})
						}
					}}
					pagination={{
						current: page,
						pageSize: perPage,
						pageSizeOptions: [5, 10, 20, 50, 100],
						showSizeChanger: true,
						total: query.data?.data.total,
						position: ['bottomCenter'],
						onChange(page, pageSize) {
							setPage(page)
							setPerPage(pageSize)
						},
						showTotal: (total, range) => {
							return `${t('general.showing')} ${range[0]} - ${range[1]} ${t(
								'general.from'
							)} ${total} ${t('general.items')}`
						}
					}}
				/>
			</Card>
		</>
	)
}

export default RewardsPage
