import React, { useContext, useState } from 'react'
import {
	Space,
	Table,
	Card,
	Button,
	Popconfirm,
	Col,
	Form,
	Row,
	Select
} from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import {
	PlusOutlined,
	EyeOutlined,
	EditOutlined,
	HistoryOutlined,
	DeleteOutlined,
	FileExcelOutlined
} from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { IPaginatedList } from '../../models/paginated-list'
import {
	DEFAULT_PAGE_SIZE,
	DEFAULT_SORT_FUNCTION,
	INITIAL_PAGE
} from '../../utils/constants'
import axios from 'axios'
import {
	IVehicleCategory,
	VehicleCategoryType
} from '../../models/vehicle-category'
import VehicleCategoryTypeTag from '../../components/enums/vehicle-category-type-tag'
import { IVehicleCapacityLight } from '../../models/vehicle-capacity'
import HeaderCard from '../../components/common/header-card'
import { IFilter, ISort } from '../../models/shared'
import { SorterResult } from 'antd/es/table/interface'
import { UserStatus } from '../../models/user'
import ExportToExcelModal from '../../components/common/export-to-excel-modal'
import AuthContext from '../../contexts/auth-context/context'
import {
	CAN_CREATE_CATEGORY,
	CAN_DELETE_CATEGORY,
	CAN_EXPORT_CATEGORIES,
	CAN_UPDATE_CATEGORY
} from '../../utils/rbac/permissions'

const VehicleCategoriesPage: React.FC = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const {
		actions: { can }
	} = useContext(AuthContext)

	const queryClient = useQueryClient()

	const [page, setPage] = useState(INITIAL_PAGE)
	const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE)
	const [search, setSearch] = useState<string | undefined>()
	const [filters, setFilters] = useState<IFilter[]>()
	const [sort, setSort] = useState<ISort | undefined>()
	const [exportModelVisible, setExportModalVisible] = useState(false)

	const query = useQuery({
		queryKey: ['vehicle-categories', page, perPage, search, filters, sort],
		queryFn: async () => {
			return axios.get<IPaginatedList<IVehicleCategory>>('/category', {
				params: {
					page,
					perPage,
					search,
					filters,
					orders: [sort]
				}
			})
		}
	})

	const mutation = useMutation({
		mutationFn: (id: number) => {
			return axios.delete(`/category/${id}`)
		},
		onSuccess: (_, id) => {
			if (query.data?.data.data.length === 1) {
				setPage(INITIAL_PAGE)
			}
			queryClient.invalidateQueries({ queryKey: ['vehicle-categories'] })
			queryClient.invalidateQueries({ queryKey: ['vehicle-categories-light'] })
			queryClient.invalidateQueries({ queryKey: ['vehicle-category', id] })
		}
	})

	const columns: ColumnsType<IVehicleCategory> = [
		{
			title: t('general.id'),
			dataIndex: 'id',
			key: 'id',
			render: (id) => <Link to={`/vehicle-category/${id}`}>{id}</Link>,
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('vehicle_category.name'),
			dataIndex: 'name',
			key: 'name',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('vehicle_category.type'),
			dataIndex: 'type',
			key: 'type',
			render: (type: VehicleCategoryType) => {
				return <VehicleCategoryTypeTag type={type} />
			}
		},
		{
			title: t('vehicle_category.capacity'),
			dataIndex: 'capacity',
			key: 'capacity.id',
			render: (capacity: IVehicleCapacityLight) => capacity.name
		},
		{
			title: t('vehicle_category.points'),
			dataIndex: 'points',
			key: 'points'
		},
		{
			title: t('general.actions'),
			key: 'actions',
			render: (_, record) => (
				<Space>
					<Button
						type='link'
						icon={<HistoryOutlined />}
						onClick={() => navigate(`/vehicle-category/${record.id}/history`)}
					>
						{t('general.history')}
					</Button>
					<Button
						type='link'
						icon={<EyeOutlined />}
						onClick={() => navigate(`/vehicle-category/${record.id}`)}
					>
						{t('general.show')}
					</Button>
					{can(CAN_UPDATE_CATEGORY) && (
						<Button
							type='link'
							icon={<EditOutlined />}
							onClick={() => navigate(`/vehicle-category/${record.id}/edit`)}
						>
							{t('general.edit')}
						</Button>
					)}
					{can(CAN_DELETE_CATEGORY) && (
						<Popconfirm
							title={t('general.delete_warning')}
							description={t('general.delete_confirmation')}
							onConfirm={() => mutation.mutate(record.id)}
							okText={t('general.yes')}
							cancelText={t('general.no')}
						>
							<Button type='link' icon={<DeleteOutlined />} danger>
								{t('general.delete')}
							</Button>
						</Popconfirm>
					)}
				</Space>
			)
		}
	]

	return (
		<>
			<HeaderCard
				title={t('vehicle_category.label')}
				onSearch={(value) => {
					setSearch(value.length === 0 ? undefined : value)
				}}
				trailing={[
					can(CAN_CREATE_CATEGORY) ? (
						<Button
							type='primary'
							icon={<PlusOutlined />}
							onClick={() => navigate('/vehicle-category/add')}
						>
							{t('general.add')}
						</Button>
					) : null,
					can(CAN_EXPORT_CATEGORIES) ? (
						<>
							<Button
								type='default'
								icon={<FileExcelOutlined />}
								onClick={() => setExportModalVisible(true)}
							>
								{t('general.export_to_excel')}
							</Button>
							<ExportToExcelModal
								visible={exportModelVisible}
								setVisible={setExportModalVisible}
								exportUrl={'/category/export'}
								columns={[
									{ name: 'id', label: 'general.id' },
									{ name: 'name', label: 'vehicle_category.name' },
									{ name: 'type', label: 'vehicle_category.type' },
									{ name: 'points', label: 'vehicle_category.points' },
									{ name: 'capacity', label: 'vehicle_category.capacity' },
									{ name: 'createdAt', label: 'general.created_at' }
								]}
							/>
						</>
					) : null
				].filter((i) => i != null)}
				onFilterChange={(data) => {
					var filters: IFilter[] = []
					data.type &&
						filters.push({ name: 'type', operation: '=', value: data.type })
					setFilters(filters.length === 0 ? undefined : filters)
				}}
				filters={
					<Row style={{ padding: '0 1rem' }} gutter={16}>
						<Col span={6}>
							<Form.Item name='type' label={t('vehicle_category.type')}>
								<Select
									options={[
										{
											label: t('vehicle_category.transport'),
											value: VehicleCategoryType.TRANSPORT
										},
										{
											label: t('vehicle_category.time_hours'),
											value: VehicleCategoryType.TIME_HOURS
										},
										{
											label: t('vehicle_category.time_days'),
											value: VehicleCategoryType.TIME_DAYS
										}
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
				}
			/>
			<Card bordered={false} bodyStyle={{ padding: 0 }}>
				<Table
					loading={query.isFetching}
					columns={columns}
					dataSource={query.data?.data.data}
					rowKey={(record) => record.id}
					style={{ overflowX: 'scroll' }}
					onChange={(_, __, sorter, ___) => {
						const { columnKey, order } =
							sorter as SorterResult<IVehicleCategory>

						if (!order) {
							setSort(undefined)
						} else {
							setSort({
								name: columnKey as string,
								direction: order === 'ascend' ? 'asc' : 'desc'
							})
						}
					}}
					pagination={{
						current: page,
						pageSize: perPage,
						pageSizeOptions: [5, 10, 20, 50, 100],
						showSizeChanger: true,
						total: query.data?.data.total,
						position: ['bottomCenter'],
						onChange(page, pageSize) {
							setPage(page)
							setPerPage(pageSize)
						},
						showTotal: (total, range) => {
							return `${t('general.showing')} ${range[0]} - ${range[1]} ${t(
								'general.from'
							)} ${total} ${t('general.items')}`
						}
					}}
				/>
			</Card>
		</>
	)
}

export default VehicleCategoriesPage
