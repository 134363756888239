import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { RequestStatus } from '../../../models/request'

interface IProps {
	status: RequestStatus
}

const RequestStatusTag: React.FC<IProps> = ({ status }) => {
	const { t } = useTranslation()

	let label = ''
	let color = ''

	switch (status) {
		case RequestStatus.SUSPENDED_BY_DRIVER:
			label = t('request.suspended_by_driver')
			color = 'red'
			break
		case RequestStatus.SUSPENDED_BY_CUSTOMER:
			label = t('request.suspended_by_client')
			color = 'red'
			break
		case RequestStatus.CANCELED_BY_DRIVER:
			label = t('request.canceled_by_driver')
			color = 'default'
			break
		case RequestStatus.CANCELED_BY_CUSTOMER:
			label = t('request.canceled_by_client')
			color = 'default'
			break
		case RequestStatus.APPROVED:
			label = t('request.approved')
			color = 'green'
			break
		case RequestStatus.ON_THE_WAY:
			label = t('request.on_the_way')
			color = 'cyan'
			break
		case RequestStatus.SITE_HAS_BEEN_REACHED:
			label = t('request.site_has_been_reached')
			color = 'lime'
			break
		case RequestStatus.IN_PROGRESS:
			label = t('request.in_progress')
			color = 'yellow'
			break
		case RequestStatus.COMPLETE:
			label = t('request.complete')
			color = 'blue'
			break
		case RequestStatus.CLOSED:
			label = t('request.closed')
			color = 'magenta'
			break
	}

	return <Tag color={color}>{label}</Tag>
}

export default RequestStatusTag
