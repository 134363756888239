import {
  GoogleMap,
  useJsApiLoader,
  StandaloneSearchBox,
} from '@react-google-maps/api'
import {
  CSSProperties,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { DEFAULT_GOOGLE_MAP_PROPS } from '../../../utils/constants'
import { useTranslation } from 'react-i18next'

const containerStyle = {
  width: '100%',
  height: '35rem',
}

interface IProps {
  center?: {
    lat: number
    lng: number
  }
  zoom?: number
  isMapLoaded?: (isLoaded: boolean) => void
  children?: ReactNode | undefined
  onPlacesChange?: (position: { lat: number; lng: number }) => void
  showPlaces?: boolean
}

type Libraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[]

const libs: Libraries = ['places']

const GMap: FC<IProps> = (props) => {
  const {
    center,
    zoom,
    children,
    isMapLoaded,
    onPlacesChange,
    showPlaces = true,
  } = props

  const { isLoaded } = useJsApiLoader({
    id: 'google-map',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY ?? '',
    libraries: libs,
  })

  const { t } = useTranslation()

  const inputStyle: CSSProperties = {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `240px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: 'absolute',
    top: '64px',
    right: '10px',
    zIndex: 999,
  }

  useEffect(() => {
    isMapLoaded && isMapLoaded(isLoaded)
    return () => {
      isMapLoaded && isMapLoaded(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded])

  const [mapRef, setMapRef] = useState<google.maps.Map | null>(null)

  const searchBoxContainerRef = useRef<HTMLDivElement>(null)

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    // Set Center
    map.setCenter(center ?? DEFAULT_GOOGLE_MAP_PROPS.center)

    // Set Zoom
    map.setZoom(zoom ?? DEFAULT_GOOGLE_MAP_PROPS.zoom)

    // Check search box
    if (searchBoxRef.current && searchBoxContainerRef.current) {
      // Add the search box to the map as a control
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(
        searchBoxContainerRef.current
      )
    }

    // Set Map
    setMapRef(map)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onUnmount = useCallback(function callback(map: google.maps.Map) {
    setMapRef(null)
  }, [])

  // Search Box
  const searchBoxRef = useRef<google.maps.places.SearchBox>()

  const onSearchBoxLoad = (ref: google.maps.places.SearchBox) => {
    searchBoxRef.current = ref
  }

  const onSearchBoxChanged = () => {
    const places = searchBoxRef.current?.getPlaces()

    if (places && places.length > 0) {
      const firstPlace = places[0]
      if (firstPlace.geometry && firstPlace.geometry.location) {
        let position = {
          lat: firstPlace?.geometry?.location.lat(),
          lng: firstPlace?.geometry?.location.lng(),
        }

        mapRef?.setCenter(position)
        onPlacesChange && onPlacesChange(position)
      }
    }
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      onLoad={onLoad}
      onUnmount={onUnmount}
      center={center}
      zoom={zoom}
    >
      <div
        ref={searchBoxContainerRef}
        style={{
          position: 'absolute',
          top: '64px',
          right: '0px',
        }}
      >
        {showPlaces && (
          <StandaloneSearchBox
            onLoad={onSearchBoxLoad}
            onPlacesChanged={onSearchBoxChanged}
          >
            <input
              type='text'
              placeholder={t('general.search_hint')}
              style={inputStyle}
            />
          </StandaloneSearchBox>
        )}
      </div>

      {/* Child components, such as markers, info windows, etc. */}
      <>{children}</>
    </GoogleMap>
  ) : (
    <></>
  )
}

export default GMap
