import { IDriverLight } from './driver'
import { IUserLight } from './user'

export enum PaymentType {
	INCOMING = 1,
	OUTGOING = 2
}

export enum PaymentMethod {
	ELECTRONIC = 1,
	DEPOSIT = 2,
	COUPON = 3,
	REWARD = 4,
	MANUAL = 5,
	REQUEST = 6
}

export interface IPayment {
	id: number
	value: number
	type: PaymentType
	method: PaymentMethod
	receivingParty?: string
	driver: IDriverLight
	createdAt: Date
	createdBy?: IUserLight
}

export interface IPaymentDetails extends IPayment {
	note?: string
}

export interface IAddPayment {
	value: number
	type: PaymentType
	driverId: number
	note?: string
}
