import React from 'react'
import { Card, Button, Descriptions, Spin, Image } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import axios from 'axios'
import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '../../../utils/constants'
import { IClientDetails } from '../../../models/client'
import UserStatusTag from '../../../components/enums/user-status-tag'
import HowKnowAppTag from '../../../components/enums/how-know-app-tag'

const ClientDetailsPage: React.FC = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const { id } = useParams()

	const query = useQuery({
		queryKey: ['client', id],
		queryFn: async () => {
			return axios.get<IClientDetails>(`/clients/${id}`)
		}
	})

	const data = query.data?.data

	return (
		<div>
			<Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
				<div className={styles.headerCardTitle}>
					<h3>{t('client.details')}</h3>
					<div className={styles.headerCardTitleTrailing}>
						<Button type='default' onClick={() => navigate(-1)}>
							{t('general.cancel')}
						</Button>
					</div>
				</div>
			</Card>
			<Card bodyStyle={{ padding: 0 }}>
				<Spin tip={t('general.loading')} spinning={query.isFetching}>
					<Descriptions bordered column={1}>
						<Descriptions.Item label={t('general.id')}>
							{data?.id}
						</Descriptions.Item>
						<Descriptions.Item label={t('client.first_name')}>
							{data?.user.firstName}
						</Descriptions.Item>
						<Descriptions.Item label={t('client.last_name')}>
							{data?.user.lastName}
						</Descriptions.Item>
						<Descriptions.Item label={t('client.status')}>
							{data?.user.status && (
								<UserStatusTag status={data?.user.status} />
							)}
						</Descriptions.Item>
						<Descriptions.Item label={t('client.mobile')}>
							{data?.user.mobile}
						</Descriptions.Item>
						<Descriptions.Item label={t('client.email')}>
							{data?.user.email}
						</Descriptions.Item>
						<Descriptions.Item label={t('client.sum_request')}>
							{data?.sumRequest}
						</Descriptions.Item>
						<Descriptions.Item label={t('client.photo')}>
							{data?.user?.photo && (
								<Image width={48} height={48} src={data?.user.photo.url} />
							)}
						</Descriptions.Item>
						<Descriptions.Item label={t('client.how_know_app')}>
							{data?.howKnowApp && <HowKnowAppTag how={data?.howKnowApp} />}
						</Descriptions.Item>
						<Descriptions.Item label={t('client.note')} span={3}>
							{data?.note}
						</Descriptions.Item>
						<Descriptions.Item label={t('general.last_usage_date')}>
							{data?.dateLastRequest &&
								dayjs(data?.dateLastRequest).format(DATE_TIME_FORMAT)}
						</Descriptions.Item>
						<Descriptions.Item label={t('general.created_at')}>
							{dayjs(data?.createdAt).format(DATE_TIME_FORMAT)}
						</Descriptions.Item>
						<Descriptions.Item label={t('general.updated_at')}>
							{dayjs(data?.updatedAt).format(DATE_TIME_FORMAT)}
						</Descriptions.Item>
						<Descriptions.Item label={t('general.updated_by')}>
							{data?.updatedBy &&
								`${data?.updatedBy?.firstName} ${data?.updatedBy?.lastName}`}
						</Descriptions.Item>
						<Descriptions.Item label={t('general.deleted_at')}>
							{data?.deletedAt &&
								dayjs(data?.deletedAt).format(DATE_TIME_FORMAT)}
						</Descriptions.Item>
					</Descriptions>
				</Spin>
			</Card>
		</div>
	)
}

export default ClientDetailsPage
