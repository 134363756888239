import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { PublicNotificationTo } from '../../../models/public-notification'

interface IProps {
  to: PublicNotificationTo
}

const PublicNotificationToTag: React.FC<IProps> = ({ to }) => {
  const { t } = useTranslation()

  let label = ''
  let color = ''

  switch (to) {
    case PublicNotificationTo.CLIENT:
      label = t('public_notification.client')
      color = 'blue'
      break
    case PublicNotificationTo.DRIVER:
      label = t('public_notification.driver')
      color = 'orange'
      break
    case PublicNotificationTo.BOTH:
      label = t('public_notification.both')
      color = 'cyan'
      break
  }

  return <Tag color={color}>{label}</Tag>
}

export default PublicNotificationToTag
