import axios from 'axios'
import reducer from './reducer'
import SocketContext, { initState } from './context'
import { IDriverLight } from '../../models/driver'
import { IPaginatedList } from '../../models/paginated-list'
import { PropsWithChildren, useReducer } from 'react'
import { useQuery } from 'react-query'

const SocketContextProvider: React.FC<PropsWithChildren> = (props) => {
  const [state, dispatch] = useReducer(reducer, initState)

  // Drivers Have Request Query
  const getDriversHaveRequestQuery = useQuery({
    queryFn: async () => {
      return axios.get<IPaginatedList<IDriverLight>>('drivers-have-request', {
        params: {
          minimal: 1,
          perPage: -1,
        },
      })
    },
    enabled: false,
  })

  // Available Drivers With NoRequest Query
  const getAvailableDriversWithNoRequestQuery = useQuery({
    queryFn: async () => {
      return axios.get<IPaginatedList<IDriverLight>>(
        'drivers-available-have-not-request',
        {
          params: {
            minimal: 1,
            perPage: -1,
          },
        }
      )
    },
    enabled: false,
  })

  const getDriversHaveRequest = async () => {
    dispatch({ type: 'LOADING', payload: { loading: 'drivers-have-request' } })

    const res = await getDriversHaveRequestQuery
      .refetch()
      .then((result) => {
        if (result.data && result.data.data.data) {
          dispatch({
            type: 'SET_DRIVERS_HAVE_REQUESTS',
            payload: { driversHaveRequest: result.data.data.data },
          })
        }

        dispatch({
          type: 'LOADING',
          payload: { loading: 'drivers-have-request' },
        })
        return true
      })
      .catch(() => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'drivers-have-request' },
        })
        return false
      })

    return res ?? false
  }

  const getAvailableDriversWithNoRequest = async () => {
    dispatch({
      type: 'LOADING',
      payload: { loading: 'available-drivers-with-no-request' },
    })

    const res = await getAvailableDriversWithNoRequestQuery
      .refetch()
      .then((result) => {
        if (result.data) {
          dispatch({
            type: 'SET_AVAILABLE_DRIVERS_WITH_NO_REQUEST',
            payload: { availableDriversWithNoRequest: result.data.data.data },
          })
        }

        dispatch({
          type: 'LOADING',
          payload: { loading: 'available-drivers-with-no-request' },
        })
        return true
      })
      .catch(() => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'available-drivers-with-no-request' },
        })
        return false
      })

    return res ?? false
  }

  return (
    <SocketContext.Provider
      value={{
        ...state,
        actions: {
          getDriversHaveRequest,
          getAvailableDriversWithNoRequest,
        },
      }}
    >
      {props.children}
    </SocketContext.Provider>
  )
}

export default SocketContextProvider
