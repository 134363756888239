import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import { Card, Spin } from 'antd'
import { FC } from 'react'
import { Pie } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

ChartJS.register(ArcElement, Tooltip, Legend)

interface IProps {
  requestClosed?: number
  requestInProgress?: number
  loading: boolean
}

const RequestStatusChart: FC<IProps> = ({
  requestClosed,
  requestInProgress,
  loading,
}) => {
  const { t } = useTranslation()

  const data = {
    labels: ['Closed', 'In Progress'],
    datasets: [
      {
        data: [requestClosed, requestInProgress],
        backgroundColor: ['#FF6384', '#36A2EB'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB'],
      },
    ],
  }

  return (
    <Spin spinning={loading}>
      <Card
        title={t('Request Status Overview')}
        headStyle={{
          textAlign: 'center',
        }}
        bodyStyle={{
          height: '20rem',
        }}
      >
        <Pie
          data={data}
          options={{
            responsive: true,
            maintainAspectRatio: false,
          }}
        />
      </Card>
    </Spin>
  )
}

export default RequestStatusChart
