import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'

interface IProps {
  value: boolean
}

const BooleanTag: React.FC<IProps> = ({ value }) => {
  const { t } = useTranslation()

  let label = value ? 'general.yes' : 'general.no'
  let color = value ? 'green' : 'red'

  return <Tag color={color}>{t(label)}</Tag>
}

export default BooleanTag
