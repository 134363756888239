import AccidentDetailsPage from './pages/accident/details'
import AccidentsPage from './pages/accident'
import AddCityPage from './pages/city/add'
import AddCouponPage from './pages/coupon/add'
import AddPaymentPage from './pages/payment/add'
import AddPublicNotificationPage from './pages/public-notification/add'
import AddRequestPage from './pages/request/add'
import AddRewardPage from './pages/reward/add'
import AddRolePage from './pages/role/add'
import AddUserPage from './pages/user/add'
import AddVehicleCapacityPage from './pages/vehicle-capacity/add'
import AddVehicleCategoryPage from './pages/vehicle-category/add'
import AuthContextProvider from './contexts/auth-context/provider'
import AuthPage from './pages/auth'
import CitiesPage from './pages/city'
import CityDetailsPage from './pages/city/details'
import CityHistoryPage from './pages/city/history'
import ClientDetailsPage from './pages/client/details'
import ClientsPage from './pages/client'
import ConfigPage from './pages/config'
import CouponDetailsPage from './pages/coupon/details'
import CouponHistoryPage from './pages/coupon/history'
import CouponsPage from './pages/coupon'
import CouponsUsagePage from './pages/coupon/usage'
import DashboardOutlet from './components/dashboard-outlet'
import DriverDetailsPage from './pages/driver/details'
import DriversPage from './pages/driver'
import EditCityPage from './pages/city/edit'
import EditCouponPage from './pages/coupon/edit'
import EditPublicNotificationPage from './pages/public-notification/edit'
import EditRequestPage from './pages/request/edit'
import EditRewardPage from './pages/reward/edit'
import EditRolePage from './pages/role/edit'
import EditUserPage from './pages/user/edit'
import EditVehicleCapacityPage from './pages/vehicle-capacity/edit'
import EditVehicleCategoryPage from './pages/vehicle-category/edit'
import ForbiddenPage from './pages/error/403'
import HomePage from './pages/home'
import NotFoundPage from './pages/error/404'
import PaymentDetailsPage from './pages/payment/details'
import PaymentsPage from './pages/payment'
import PermissionsPage from './pages/permission'
import PublicNotificationDetailsPage from './pages/public-notification/details'
import PublicNotificationsPage from './pages/public-notification'
import RequestDetailsPage from './pages/request/details'
import RequestsPage from './pages/request'
import RewardDetailsPage from './pages/reward/details'
import RewardHistoryPage from './pages/reward/history'
import RewardsEarningPage from './pages/reward/earning'
import RewardsPage from './pages/reward'
import RoleDetailsPage from './pages/role/details'
import RolesPage from './pages/role'
import TrackingPage from './pages/tracking'
import UserDetailsPage from './pages/user/details'
import UsersPage from './pages/user'
import VehicleCapacitiesPage from './pages/vehicle-capacity'
import VehicleCapacityDetailsPage from './pages/vehicle-capacity/details'
import VehicleCategoriesPage from './pages/vehicle-category'
import VehicleCategoryDetailsPage from './pages/vehicle-category/details'
import VehicleCategoryHistoryPage from './pages/vehicle-category/history'
import { createBrowserRouter, Outlet, RouteObject } from 'react-router-dom'
import DriversTrashPage from './pages/driver/trash'
import ClientsTrashPage from './pages/client/trash'

const routes: RouteObject[] = [
	{
		element: (
			<AuthContextProvider>
				<Outlet />
			</AuthContextProvider>
		),
		errorElement: <NotFoundPage />,
		children: [
			{
				path: '/auth',
				element: <AuthPage />
			},
			{
				path: '/',
				element: <DashboardOutlet />,
				children: [
					{
						path: '',
						element: <HomePage />
					},
					{
						path: 'tracking',
						element: <TrackingPage />
					},
					{
						path: 'config',
						element: <ConfigPage />
					},
					{
						path: 'permission',
						element: <PermissionsPage />
					},
					{
						path: 'role',
						element: <Outlet />,
						children: [
							{ path: '', element: <RolesPage /> },
							{ path: 'add', element: <AddRolePage /> },
							{ path: ':id', element: <RoleDetailsPage /> },
							{ path: ':id/edit', element: <EditRolePage /> }
						]
					},
					{
						path: 'user',
						element: <Outlet />,
						children: [
							{ path: '', element: <UsersPage /> },
							{ path: 'add', element: <AddUserPage /> },
							{ path: ':id', element: <UserDetailsPage /> },
							{ path: ':id/edit', element: <EditUserPage /> }
						]
					},
					{
						path: 'city',
						element: <Outlet />,
						children: [
							{ path: '', element: <CitiesPage /> },
							{ path: 'add', element: <AddCityPage /> },
							{ path: ':id', element: <CityDetailsPage /> },
							{ path: ':id/edit', element: <EditCityPage /> },
							{ path: ':id/history', element: <CityHistoryPage /> }
						]
					},
					{
						path: 'public-notification',
						element: <Outlet />,
						children: [
							{ path: '', element: <PublicNotificationsPage /> },
							{ path: 'add', element: <AddPublicNotificationPage /> },
							{ path: ':id', element: <PublicNotificationDetailsPage /> },
							{ path: ':id/edit', element: <EditPublicNotificationPage /> }
						]
					},
					{
						path: 'vehicle-category',
						element: <Outlet />,
						children: [
							{ path: '', element: <VehicleCategoriesPage /> },
							{ path: 'add', element: <AddVehicleCategoryPage /> },
							{ path: ':id', element: <VehicleCategoryDetailsPage /> },
							{ path: ':id/edit', element: <EditVehicleCategoryPage /> },
							{ path: ':id/history', element: <VehicleCategoryHistoryPage /> }
						]
					},
					{
						path: 'vehicle-capacity',
						element: <Outlet />,
						children: [
							{ path: '', element: <VehicleCapacitiesPage /> },
							{ path: 'add', element: <AddVehicleCapacityPage /> },
							{ path: ':id', element: <VehicleCapacityDetailsPage /> },
							{ path: ':id/edit', element: <EditVehicleCapacityPage /> }
						]
					},
					{
						path: 'client',
						element: <Outlet />,
						children: [
							{ path: '', element: <ClientsPage /> },
							{ path: ':id', element: <ClientDetailsPage /> }
						]
					},
					{
						path: 'client-trash',
						element: <ClientsTrashPage />
					},
					{
						path: 'driver',
						element: <Outlet />,
						children: [
							{ path: '', element: <DriversPage /> },
							{ path: ':id', element: <DriverDetailsPage /> }
						]
					},
					{
						path: 'driver-trash',
						element: <DriversTrashPage />
					},
					{
						path: 'payment',
						element: <Outlet />,
						children: [
							{ path: '', element: <PaymentsPage /> },
							{ path: 'add', element: <AddPaymentPage /> },
							{ path: ':id', element: <PaymentDetailsPage /> }
						]
					},
					{
						path: 'request',
						element: <Outlet />,
						children: [
							{ path: '', element: <RequestsPage /> },
							{ path: 'add', element: <AddRequestPage /> },
							{ path: ':id', element: <RequestDetailsPage /> },
							{ path: ':id/edit', element: <EditRequestPage /> }
						]
					},
					{
						path: 'coupon',
						element: <Outlet />,
						children: [
							{ path: '', element: <CouponsPage /> },
							{ path: 'add', element: <AddCouponPage /> },
							{ path: ':id', element: <CouponDetailsPage /> },
							{ path: ':id/edit', element: <EditCouponPage /> },
							{ path: ':id/history', element: <CouponHistoryPage /> }
						]
					},
					{
						path: 'coupon-usage',
						element: <CouponsUsagePage />
					},
					{
						path: 'reward',
						element: <Outlet />,
						children: [
							{ path: '', element: <RewardsPage /> },
							{ path: 'add', element: <AddRewardPage /> },
							{ path: ':id', element: <RewardDetailsPage /> },
							{ path: ':id/edit', element: <EditRewardPage /> },
							{ path: ':id/history', element: <RewardHistoryPage /> }
						]
					},
					{
						path: 'reward-earning',
						element: <RewardsEarningPage />
					},
					{
						path: 'accident',
						element: <Outlet />,
						children: [
							{ path: '', element: <AccidentsPage /> },
							{ path: ':id', element: <AccidentDetailsPage /> }
						]
					}
				]
			},
			{
				path: '/404',
				element: <NotFoundPage />
			},
			{
				path: '/403',
				element: <ForbiddenPage />
			}
		]
	}
]

const router = createBrowserRouter(routes)

export default router
