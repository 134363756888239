import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { RewardGettingWay } from '../../../models/reward'

interface IProps {
  way: RewardGettingWay
}

const RewardGettingWayTag: React.FC<IProps> = ({ way }) => {
  const { t } = useTranslation()

  let label = ''
  let color = ''

  switch (way) {
    case RewardGettingWay.POINT:
      label = t('reward.point')
      color = 'cyan'
      break
    case RewardGettingWay.RATE:
      label = t('reward.rate')
      color = 'orange'
      break
  }

  return <Tag color={color}>{label}</Tag>
}

export default RewardGettingWayTag
