import { Button, Card, Form, FormInstance, Input, Select, Spin } from 'antd'
import React from 'react'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { CheckOutlined } from '@ant-design/icons'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import {
  IEditPublicNotification,
  IPublicNotificationDetails,
  PublicNotificationTo,
} from '../../../models/public-notification'

const EditPublicNotificationPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { id } = useParams()

  const formRef = React.useRef<FormInstance>(null)

  const queryClient = useQueryClient()

  const query = useQuery({
    queryKey: ['public-notification', id],
    queryFn: async () => {
      return axios.get<IPublicNotificationDetails>(`/notification-public/${id}`)
    },
    onSuccess: (data) => {
      formRef.current?.setFieldsValue({
        title: data.data.title,
        body: data.data.body,
        to: data.data.to,
        note: data.data.note,
      })
    },
    refetchOnWindowFocus: false,
  })

  const mutation = useMutation({
    mutationFn: (data: IEditPublicNotification) => {
      return axios.put(`/notification-public/${id}`, data)
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['public-notifications'] })
      queryClient.invalidateQueries({
        queryKey: ['public-notifications-light'],
      })
      queryClient.invalidateQueries({ queryKey: ['public-notification', id] })
      navigate(-1)
    },
  })

  const onSubmit = (data: any) => {
    mutation.mutate({
      title: data['title'],
      body: data['body'],
      to: data['to'],
      note: data['note'],
    })
  }

  return (
    <Form
      name="form_item_path"
      layout="vertical"
      ref={formRef}
      onFinish={onSubmit}
    >
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('public_notification.edit')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Button
              type="default"
              disabled={mutation.isLoading || query.isFetching}
              onClick={() => navigate(-1)}
            >
              {t('general.cancel')}
            </Button>
            <Button
              className={styles.saveButton}
              type="primary"
              htmlType="submit"
              icon={<CheckOutlined />}
              loading={mutation.isLoading}
              disabled={query.isFetching}
            >
              {t('general.save')}
            </Button>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: '1rem' }}>
        <Spin tip={t('general.loading')} spinning={query.isFetching}>
          <Form.Item
            name="title"
            label={t('public_notification.title')}
            required
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="body"
            label={t('public_notification.body')}
            required
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="to"
            label={t('public_notification.to')}
            required
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Select
              options={[
                {
                  label: t('public_notification.client'),
                  value: PublicNotificationTo.CLIENT,
                },
                {
                  label: t('public_notification.driver'),
                  value: PublicNotificationTo.DRIVER,
                },
                {
                  label: t('public_notification.both'),
                  value: PublicNotificationTo.BOTH,
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="note" label={t('public_notification.note')}>
            <Input.TextArea />
          </Form.Item>
        </Spin>
      </Card>
    </Form>
  )
}

export default EditPublicNotificationPage
