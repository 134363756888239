import React from 'react'
import { Card, Button, Descriptions, Spin, Image } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import axios from 'axios'
import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '../../../utils/constants'
import { IAccidentDetails } from '../../../models/accident'
import BooleanTag from '../../../components/boolean-tag'

const AccidentDetailsPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { id } = useParams()

  const query = useQuery({
    queryKey: ['accident', id],
    queryFn: async () => {
      return axios.get<IAccidentDetails>(`/accident/${id}`)
    },
  })

  var data = query.data?.data

  return (
    <div>
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('accident.details')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Button type='default' onClick={() => navigate(-1)}>
              {t('general.cancel')}
            </Button>
            {/* <Button
              className={styles.saveButton}
              type='primary'
              icon={<EditOutlined />}
              onClick={() => navigate(`/vehicle-category/${id}/edit`)}
            >
              {t('general.edit')}
            </Button> */}
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: 0 }}>
        <Spin tip={t('general.loading')} spinning={query.isFetching}>
          <Descriptions bordered column={1}>
            <Descriptions.Item label={t('general.id')}>
              {data?.id}
            </Descriptions.Item>
            <Descriptions.Item label={t('accident.driver')}>
              {data?.driver.toString()}
            </Descriptions.Item>
            <Descriptions.Item label={t('accident.date_time')}>
              {dayjs(data?.dateTime).format(DATE_TIME_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label={t('accident.physical_injury')}>
              {data && <BooleanTag value={data.thereIsPhysicalInjury} />}
            </Descriptions.Item>
            <Descriptions.Item label={t('accident.description')}>
              {data?.description}
            </Descriptions.Item>
            <Descriptions.Item label={t('accident.location')}>
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${data?.lat},${data?.lng}`}
                target='_blank'
                rel='noreferrer'
              >
                {t('general.view_on_map')}
              </a>
            </Descriptions.Item>
            <Descriptions.Item label={t('accident.photo')}>
              {data?.photo && (
                <Image width={48} height={48} src={data.photo.url} />
              )}
            </Descriptions.Item>
            <Descriptions.Item label={t('accident.dealt')}>
              {data && <BooleanTag value={data.hasBeenDealt} />}
            </Descriptions.Item>
            <Descriptions.Item label={t('accident.note')}>
              {data?.note}
            </Descriptions.Item>
          </Descriptions>
        </Spin>
      </Card>
    </div>
  )
}

export default AccidentDetailsPage
