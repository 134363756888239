export const INITIAL_PAGE = 1
export const DEFAULT_PAGE_SIZE = 10
export const MAX_PAGE_SIZE = -1
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm A'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'HH:mm'
export const ACCESS_TOKEN_KEY = 'access-token'

export const DEFAULT_FUNCTION = () => null
export const DEFAULT_ASYNC_FUNCTION = async () => false
export const DEFAULT_SORT_FUNCTION = () => 0

export const DEFAULT_GOOGLE_MAP_PROPS = {
	zoom: 10,
	center: {
		lat: 33.314950902275804,
		lng: 44.36855720565817
	}
}
