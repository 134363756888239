import { ConfigProvider, notification as Notification } from 'antd'
import { Locale } from 'antd/es/locale'
import ar from 'antd/locale/ar_EG'
import en from 'antd/locale/en_US'
import ku from 'antd/locale/ku_IQ'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import { RouterProvider } from 'react-router-dom'
import router from './router'
import { ACCESS_TOKEN_KEY } from './utils/constants'
import './utils/http/axios-instance'
import './utils/localization/i18n'

const queryClient = new QueryClient()

const App: React.FC = () => {
	const {
		t,
		i18n: { dir, language }
	} = useTranslation()

	const [notification, notificationContextHolder] =
		Notification.useNotification()

	let locale: Locale = ar
	switch (language) {
		case 'ku':
			locale = ku
			break
		case 'ar':
			locale = ar
			break
		case 'en':
			locale = en
			break
	}

	const onError = (error: any) => {
		if (!error) return

		// Unauthenticated
		if (error.response?.status === 401) {
			// Clear Token
			localStorage.removeItem(ACCESS_TOKEN_KEY)

			if (window.location.pathname !== '/auth') {
				window.location.href = '/auth'
			}
		}

		// Forbidden
		if (error.response?.status === 403) {
			window.location.href = '/403'
		}

		notification.error({
			message: error.response
				? error.response.data.message
				: error.message ?? `${error}`,
			placement: dir() === 'rtl' ? 'bottomLeft' : 'bottomRight'
		})
	}

	const onSuccess = (data: unknown, error: unknown) => {
		if (error) return
		notification.success({
			message: t('operation_completed_successfully'),
			placement: dir() === 'rtl' ? 'bottomLeft' : 'bottomRight'
		})
	}

	queryClient.setDefaultOptions({
		queries: { onError },
		mutations: { onError, onSettled: onSuccess }
	})

	return (
		<QueryClientProvider client={queryClient}>
			<ConfigProvider
				theme={{
					token: {
						colorPrimary: '#f55a00',
						fontFamily: 'Cairo'
					}
				}}
				direction={dir()}
				locale={locale}
			>
				{notificationContextHolder}
				<RouterProvider router={router} />
			</ConfigProvider>
		</QueryClientProvider>
	)
}

export default App
