import { IDriverLight } from '../../models/driver'
import { toggleLoading } from '../../utils/helpers/functions'
import { IState, SocketLoading } from './context'

export type Action =
  | {
      type: 'LOADING'
      payload: { loading: SocketLoading | SocketLoading[] }
    }
  | {
      type: 'SET_DRIVERS_HAVE_REQUESTS'
      payload: { driversHaveRequest: IDriverLight[] }
    }
  | {
      type: 'SET_AVAILABLE_DRIVERS_WITH_NO_REQUEST'
      payload: { availableDriversWithNoRequest: IDriverLight[] }
    }

const reducer = (state: IState, action: Action): IState => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      }
    case 'SET_DRIVERS_HAVE_REQUESTS':
      return { ...state, driversHaveRequest: action.payload.driversHaveRequest }

    case 'SET_AVAILABLE_DRIVERS_WITH_NO_REQUEST':
      return {
        ...state,
        availableDriversWithNoRequest:
          action.payload.availableDriversWithNoRequest,
      }
    default:
      return state
  }
}

export default reducer
