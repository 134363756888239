import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { CouponStatus } from '../../../models/coupon'

interface IProps {
  status: CouponStatus
}

const CouponStatusTag: React.FC<IProps> = ({ status }) => {
  const { t } = useTranslation()

  let label = ''
  let color = ''

  switch (status) {
    case CouponStatus.ACTIVE:
      label = t('coupon.active')
      color = 'green'
      break
    case CouponStatus.NOT_ACTIVE:
      label = t('coupon.not_active')
      color = 'red'
      break
  }

  return <Tag color={color}>{label}</Tag>
}

export default CouponStatusTag
