import {
	IRequestLocalizedProperty,
	LocalizedProperty
} from './localized-property'
import { IUserLight } from './user'

export enum RewardStatus {
	ACTIVE = 1,
	NOT_ACTIVE = 2
}

export enum RewardGettingWay {
	POINT = 1,
	RATE = 2
}

export interface IReward {
	id: number
	name: string
	value: number
	status: RewardStatus
	month: string
	year: number
	wayGetReward: RewardGettingWay
	numOfPoint?: number
	numOfRate?: number
}

export interface IRewardLight {
	id: number
	name: string
}

export interface IRewardDetails extends IReward {
	note?: string
	createdAt?: Date
	createdBy?: IUserLight
	updatedAt?: Date
	updatedBy?: IUserLight
	translations: {
		name: LocalizedProperty
	}
}

export interface IAddReward {
	name: IRequestLocalizedProperty
	note?: string
	value: number
	status: RewardStatus
	month: string
	year: number
	wayGetReward: RewardGettingWay
	numOfPoint?: number
	numOfRate?: number
}

export interface IEditReward {
	name: IRequestLocalizedProperty
	note?: string
	value: number
	status: RewardStatus
	month: string
	year: number
	wayGetReward: RewardGettingWay
	numOfPoint?: number
	numOfRate?: number
}

export interface IRewardEarning {
	id: number
	name: string
	value: number
	month: string
	year: number
	firstName: string
	lastName: string
	rewardDate: Date
	points: number
	rating: number
}
