import React from 'react'
import { Card, Button, Descriptions, Spin, Space, Tag, Image } from 'antd'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import axios from 'axios'
import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '../../../utils/constants'
import UserStatusTag from '../../../components/enums/user-status-tag'
import { IDriverDetails } from '../../../models/driver'
import DriverStatusTag from '../../../components/enums/driver-status-tag'

const DriverDetailsPage: React.FC = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const { id } = useParams()

	const query = useQuery({
		queryKey: ['driver', id],
		queryFn: async () => {
			return axios.get<IDriverDetails>(`/drivers/${id}`)
		}
	})

	const data = query.data?.data

	return (
		<div>
			<Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
				<div className={styles.headerCardTitle}>
					<h3>{t('driver.details')}</h3>
					<div className={styles.headerCardTitleTrailing}>
						<Button type='default' onClick={() => navigate(-1)}>
							{t('general.cancel')}
						</Button>
					</div>
				</div>
			</Card>
			<Card bodyStyle={{ padding: 0 }}>
				<Spin tip={t('general.loading')} spinning={query.isFetching}>
					<Descriptions bordered column={1}>
						<Descriptions.Item label={t('general.id')}>
							{data?.id}
						</Descriptions.Item>
						<Descriptions.Item label={t('driver.photo')}>
							<Image
								width={48}
								height={48}
								style={{
									objectFit: 'contain'
								}}
								src={data?.user?.photo?.url}
							/>
						</Descriptions.Item>
						<Descriptions.Item label={t('driver.first_name')}>
							{data?.user.firstName}
						</Descriptions.Item>
						<Descriptions.Item label={t('driver.last_name')}>
							{data?.user.lastName}
						</Descriptions.Item>
						<Descriptions.Item label={t('driver.mobile')}>
							{data?.user.mobile}
						</Descriptions.Item>
						<Descriptions.Item label={t('driver.account_status')}>
							{data?.driverAccountStatus && (
								<UserStatusTag status={data?.driverAccountStatus} />
							)}
						</Descriptions.Item>
						<Descriptions.Item label={t('driver.status')}>
							{data?.status && <DriverStatusTag status={data?.status} />}
						</Descriptions.Item>

						<Descriptions.Item label={t('driver.license_photo')}>
							<Image
								width={48}
								height={48}
								style={{
									objectFit: 'contain'
								}}
								src={data?.license?.url}
							/>
						</Descriptions.Item>

						<Descriptions.Item label={t('driver.wallet_value')}>
							{data?.wallet.value}
						</Descriptions.Item>
						<Descriptions.Item label={t('driver.sum_request')}>
							{data?.sumRequest}
						</Descriptions.Item>
						<Descriptions.Item label={t('driver.points')}>
							{data?.points}
						</Descriptions.Item>
						<Descriptions.Item label={t('driver.rating')}>
							{data?.rating}
						</Descriptions.Item>
						<Descriptions.Item label={t('driver.count_request')}>
							{data?.countRequest}
						</Descriptions.Item>
						<Descriptions.Item label={t('driver.vehicle_photo')}>
							<Image
								width={48}
								height={48}
								style={{
									objectFit: 'contain'
								}}
								src={data?.crane?.photo?.url}
							/>
						</Descriptions.Item>
						<Descriptions.Item label={t('driver.vehicle_category')}>
							{data?.crane.category.name}
						</Descriptions.Item>
						<Descriptions.Item label={t('driver.vehicle_code')}>
							{data?.crane.code}
						</Descriptions.Item>
						<Descriptions.Item label={t('driver.vehicle_capacity')}>
							{data?.crane.category?.capacity?.name}
						</Descriptions.Item>
						<Descriptions.Item label={t('driver.vehicle_number')}>
							{data?.crane.number}
						</Descriptions.Item>
						<Descriptions.Item label={t('driver.cities')}>
							<Space>
								{data?.cities?.map((c) => (
									<Tag key={c.id}>{c.name}</Tag>
								))}
							</Space>
						</Descriptions.Item>
						<Descriptions.Item label={t('driver.note')} span={3}>
							{data?.note}
						</Descriptions.Item>
						<Descriptions.Item label={t('general.last_usage_date')}>
							{data?.dateLastRequest &&
								dayjs(data?.dateLastRequest).format(DATE_TIME_FORMAT)}
						</Descriptions.Item>
						<Descriptions.Item label={t('general.created_at')}>
							{dayjs(data?.createdAt).format(DATE_TIME_FORMAT)}
						</Descriptions.Item>
						<Descriptions.Item label={t('general.updated_at')}>
							{dayjs(data?.updatedAt).format(DATE_TIME_FORMAT)}
						</Descriptions.Item>
						<Descriptions.Item label={t('general.updated_by')}>
							{data?.updatedBy &&
								`${data?.updatedBy?.firstName} ${data?.updatedBy?.lastName}`}
						</Descriptions.Item>
						<Descriptions.Item label={t('general.deleted_at')}>
							{data?.deletedAt &&
								dayjs(data?.deletedAt).format(DATE_TIME_FORMAT)}
						</Descriptions.Item>
					</Descriptions>
				</Spin>
			</Card>
		</div>
	)
}

export default DriverDetailsPage
