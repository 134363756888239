import dayjs from 'dayjs'
import { Bar } from 'react-chartjs-2'
import { Card, Col, Row, Spin, TimeRangePickerProps } from 'antd'
import { FC, useState } from 'react'
import { t } from 'i18next'
import {
  ISecondStatisticsFilter,
  IStatisticsNewClient,
  IStatisticsNewDriver,
} from '../../../../models/home'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import type { Dayjs } from 'dayjs'
import RangePickerPopover from '../../rang-picker-popover'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface IProps {
  newDrivers: IStatisticsNewDriver[]
  newClients: IStatisticsNewClient[]
  loading: boolean
  secondQueryFilter?: ISecondStatisticsFilter
  setSecondQueryFilter?: (filter: ISecondStatisticsFilter) => void
}

const NewDriversAndClinetsChart: FC<IProps> = ({
  newDrivers,
  newClients,
  loading,
  secondQueryFilter,
  setSecondQueryFilter,
}) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
  }

  // Extracting months and driver_counts for the chart data
  const driversMonths = newDrivers.map((entry) =>
    dayjs(entry.date).format('YYYY-MM')
  )
  const driverCounts = newDrivers.map((entry) => entry.driver_count)

  // Extracting months and driver_counts for the chart data
  const clientsMonths = newClients.map((entry) =>
    dayjs(entry.date).format('YYYY-MM')
  )
  const clientCounts = newClients.map((entry) => entry.client_count)

  // Mix Dates (driversMonths & clientsMonths)
  const combinedMonths = [...driversMonths, ...clientsMonths]
  const mixMonths = Array.from(new Set(combinedMonths)).sort()

  // Chart data
  const data = {
    labels: mixMonths,
    datasets: [
      {
        label: t('home.new_drivers'),
        data: driverCounts,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: t('home.new_clients'),
        data: clientCounts,
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  }

  return (
    <Spin spinning={loading}>
      <Card
        title={
          <Row gutter={[16, 16]} justify={'space-between'} align={'middle'}>
            <Col>{t('home.new_drivers_vs_new_clients')}</Col>

            <Col>
              <RangePickerPopover
                onChange={(fromDate, toDate) => {
                  setSecondQueryFilter &&
                    setSecondQueryFilter({
                      ...secondQueryFilter,
                      'newDriver[start]': fromDate,
                      'newDriver[end]': toDate,
                      'newClient[start]': fromDate,
                      'newClient[end]': toDate,
                    })
                }}
              />
            </Col>
          </Row>
        }
        headStyle={{
          textAlign: 'center',
        }}
        bodyStyle={{
          height: '28rem',
          width: '100%',
        }}
      >
        <Bar
          options={options}
          data={data}
          style={{
            width: '100%',
          }}
        />
      </Card>
    </Spin>
  )
}

export default NewDriversAndClinetsChart
