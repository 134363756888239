import axios from 'axios'
import dayjs from 'dayjs'
import DriverStatusTag from '../../../components/enums/driver-status-tag'
import React, { useContext, useState } from 'react'
import UserStatusTag from '../../../components/enums/user-status-tag'
import {
	Button,
	Card,
	Col,
	Form,
	Popconfirm,
	Row,
	Select,
	Space,
	Table,
	Tag
} from 'antd'
import { DriverStatus, IDriver } from '../../../models/driver'
import {
	EditOutlined,
	EyeOutlined,
	FileExcelOutlined,
	DeleteOutlined
} from '@ant-design/icons'
import { ICity } from '../../../models/city'
import { IPaginatedList } from '../../../models/paginated-list'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { UserStatus } from '../../../models/user'
import { useTranslation } from 'react-i18next'
import type { ColumnsType } from 'antd/es/table'
import {
	DATE_TIME_FORMAT,
	DEFAULT_PAGE_SIZE,
	DEFAULT_SORT_FUNCTION,
	INITIAL_PAGE
} from '../../../utils/constants'
import HeaderCard from '../../../components/common/header-card'
import { IFilter, ISort } from '../../../models/shared'
import { SorterResult } from 'antd/es/table/interface'
import ExportToExcelModal from '../../../components/common/export-to-excel-modal'
import AuthContext from '../../../contexts/auth-context/context'
import {
	CAN_DELETE_DRIVER,
	CAN_EXPORT_DRIVERS,
	CAN_UPDATE_DRIVER
} from '../../../utils/rbac/permissions'

const DriversTrashPage: React.FC = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const {
		actions: { can }
	} = useContext(AuthContext)

	const [page, setPage] = useState(INITIAL_PAGE)
	const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE)
	const [search, setSearch] = useState<string | undefined>()
	const [sort, setSort] = useState<ISort | undefined>()
	const [exportModelVisible, setExportModalVisible] = useState(false)

	const queryClient = useQueryClient()

	const query = useQuery({
		queryKey: ['drivers-trash', page, perPage, search, sort],
		queryFn: async () => {
			return axios.get<IPaginatedList<IDriver>>('/drivers', {
				params: {
					page,
					perPage,
					search,
					filters: [
						{
							name: 'deletedAt',
							operation: '!=',
							value: 'null'
						}
					],
					orders: [sort]
				}
			})
		}
	})

	const columns: ColumnsType<IDriver> = [
		{
			title: t('general.id'),
			dataIndex: 'id',
			key: 'id',
			render: (id) => <Link to={`/driver/${id}`}>{id}</Link>,
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('driver.first_name'),
			dataIndex: 'user',
			key: 'user.firstName',
			render: (user) => user.firstName,
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('driver.last_name'),
			dataIndex: 'user',
			key: 'user.lastName',
			render: (user) => user.lastName,
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('driver.mobile'),
			dataIndex: 'user',
			key: 'user.mobile',
			render: (user) => user.mobile
		},
		{
			title: t('driver.account_status'),
			dataIndex: 'driverAccountStatus',
			key: 'driverAccountStatus',
			render: (status: UserStatus) => <UserStatusTag status={status} />
		},
		{
			title: t('driver.status'),
			dataIndex: 'status',
			key: 'status',
			render: (status: DriverStatus) => <DriverStatusTag status={status} />
		},
		{
			title: t('driver.vehicle_category'),
			dataIndex: 'crane',
			key: 'crane.id',
			render: (crane) => crane.category.name
		},
		{
			title: t('general.created_at'),
			dataIndex: 'user',
			key: 'user.createdAt',
			render: (user) => dayjs(user.createdAt).format(DATE_TIME_FORMAT),
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('driver.cities'),
			dataIndex: 'cities',
			key: 'cities',
			render: (cities: ICity[]) => (
				<Space>
					{cities?.map((c) => (
						<Tag key={c.id}>{c.name}</Tag>
					))}
				</Space>
			)
		},
		{
			title: t('general.last_usage_date'),
			dataIndex: 'dateLastRequest',
			key: 'dateLastRequest',
			render: (dateLastRequest) =>
				dateLastRequest && dayjs(dateLastRequest).format(DATE_TIME_FORMAT),
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('driver.wallet_value'),
			dataIndex: 'wallet',
			key: 'wallet.value',
			render: (wallet) => wallet.value,
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('driver.sum_request'),
			dataIndex: 'sumRequest',
			key: 'sumRequest',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('driver.points'),
			dataIndex: 'points',
			key: 'points',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('driver.rating'),
			dataIndex: 'rating',
			key: 'rating',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('general.deleted_at'),
			dataIndex: 'deletedAt',
			key: 'deletedAt',
			render: (deletedAt) => dayjs(deletedAt).format(DATE_TIME_FORMAT),
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('general.actions'),
			key: 'actions',
			render: (_, record) => (
				<Space>
					<Button
						type='link'
						icon={<EyeOutlined />}
						onClick={() => navigate(`/driver/${record.id}`)}
					>
						{t('general.show')}
					</Button>
				</Space>
			)
		}
	]

	return (
		<>
			<HeaderCard
				title={t('sider.deleted_drivers')}
				onSearch={(value) => {
					setSearch(value.length === 0 ? undefined : value)
				}}
				trailing={[
					can(CAN_EXPORT_DRIVERS) ? (
						<>
							<Button
								type='default'
								icon={<FileExcelOutlined />}
								onClick={() => setExportModalVisible(true)}
							>
								{t('general.export_to_excel')}
							</Button>
							<ExportToExcelModal
								visible={exportModelVisible}
								setVisible={setExportModalVisible}
								exportUrl={'/drivers/export'}
								columns={[
									{ name: 'id', label: 'general.id' },
									{ name: 'status', label: 'driver.status' },
									{
										name: 'driverAccountStatus',
										label: 'driver.account_status'
									},
									{ name: 'sumRequest', label: 'driver.sum_request' },
									{ name: 'firstName', label: 'driver.first_name' },
									{ name: 'lastName', label: 'driver.last_name' },
									{ name: 'mobile', label: 'driver.mobile' },
									{ name: 'points', label: 'driver.points' },
									{ name: 'craneNumber', label: 'driver.crane_number' },
									{ name: 'craneCode', label: 'driver.crane_code' },
									{ name: 'craneCapacity', label: 'driver.crane_capacity' },
									{ name: 'createdAt', label: 'general.created_at' },
									{ name: 'wallet', label: 'driver.wallet' }
								]}
							/>
						</>
					) : null
				].filter((i) => i != null)}
			/>
			<Card bordered={false} bodyStyle={{ padding: 0 }}>
				<Table
					loading={query.isFetching}
					columns={columns}
					dataSource={query.data?.data.data}
					rowKey={(record) => record.id}
					style={{ overflowX: 'scroll' }}
					onChange={(_, __, sorter, ___) => {
						const { columnKey, order } = sorter as SorterResult<IDriver>

						if (!order) {
							setSort(undefined)
						} else {
							setSort({
								name: columnKey as string,
								direction: order === 'ascend' ? 'asc' : 'desc'
							})
						}
					}}
					pagination={{
						current: page,
						pageSize: perPage,
						pageSizeOptions: [5, 10, 20, 50, 100],
						showSizeChanger: true,
						total: query.data?.data.total,
						position: ['bottomCenter'],
						onChange(page, pageSize) {
							setPage(page)
							setPerPage(pageSize)
						},
						showTotal: (total, range) => {
							return `${t('general.showing')} ${range[0]} - ${range[1]} ${t(
								'general.from'
							)} ${total} ${t('general.items')}`
						}
					}}
				/>
			</Card>
		</>
	)
}

export default DriversTrashPage
