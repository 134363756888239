import { Button, Card, Form, FormInstance, Input, Select } from 'antd'
import React from 'react'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { CheckOutlined } from '@ant-design/icons'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { IAddUser, UserStatus } from '../../../models/user'
import { IPaginatedList } from '../../../models/paginated-list'
import { IRoleLight } from '../../../models/role'
import { MAX_PAGE_SIZE } from '../../../utils/constants'

const AddUserPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const formRef = React.useRef<FormInstance>(null)

  const queryClient = useQueryClient()

  const rolesQuery = useQuery({
    queryKey: ['roles-light'],
    queryFn: async () => {
      return axios.get<IPaginatedList<IRoleLight>>('/roles', {
        params: {
          minimal: 1,
          perPage: MAX_PAGE_SIZE,
        },
      })
    },
  })

  const mutation = useMutation({
    mutationFn: (data: IAddUser) => {
      return axios.post('/users', data)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['users'] })
      queryClient.invalidateQueries({ queryKey: ['users-light'] })
      navigate(-1)
    },
  })

  const onSubmit = (data: any) => {
    mutation.mutate({
      firstName: data['firstName'],
      lastName: data['lastName'],
      roles: data['roles'],
      status: data['status'],
      mobile: data['mobile'],
      password: data['password'],
      email: data['email'],
      address: data['address'],
      note: data['note']
    })
  }

  return (
    <Form
      name="form_item_path"
      layout="vertical"
      onFinish={onSubmit}
      ref={formRef}
    >
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('user.add')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Button
              type="default"
              disabled={mutation.isLoading}
              onClick={() => navigate(-1)}
            >
              {t('general.cancel')}
            </Button>
            <Button
              className={styles.saveButton}
              type="primary"
              htmlType="submit"
              icon={<CheckOutlined />}
              loading={mutation.isLoading}
            >
              {t('general.save')}
            </Button>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: '1rem' }}>
        <Form.Item
          name="firstName"
          label={t('user.first_name')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastName"
          label={t('user.last_name')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="roles"
          label={t('user.roles')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Select
            mode="multiple"
            options={rolesQuery.data?.data?.data?.map((r) => {
              return {
                value: r.id,
                label: r.name,
              }
            })}
          />
        </Form.Item>
        <Form.Item
          name="status"
          label={t('user.status')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Select
            options={[
              {
                label: t('user.active'),
                value: UserStatus.ACTIVE,
              },
              {
                label: t('user.not_active'),
                value: UserStatus.NOT_ACTIVE,
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="mobile"
          label={t('user.mobile')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="email"
          label={t('user.email')}
          required
          rules={[
            { required: true, message: t('validation.required') },
            { type: 'email', message: t('validation.email') },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label={t('user.password')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="password_confirmation"
          label={t('user.password_confirmation')}
          required
          rules={[
            { required: true, message: t('validation.required') },
            {
              validator: (_, value, callback) => {
                const password = formRef.current?.getFieldValue('password')
                if (value !== password) {
                  callback(t('validation.password_confirmation'))
                } else {
                  callback()
                }
              },
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="address"
          label={t('user.address')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="note" label={t('user.note')}>
          <Input.TextArea />
        </Form.Item>
      </Card>
    </Form>
  )
}

export default AddUserPage
