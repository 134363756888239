import React, { useContext, useState } from 'react'
import {
	Space,
	Table,
	Card,
	Button,
	Popconfirm,
	Col,
	DatePicker,
	Form,
	Row,
	Select,
	Modal
} from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import {
	PlusOutlined,
	EyeOutlined,
	EditOutlined,
	DeleteOutlined,
	FileExcelOutlined
} from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { IPaginatedList } from '../../models/paginated-list'
import {
	DATE_TIME_FORMAT,
	DEFAULT_PAGE_SIZE,
	DEFAULT_SORT_FUNCTION,
	INITIAL_PAGE,
	MAX_PAGE_SIZE
} from '../../utils/constants'
import axios from 'axios'
import { DurationType, IRequest, RequestStatus } from '../../models/request'
import VehicleCategoryTypeTag from '../../components/enums/vehicle-category-type-tag'
import RequestStatusTag from '../../components/enums/request-status-tag'
import dayjs from 'dayjs'
import HeaderCard from '../../components/common/header-card'
import { IFilter, ISort } from '../../models/shared'
import DurationTypeTag from '../../components/enums/duration-type-tag'
import { SorterResult } from 'antd/es/table/interface'
import { IClientLight } from '../../models/client'
import { IDriverLight } from '../../models/driver'
import { PaymentType, PaymentMethod } from '../../models/payment'
import { VehicleCategoryType } from '../../models/vehicle-category'
import ExportToExcelModal from '../../components/common/export-to-excel-modal'
import AuthContext from '../../contexts/auth-context/context'
import {
	CAN_CREATE_REQUEST,
	CAN_DELETE_REQUEST,
	CAN_EXPORT_REQUESTS,
	CAN_UPDATE_REQUEST
} from '../../utils/rbac/permissions'

const RequestsPage: React.FC = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const {
		actions: { can }
	} = useContext(AuthContext)

	const [page, setPage] = useState(INITIAL_PAGE)
	const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE)
	const [search, setSearch] = useState<string | undefined>()
	const [filters, setFilters] = useState<IFilter[]>()
	const [sort, setSort] = useState<ISort | undefined>()
	const [exportModelVisible, setExportModalVisible] = useState(false)

	var queryClient = useQueryClient()

	const query = useQuery({
		queryKey: ['requests', page, perPage, search, filters, sort],
		queryFn: async () => {
			return axios.get<IPaginatedList<IRequest>>('/request', {
				params: {
					page,
					perPage,
					search,
					filters,
					orders: [sort]
				}
			})
		}
	})

	const mutation = useMutation({
		mutationFn: (id: number) => {
			return axios.delete(`/request/${id}`)
		},
		onSuccess: (_, id) => {
			if (query.data?.data.data.length === 1) {
				setPage(INITIAL_PAGE)
			}
			queryClient.invalidateQueries({ queryKey: ['requests'] })
			queryClient.invalidateQueries({ queryKey: ['request', id] })
		}
	})

	const clientsQuery = useQuery({
		queryKey: ['client-light'],
		queryFn: async () => {
			return axios.get<IPaginatedList<IClientLight>>('/clients', {
				params: {
					minimal: 1,
					perPage: MAX_PAGE_SIZE
				}
			})
		}
	})

	const driversQuery = useQuery({
		queryKey: ['drivers-light'],
		queryFn: async () => {
			return axios.get<IPaginatedList<IDriverLight>>('/drivers', {
				params: {
					minimal: 1,
					perPage: MAX_PAGE_SIZE
				}
			})
		}
	})

	const columns: ColumnsType<IRequest> = [
		{
			title: t('general.id'),
			dataIndex: 'id',
			key: 'id',
			render: (id) => <Link to={`/request/${id}`}>{id}</Link>,
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('request.client'),
			dataIndex: 'client',
			render: (client) => client && `${client.firstName} ${client.lastName}`
		},
		{
			title: t('request.vehicle_category'),
			dataIndex: 'category',
			render: (category) => category.name
		},
		{
			title: t('request.vehicle_category_type'),
			dataIndex: 'category',
			render: (category) => <VehicleCategoryTypeTag type={category.type} />
		},
		{
			title: t('request.driver'),
			dataIndex: 'driver',
			render: (driver) => driver && `${driver.firstName} ${driver.lastName}`
		},
		{
			title: t('request.status'),
			dataIndex: 'status',
			render: (status) => <RequestStatusTag status={status} />
		},
		{
			title: t('request.duration_type'),
			dataIndex: 'durationType',
			render: (type) => <DurationTypeTag type={type} />
		},
		{
			title: t('request.scheduled_date'),
			dataIndex: 'scheduledDate',
			key: 'scheduledDate',
			render: (date) => date && dayjs(date).format(DATE_TIME_FORMAT),
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('general.created_at'),
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (date) => dayjs(date).format(DATE_TIME_FORMAT),
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('request.price'),
			dataIndex: 'price'
		},
		{
			title: t('request.rate'),
			dataIndex: 'rate'
		},
		{
			title: t('request.percent'),
			dataIndex: 'percent',
			render: (percent) => `${percent}%`
		},
		{
			title: t('general.actions'),
			render: (_, record) => (
				<Space>
					<Button
						type='link'
						icon={<EyeOutlined />}
						onClick={() => navigate(`/request/${record.id}`)}
					>
						{t('general.show')}
					</Button>
					{can(CAN_UPDATE_REQUEST) && (
						<Button
							type='link'
							icon={<EditOutlined />}
							onClick={() => navigate(`/request/${record.id}/edit`)}
						>
							{t('general.edit')}
						</Button>
					)}
					{can(CAN_DELETE_REQUEST) && (
						<Popconfirm
							title={t('general.delete_warning')}
							description={t('general.delete_confirmation')}
							onConfirm={() => mutation.mutate(record.id)}
							okText={t('general.yes')}
							cancelText={t('general.no')}
						>
							<Button type='link' icon={<DeleteOutlined />} danger>
								{t('general.delete')}
							</Button>
						</Popconfirm>
					)}
				</Space>
			)
		}
	]

	return (
		<>
			<HeaderCard
				title={t('request.label')}
				onSearch={(value) => {
					setSearch(value.length === 0 ? undefined : value)
				}}
				trailing={[
					can(CAN_CREATE_REQUEST) ? (
						<Button
							type='primary'
							icon={<PlusOutlined />}
							onClick={() => navigate('/request/add')}
						>
							{t('general.add')}
						</Button>
					) : null,
					can(CAN_EXPORT_REQUESTS) ? (
						<>
							<Button
								type='default'
								icon={<FileExcelOutlined />}
								onClick={() => setExportModalVisible(true)}
							>
								{t('general.export_to_excel')}
							</Button>
							<ExportToExcelModal
								visible={exportModelVisible}
								setVisible={setExportModalVisible}
								exportUrl={'/request/export'}
								columns={[
									{ name: 'id', label: 'general.id' },
									{ name: 'status', label: 'request.status' },
									{
										name: 'reasonCancellation',
										label: 'request.cancellation_reason'
									},
									{ name: 'durationType', label: 'request.duration_type' },
									{
										name: 'requiredDuration',
										label: 'request.required_duration'
									},
									{ name: 'scheduledDate', label: 'request.scheduled_date' },
									{ name: 'weight', label: 'request.weight' },
									{ name: 'price', label: 'request.price' },
									{ name: 'rate', label: 'request.rate' },
									{
										name: 'estimatedDuration',
										label: 'request.estimated_duration'
									},
									{ name: 'percent', label: 'request.percent' },
									{ name: 'description', label: 'request.description' },
									{
										name: 'clientFirstName',
										label: 'request.client_first_name'
									},
									{ name: 'clientLastName', label: 'request.client_last_name' },
									{
										name: 'driverFirstName',
										label: 'request.driver_first_name'
									},
									{ name: 'driverLastName', label: 'request.driver_last_name' },
									{ name: 'category', label: 'request.vehicle_category' },
									{ name: 'createdAt', label: 'general.created_at' }
								]}
							/>
						</>
					) : null
				].filter((i) => i != null)}
				onFilterChange={(data) => {
					var filters: IFilter[] = []
					data.driverId &&
						filters.push({
							name: 'driverId',
							operation: '=',
							value: data.driverId
						})
					data.clientId &&
						filters.push({
							name: 'clientId',
							operation: '=',
							value: data.clientId
						})
					data.status &&
						filters.push({
							name: 'status',
							operation: '=',
							value: data.status
						})
					data.durationType &&
						filters.push({
							name: 'durationType',
							operation: '=',
							value: data.durationType
						})
					data.categoryType &&
						filters.push({
							name: 'category.type',
							operation: '=',
							value: data.categoryType
						})
					setFilters(filters.length === 0 ? undefined : filters)
				}}
				filters={
					<>
						<Row style={{ padding: '0 1rem' }} gutter={16}>
							<Col span={6}>
								<Form.Item name='driverId' label={t('request.driver')}>
									<Select
										loading={driversQuery.isLoading}
										options={driversQuery.data?.data.data.map((driver) => {
											return {
												label: `#${driver.id}) ${driver.firstName} ${driver.lastName}`,
												value: driver.id
											}
										})}
										showSearch
									/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item name='clientId' label={t('request.client')}>
									<Select
										loading={clientsQuery.isLoading}
										options={clientsQuery.data?.data.data.map((client) => {
											return {
												label: `#${client.id}) ${client.firstName} ${client.lastName}`,
												value: client.id
											}
										})}
										showSearch
									/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item name='status' label={t('request.status')}>
									<Select
										options={[
											{
												label: t('request.suspended_by_driver'),
												value: RequestStatus.SUSPENDED_BY_DRIVER
											},
											{
												label: t('request.suspended_by_client'),
												value: RequestStatus.SUSPENDED_BY_CUSTOMER
											},
											{
												label: t('request.canceled_by_driver'),
												value: RequestStatus.CANCELED_BY_DRIVER
											},
											{
												label: t('request.canceled_by_client'),
												value: RequestStatus.CANCELED_BY_CUSTOMER
											},
											{
												label: t('request.approved'),
												value: RequestStatus.APPROVED
											},
											{
												label: t('request.on_the_way'),
												value: RequestStatus.ON_THE_WAY
											},
											{
												label: t('request.site_has_been_reached'),
												value: RequestStatus.SITE_HAS_BEEN_REACHED
											},
											{
												label: t('request.in_progress'),
												value: RequestStatus.IN_PROGRESS
											},
											{
												label: t('request.complete'),
												value: RequestStatus.COMPLETE
											},
											{
												label: t('request.closed'),
												value: RequestStatus.CLOSED
											}
										]}
									/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									name='durationType'
									label={t('request.duration_type')}
								>
									<Select
										options={[
											{
												label: t('request.immediate'),
												value: DurationType.QUICKLY
											},
											{
												label: t('request.scheduled'),
												value: DurationType.SCHEDULER
											}
										]}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row style={{ padding: '0 1rem' }} gutter={16}>
							<Col span={6}>
								<Form.Item
									name='categoryType'
									label={t('request.vehicle_category_type')}
								>
									<Select
										options={[
											{
												label: t('vehicle_category.transport'),
												value: VehicleCategoryType.TRANSPORT
											},
											{
												label: t('vehicle_category.time_hours'),
												value: VehicleCategoryType.TIME_HOURS
											},
											{
												label: t('vehicle_category.time_days'),
												value: VehicleCategoryType.TIME_DAYS
											}
										]}
									/>
								</Form.Item>
							</Col>
						</Row>
					</>
				}
			/>
			<Card bordered={false} bodyStyle={{ padding: 0 }}>
				<Table
					loading={query.isFetching}
					columns={columns}
					dataSource={query.data?.data.data}
					rowKey={(record) => record.id}
					style={{ overflowX: 'scroll' }}
					onChange={(_, __, sorter, ___) => {
						const { columnKey, order } = sorter as SorterResult<IRequest>

						if (!order) {
							setSort(undefined)
						} else {
							setSort({
								name: columnKey as string,
								direction: order === 'ascend' ? 'asc' : 'desc'
							})
						}
					}}
					pagination={{
						current: page,
						pageSize: perPage,
						pageSizeOptions: [5, 10, 20, 50, 100],
						showSizeChanger: true,
						total: query.data?.data.total,
						position: ['bottomCenter'],
						onChange(page, pageSize) {
							setPage(page)
							setPerPage(pageSize)
						},
						showTotal: (total, range) => {
							return `${t('general.showing')} ${range[0]} - ${range[1]} ${t(
								'general.from'
							)} ${total} ${t('general.items')}`
						}
					}}
				/>
			</Card>
		</>
	)
}

export default RequestsPage
