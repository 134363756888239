import { IMedia } from './media'
import { IRoleLight } from './role'

export enum UserStatus {
	ACTIVE = 1,
	NOT_ACTIVE = 2
}

export interface IUser {
	id: number
	firstName: string
	lastName: string
	roles: IRoleLight[]
	status: UserStatus
	mobile: string
}

export interface IUserDetails extends IUser {
	email: string
	address?: string
	note?: string
	photo?: IMedia
	createdAt?: Date
	createdBy?: IUserLight
	updatedAt?: Date
	updatedBy?: IUserLight
	permissions: string[]
}

export interface IUserLight {
	id: number
	firstName: string
	lastName: string
}

export interface IAddUser {
	firstName: string
	lastName: string
	address: string
	mobile: string
	password: string
	status: UserStatus
	email: string
	roles: number[]
	note?: string
}

export interface IEditUser {
	firstName: string
	lastName: string
	address: string
	mobile: string
	password: string
	status: UserStatus
	email: string
	roles: number[]
	note?: string
}
