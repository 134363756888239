import AppKnowledgeChart from './app-knowledge-chart'
import DriverWithRequestChart from './driver-with-request-chart'
import NewDriversAndClinetsChart from './new-drivers-chart/Index'
import RequestPaymentChart from './request-payment-chart'
import RequestStatusChart from './request-status-chart'
import { AxiosResponse } from 'axios'
import { Col, Row } from 'antd'
import { FC, useEffect, useState } from 'react'
import { UseQueryResult } from 'react-query'
import { useTranslation } from 'react-i18next'
import {
  IDriverWithRequest,
  IFirstStatistics,
  IFirstStatisticsFilter,
  ISecondStatistics,
  ISecondStatisticsFilter,
  IStatisticsKnowApp,
  IStatisticsNewClient,
  IStatisticsNewDriver,
} from '../../../models/home'
import TopRatingDriversList from '../top-lists/top-rating-drivers-list'
import MostWantedDriversList from '../top-lists/most-wanted-drivers-list'

interface IProps {
  firstQuery: UseQueryResult<AxiosResponse<IFirstStatistics, any>, unknown>
  firstQueryFilter?: IFirstStatisticsFilter
  setFirstQueryFilter?: (filter: IFirstStatisticsFilter) => void

  secondQuery: UseQueryResult<AxiosResponse<ISecondStatistics, any>, unknown>
  secondQueryFilter?: ISecondStatisticsFilter
  setSecondQueryFilter?: (filter: ISecondStatisticsFilter) => void
}

const ChartsCards: FC<IProps> = ({
  firstQuery,
  secondQuery,
  firstQueryFilter,
  setFirstQueryFilter,
  secondQueryFilter,
  setSecondQueryFilter,
}) => {
  const { t } = useTranslation()

  // Driver With Requests Chart Data
  const [driverWithRequests, setDriverWithRequests] = useState<
    IDriverWithRequest[]
  >(firstQuery.data?.data?.driverWithRequest ?? [])

  // Request Status Chart Data
  const [requestStatusData, setRequestStatusData] = useState<{
    requestClosed: number
    requestInProgress: number
  }>()

  // Request Payment Chart Data
  const [requestPaymentChart, setRequestPaymentChart] = useState<{
    totalValueOfRequest: number
    totalPayment: number
  }>()

  // App Knowledge Chart Data
  const [appKnowledgeChart, setAppKnowledgeChart] =
    useState<IStatisticsKnowApp>()

  // New Drivers Chart Data
  const [newDrivers, setNewDrivers] = useState<IStatisticsNewDriver[]>(
    secondQuery.data?.data?.newDrivers ?? []
  )

  // New Clients Chart Data
  const [newClients, setNewClients] = useState<IStatisticsNewClient[]>(
    secondQuery.data?.data?.newClients ?? []
  )

  useEffect(() => {
    if (firstQuery.data?.data) {
      // Set Driver With Requests Chart Data
      setDriverWithRequests(firstQuery.data?.data?.driverWithRequest ?? [])

      // Set Request Status Chart Data
      setRequestStatusData({
        requestClosed: firstQuery.data?.data.requestClosed,
        requestInProgress: firstQuery.data?.data.requestInProgress,
      })

      // Set Request Payment Chart Data
      setRequestPaymentChart({
        totalValueOfRequest: firstQuery.data?.data.totalValueOfRequest,
        totalPayment: firstQuery.data?.data.totalPayment,
      })
    }
  }, [firstQuery])

  useEffect(() => {
    if (secondQuery.data?.data) {
      // Set App Knowledge Chart Data
      setAppKnowledgeChart(secondQuery.data?.data.knowApp)

      // Set New Drivers Chart Data
      setNewDrivers(secondQuery.data?.data?.newDrivers ?? [])

      // Set New Clients Chart Data
      setNewClients(secondQuery.data?.data?.newClients ?? [])
    }
  }, [secondQuery])

  return (
    <Row gutter={[16, 16]} align={'stretch'}>
      <Col span={24}>
        <NewDriversAndClinetsChart
          loading={secondQuery.isFetching}
          newClients={newClients}
          newDrivers={newDrivers}
          secondQueryFilter={secondQueryFilter}
          setSecondQueryFilter={setSecondQueryFilter}
        />
      </Col>

      <Col span={12}>
        <TopRatingDriversList />
      </Col>

      <Col span={12}>
        <MostWantedDriversList />
      </Col>

      <Col span={12}>
        <DriverWithRequestChart
          data={driverWithRequests}
          loading={firstQuery.isFetching}
          firstQueryFilter={firstQueryFilter}
          setFirstQueryFilter={setFirstQueryFilter}
        />
      </Col>

      <Col span={12}>
        <RequestStatusChart
          requestClosed={requestStatusData?.requestClosed}
          requestInProgress={requestStatusData?.requestInProgress}
          loading={firstQuery.isFetching}
        />
      </Col>

      <Col span={12}>
        <RequestPaymentChart
          totalValueOfRequest={requestPaymentChart?.totalValueOfRequest}
          totalPayment={requestPaymentChart?.totalPayment}
          loading={firstQuery.isFetching}
          firstQueryFilter={firstQueryFilter}
          setFirstQueryFilter={setFirstQueryFilter}
        />
      </Col>

      <Col span={12}>
        <AppKnowledgeChart
          knowApp={appKnowledgeChart}
          loading={secondQuery.isFetching}
        />
      </Col>
    </Row>
  )
}

export default ChartsCards
