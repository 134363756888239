import GMap from '..'
import GMarker from '../marker'
import { DEFAULT_GOOGLE_MAP_PROPS } from '../../../../utils/constants'
import { FC, useState } from 'react'

interface IProps {
  value: {
    lat: number
    lng: number
  }

  onChange: (value: { lat: number; lng: number }) => void
}

const MapLocationPicker: FC<IProps> = (props) => {
  const { value, onChange } = props

  const [isMapLoaded, setIsMapLoaded] = useState(false)

  return (
    <GMap
      isMapLoaded={(isLoaded) => {
        isLoaded && setIsMapLoaded(true)
      }}
      showPlaces
      onPlacesChange={(position) => {
        onChange(position)
      }}
    >
      {isMapLoaded && (
        <>
          <GMarker
            position={value ?? DEFAULT_GOOGLE_MAP_PROPS.center}
            draggable={true}
            onDragEnd={(event) => {
              event &&
                event.latLng &&
                onChange({
                  lat: event.latLng.lat(),
                  lng: event.latLng.lng(),
                })
            }}
          />
        </>
      )}
    </GMap>
  )
}

export default MapLocationPicker
