import axios from 'axios'
import { PropsWithChildren, useEffect, useReducer } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import LoadingPage from '../../components/loading-page'
import {
	IChangePasswordRequest,
	IChangePasswordResponse,
	ILoginRequest,
	ILoginResponse,
	ILogoutRequest,
	ILogoutResponse
} from '../../models/auth'
import { IUserDetails } from '../../models/user'
import { ACCESS_TOKEN_KEY } from '../../utils/constants'
import AuthContext, { initState } from './context'
import reducer from './reducer'

const AuthContextProvider: React.FC<PropsWithChildren> = (props) => {
	const [state, dispatch] = useReducer(reducer, initState)
	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		getMe()
	}, [])

	// Get me
	const getMeQuery = useQuery({
		queryFn: async () => {
			return axios.get<IUserDetails>('auth/me')
		},
		enabled: false,
		retry: 1
	})

	// Login
	const loginMutation = useMutation({
		mutationFn: (data: ILoginRequest) => {
			return axios.post<ILoginResponse>('/auth/login', data)
		}
	})

	// Logout
	const logoutMutation = useMutation({
		mutationFn: (data: ILogoutRequest) => {
			return axios.post<ILogoutResponse>('/auth/logout', data)
		}
	})

	// Login
	const changePasswordMutation = useMutation({
		mutationFn: (data: IChangePasswordRequest) => {
			return axios.post<IChangePasswordResponse>('/auth/change-password', data)
		}
	})

	const getMe = async () => {
		dispatch({ type: 'LOADING', payload: { loading: 'get-me' } })

		const result = await getMeQuery.refetch()

		if (
			result.isError &&
			result?.error &&
			(result.error as any).response?.status === 401
		) {
			// (result.error as any)
			navigate('/auth')
		}

		if (!result.isError && result.data) {
			const userDetails = result.data.data

			// Set User Details
			dispatch({ type: 'GET_ME', payload: { user: userDetails } })
			dispatch({ type: 'SET_IS_AUTH', payload: { isAuth: true } })

			dispatch({ type: 'LOADING', payload: { loading: 'get-me' } })

			if (location.pathname === '/auth') {
				navigate('/')
			}
			return true
		}

		dispatch({ type: 'LOADING', payload: { loading: 'get-me' } })
		return false
	}

	const login = async (request: ILoginRequest) => {
		dispatch({ type: 'LOADING', payload: { loading: 'login' } })

		const res = await loginMutation
			.mutateAsync(request)
			.then((result) => {
				if (result.data) {
					const loginResponse = result.data

					// Set Token
					localStorage.setItem(ACCESS_TOKEN_KEY, loginResponse.token)
					dispatch({ type: 'SET_IS_AUTH', payload: { isAuth: true } })

					// Set User Details
					dispatch({ type: 'LOGIN', payload: { user: loginResponse.user } })

					dispatch({ type: 'LOADING', payload: { loading: 'login' } })

					navigate('/')

					return true
				}
			})
			.catch(() => {
				dispatch({ type: 'LOADING', payload: { loading: 'login' } })
				return false
			})
			.finally(() => {
				return false
			})

		return res ?? false
	}

	const logout = async (request: ILogoutRequest) => {
		dispatch({ type: 'LOADING', payload: { loading: 'logout' } })

		await logoutMutation.mutateAsync(request)

		// Clear Token
		localStorage.removeItem(ACCESS_TOKEN_KEY)

		// Clear authUser && set isAuth to false
		dispatch({ type: 'LOGOUT' })

		navigate('/auth')

		dispatch({ type: 'LOADING', payload: { loading: 'logout' } })

		return true
	}

	const changePassword = async (request: IChangePasswordRequest) => {
		dispatch({ type: 'LOADING', payload: { loading: 'change-password' } })

		const res = await changePasswordMutation
			.mutateAsync(request)
			.then((res) => {
				dispatch({ type: 'LOADING', payload: { loading: 'change-password' } })

				if (res.status === 204) {
					return true
				}
			})
			.catch(() => {
				dispatch({ type: 'LOADING', payload: { loading: 'change-password' } })
				return false
			})
			.finally(() => {
				return false
			})

		return res ?? false
	}

	const can = (permission: string) => {
		if (!state.authUser) return false

		return state.authUser.permissions.includes(permission)
	}

	return (
		<AuthContext.Provider
			value={{
				...state,
				actions: {
					login,
					getMe,
					logout,
					changePassword,
					can
				}
			}}
		>
			{state.isAuth || location.pathname === '/auth' ? (
				props.children
			) : (
				<LoadingPage />
			)}
		</AuthContext.Provider>
	)
}

export default AuthContextProvider
