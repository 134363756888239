import { IMedia } from './media'
import { IUserLight, UserStatus } from './user'

export enum HowKnowApp {
	FRIEND = 1,
	MARKETING = 2,
	SOCIAL_NETWORKING_SITES = 3
}

export interface IClientLight {
	id: number
	firstName: string
	lastName: string
}

export interface IClient {
	id: number
	sumRequest: number
	dateLastRequest?: Date
	user: {
		firstName: string
		lastName: string
		id: number
		mobile: string
		status: UserStatus
		createdAt: Date
	}
	deletedAt?: Date
}

export interface IClientDetails {
	id: number
	howKnowApp: HowKnowApp
	sumRequest: number
	note?: string
	dateLastRequest?: Date
	user: {
		firstName: string
		lastName: string
		id: number
		email: string
		mobile: string
		status: UserStatus
		photo: IMedia
		createdAt: Date
	}
	createdAt?: Date
	updatedAt?: Date
	updatedBy?: IUserLight
	deletedAt?: Date
}
