import APinIcon from '../../../../assets/images/a-pin.png'
import GMarker from '../marker'
import { FC, useMemo } from 'react'

interface IProps {
  heading?: number
  position: google.maps.LatLng | google.maps.LatLngLiteral
  isMapLoaded: boolean
  onClick?: (e: google.maps.MapMouseEvent) => void
}

const GMarkerStart: FC<IProps> = (props) => {
  const { isMapLoaded, position, onClick } = props

  const aPinIcon = useMemo(
    () =>
      isMapLoaded
        ? {
            url: APinIcon, // url
            scaledSize: new window.google.maps.Size(24, 24), // size
          }
        : undefined,
    [isMapLoaded]
  )

  return <GMarker position={position} icon={aPinIcon} onClick={onClick} />
}

export default GMarkerStart
