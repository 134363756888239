import React, { useContext, useEffect, useState } from 'react'
import { Card, Form, Input, Button } from 'antd'
import styles from './style.module.css'
import { LockOutlined, MailOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../contexts/auth-context/context'
import logo from '../../assets/images/logo-orange.png'

const AuthPage: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { actions, loading } = useContext(AuthContext)
  const [form] = Form.useForm()

  const [, forceUpdate] = useState({})

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({})
  }, [])

  const onSubmit = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => {
    const result = await actions.login({
      email,
      password,
    })
    if (result) {
      navigate('/')
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.layer} />
      <Card
        bordered={false}
        className={styles.card}
        title={
          <div className={styles.header}>
            <div className={styles.brandLogoContainer}>
              <img className={styles.brandLogo} src={logo} alt='crane-logo' />
            </div>
            <h1 className={styles.title}>{t('welcome_message')}</h1>
          </div>
        }
        headStyle={{ textAlign: 'center', padding: 0, overflow: 'hidden' }}
      >
        <h2 className={styles.subtitle}>{t('welcome_sub_message')}</h2>

        <Form
          form={form}
          name='normal_login'
          className='login-form'
          style={{ padding: '.7rem 1rem 0 1rem' }}
          initialValues={{ remember: true }}
          onFinish={(values) => onSubmit(values)}
          size='large'
          autoComplete='off'
        >
          <Form.Item
            name='email'
            rules={[
              { required: true, message: t('validation.required') },
              {
                type: 'email',
                message: t('validation.email_validation_message'),
              },
            ]}
          >
            <Input
              maxLength={30}
              prefix={
                <MailOutlined
                  className='site-form-item-icon'
                  style={{ marginInlineEnd: '.5rem' }}
                />
              }
              placeholder={t('auth.email')}
            />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[{ required: true, message: t('validation.required') }]}
            style={{ paddingTop: '1rem' }}
          >
            <Input.Password
              maxLength={30}
              prefix={
                <LockOutlined
                  className='site-form-item-icon'
                  style={{ marginInlineEnd: '.5rem' }}
                />
              }
              placeholder={t('auth.password')}
            />
          </Form.Item>

          <Form.Item style={{ paddingTop: '1rem' }}>
            <Button
              type='primary'
              htmlType='submit'
              block
              loading={loading.includes('login')}
            >
              {t('auth.login')}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}

export default AuthPage
