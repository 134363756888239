import React from 'react'
import { VehicleCategoryType } from '../../../models/vehicle-category'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'

interface IProps {
  type: VehicleCategoryType
}

const VehicleCategoryTypeTag: React.FC<IProps> = ({ type }) => {
  const { t } = useTranslation()

  let label = ''
  let color = ''

  switch (type) {
    case VehicleCategoryType.TRANSPORT:
      label = t('vehicle_category.transport')
      color = 'orange'
      break
    case VehicleCategoryType.TIME_HOURS:
      label = t('vehicle_category.time_hours')
      color = 'blue'
      break
    case VehicleCategoryType.TIME_DAYS:
      label = t('vehicle_category.time_days')
      color = 'cyan'
      break
  }

  return <Tag color={color}>{label}</Tag>
}

export default VehicleCategoryTypeTag
