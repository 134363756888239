import { Bar } from 'react-chartjs-2'
import { Card, Col, Row, Spin } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IFirstStatisticsFilter } from '../../../../models/home'
import RangePickerPopover from '../../rang-picker-popover'

interface IProps {
	totalValueOfRequest?: number
	totalPayment?: number
	loading: boolean
	firstQueryFilter?: IFirstStatisticsFilter
	setFirstQueryFilter?: (filter: IFirstStatisticsFilter) => void
}

const RequestPaymentChart: FC<IProps> = (props) => {
	const { t } = useTranslation()

	const { firstQueryFilter, setFirstQueryFilter } = props

	const data = {
		labels: [''],
		datasets: [
			{
				label: t('home.total_value_of_requests'),
				data: [props.totalValueOfRequest],
				backgroundColor: 'rgba(75, 192, 192, 0.6)'
			},
			{
				label: t('home.total_payments'),
				data: [props.totalPayment],
				backgroundColor: 'rgba(255, 99, 132, 1)'
			}
		]
	}

	return (
		<Spin spinning={props.loading}>
			<Card
				title={
					<Row gutter={[16, 16]} justify={'space-between'} align={'middle'}>
						<Col>{t('home.request_value_vs_payment_received')}</Col>

						<Col>
							<RangePickerPopover
								onChange={(fromDate, toDate) => {
									setFirstQueryFilter &&
										setFirstQueryFilter({
											...firstQueryFilter,
											'payment[start]': fromDate,
											'payment[end]': toDate,
											'valueOfRequest[start]': fromDate,
											'valueOfRequest[end]': toDate
										})
								}}
							/>
						</Col>
					</Row>
				}
				headStyle={{
					textAlign: 'center'
				}}
				bodyStyle={{
					height: '20rem'
				}}
			>
				<Bar
					data={data}
					options={{
						responsive: true,
						maintainAspectRatio: false
					}}
				/>
			</Card>
		</Spin>
	)
}

export default RequestPaymentChart
