import { useContext, useEffect } from 'react'
import AvailableDriversMap from '../available-drivers-map'
import DriversHaveRequestMap from '../drivers-have-request-map'
import styles from './style.module.css'
import { Card, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import SocketContext from '../../../contexts/socket-context/context'

const MapsCard = () => {
  const { t } = useTranslation()

  const { actions } = useContext(SocketContext)

  useEffect(() => {
    actions.getDriversHaveRequest()
  }, [])

  return (
    <Card className={styles.mapCard} bodyStyle={{ padding: '0rem 1rem' }}>
      <Tabs
        destroyInactiveTabPane
        defaultActiveKey='1'
        items={[
          {
            key: '1',
            label: t('home.drivers_have_request'),
            children: <DriversHaveRequestMap />,
          },
          {
            key: '2',
            label: t('home.available_drivers_with_no_request'),
            children: <AvailableDriversMap />,
          },
        ]}
        onChange={(key) => {}}
      />
    </Card>
  )
}

export default MapsCard
