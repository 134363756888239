import React, { useState } from 'react'
import { Table, Card, Row, Col, Form, Select, Button } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import axios from 'axios'
import {
	DATE_TIME_FORMAT,
	DEFAULT_PAGE_SIZE,
	DEFAULT_SORT_FUNCTION,
	INITIAL_PAGE,
	MAX_PAGE_SIZE
} from '../../../utils/constants'
import { IPaginatedList } from '../../../models/paginated-list'
import dayjs from 'dayjs'
import { IRewardEarning, IRewardLight } from '../../../models/reward'
import HeaderCard from '../../../components/common/header-card'
import { SorterResult } from 'antd/es/table/interface'
import { IFilter, ISort } from '../../../models/shared'
import { IDriverLight } from '../../../models/driver'
import ExportToExcelModal from '../../../components/common/export-to-excel-modal'
import { FileExcelOutlined } from '@ant-design/icons'

const RewardsEarningPage: React.FC = () => {
	const { t } = useTranslation()

	const [page, setPage] = useState(INITIAL_PAGE)
	const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE)
	const [search, setSearch] = useState<string | undefined>()
	const [filters, setFilters] = useState<IFilter[]>()
	const [sort, setSort] = useState<ISort | undefined>()
	const [exportModelVisible, setExportModalVisible] = useState(false)

	const query = useQuery({
		queryKey: ['rewards-earning', page, perPage, search, filters, sort],
		queryFn: async () => {
			return axios.get<IPaginatedList<IRewardEarning>>('/reward-driver', {
				params: {
					page,
					perPage,
					search,
					filters,
					orders: [sort]
				}
			})
		}
	})

	const rewardsQuery = useQuery({
		queryKey: ['rewards-light'],
		queryFn: async () => {
			return axios.get<IPaginatedList<IRewardLight>>('/reward', {
				params: {
					minimal: 1,
					perPage: MAX_PAGE_SIZE
				}
			})
		}
	})

	const driversQuery = useQuery({
		queryKey: ['drivers-light'],
		queryFn: async () => {
			return axios.get<IPaginatedList<IDriverLight>>('/drivers', {
				params: {
					minimal: 1,
					perPage: MAX_PAGE_SIZE
				}
			})
		}
	})

	const columns: ColumnsType<IRewardEarning> = [
		{
			title: t('general.id'),
			dataIndex: 'id',
			key: 'id',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('reward.name'),
			dataIndex: 'name',
			key: 'name',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('reward.value'),
			dataIndex: 'value',
			key: 'value',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('reward.month'),
			dataIndex: 'month'
		},
		{
			title: t('reward.year'),
			dataIndex: 'year'
		},
		{
			title: t('reward.driver'),
			render: (_, record) => {
				return `${record.firstName} ${record.lastName}`
			}
		},
		{
			title: t('reward.driver_points'),
			dataIndex: 'points',
			key: 'points',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('reward.driver_rating'),
			dataIndex: 'rating',
			key: 'rating',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('reward.reward_date'),
			dataIndex: 'rewardDate',
			render: (date: Date) => dayjs(date).format(DATE_TIME_FORMAT),
			key: 'rewardDate',
			sorter: DEFAULT_SORT_FUNCTION
		}
	]

	return (
		<>
			<HeaderCard
				title={t('reward.earning')}
				onSearch={(value) => {
					setSearch(value.length === 0 ? undefined : value)
				}}
				trailing={[
					<>
						<Button
							type='default'
							icon={<FileExcelOutlined />}
							onClick={() => setExportModalVisible(true)}
						>
							{t('general.export_to_excel')}
						</Button>
						<ExportToExcelModal
							visible={exportModelVisible}
							setVisible={setExportModalVisible}
							exportUrl={'/reward/driver/export'}
							columns={[
								{ name: 'id', label: 'general.id' },
								{ name: 'name', label: 'reward.name' },
								{ name: 'value', label: 'reward.value' },
								{ name: 'firstName', label: 'reward.driver_first_name' },
								{ name: 'lastName', label: 'reward.driver_last_name' },
								{ name: 'driverPoints', label: 'reward.driver_points' },
								{ name: 'driverRate', label: 'reward.driver_rating' },
								{ name: 'month', label: 'reward.month' },
								{ name: 'year', label: 'reward.year' },
								{ name: 'rewardDate', label: 'reward.reward_date' }
							]}
						/>
					</>
				]}
				onFilterChange={(data) => {
					var filters: IFilter[] = []
					data.driverId &&
						filters.push({
							name: 'driverId',
							operation: '=',
							value: data.driverId
						})
					data.couponId &&
						filters.push({
							name: 'couponId',
							operation: '=',
							value: data.couponId
						})
					setFilters(filters.length === 0 ? undefined : filters)
				}}
				filters={
					<Row style={{ padding: '0 1rem' }} gutter={16}>
						<Col span={6}>
							<Form.Item name='driverId' label={t('reward.driver')}>
								<Select
									loading={driversQuery.isLoading}
									options={driversQuery.data?.data.data.map((driver) => {
										return {
											label: `#${driver.id}) ${driver.firstName} ${driver.lastName}`,
											value: driver.id
										}
									})}
									showSearch
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name='rewardId' label={t('reward.label')}>
								<Select
									loading={rewardsQuery.isLoading}
									options={rewardsQuery.data?.data.data.map((reward) => {
										return {
											label: `#${reward.id}) ${reward.name}`,
											value: reward.id
										}
									})}
									showSearch
								/>
							</Form.Item>
						</Col>
					</Row>
				}
			/>
			<Card bordered={false} bodyStyle={{ padding: 0 }}>
				<Table
					loading={query.isFetching}
					columns={columns}
					dataSource={query.data?.data.data}
					rowKey={(record) => record.id}
					style={{ overflowX: 'scroll' }}
					onChange={(_, __, sorter, ___) => {
						const { columnKey, order } = sorter as SorterResult<IRewardEarning>

						if (!order) {
							setSort(undefined)
						} else {
							setSort({
								name: columnKey as string,
								direction: order === 'ascend' ? 'asc' : 'desc'
							})
						}
					}}
					pagination={{
						current: page,
						pageSize: perPage,
						pageSizeOptions: [5, 10, 20, 50, 100],
						showSizeChanger: true,
						total: query.data?.data.total,
						position: ['bottomCenter'],
						onChange(page, pageSize) {
							setPage(page)
							setPerPage(pageSize)
						},
						showTotal: (total, range) => {
							return `${t('general.showing')} ${range[0]} - ${range[1]} ${t(
								'general.from'
							)} ${total} ${t('general.items')}`
						}
					}}
				/>
			</Card>
		</>
	)
}

export default RewardsEarningPage
