import RangePickerPopover from '../rang-picker-popover'
import styles from './style.module.css'
import { AxiosResponse } from 'axios'
import { FC, useEffect, useState } from 'react'
import { UseQueryResult } from 'react-query'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Popover,
  Row,
  Spin,
  Statistic,
} from 'antd'
import {
  IFirstStatistics,
  IFirstStatisticsFilter,
  ISecondStatistics,
  ISecondStatisticsFilter,
} from '../../../models/home'
import {
  CarOutlined,
  CheckCircleTwoTone,
  TeamOutlined,
  BorderlessTableOutlined,
} from '@ant-design/icons'

interface IProps {
  firstQuery: UseQueryResult<AxiosResponse<IFirstStatistics, any>, unknown>
  firstQueryFilter?: IFirstStatisticsFilter
  setFirstQueryFilter?: (filter: IFirstStatisticsFilter) => void

  secondQuery: UseQueryResult<AxiosResponse<ISecondStatistics, any>, unknown>
  secondQueryFilter?: ISecondStatisticsFilter
  setSecondQueryFilter?: (filter: ISecondStatisticsFilter) => void
}

const StatisticsCards: FC<IProps> = ({
  firstQuery,
  secondQuery,
  firstQueryFilter,
  setFirstQueryFilter,
  secondQueryFilter,
  setSecondQueryFilter,
}) => {
  const { t } = useTranslation()

  const [firstStatistics, setFirstStatistics] = useState<
    IFirstStatistics | undefined
  >(firstQuery.data?.data)

  useEffect(() => {
    firstQuery.data?.data && setFirstStatistics(firstQuery.data?.data)
  }, [firstQuery])

  const [secondStatistics, setSecondStatistics] = useState<
    ISecondStatistics | undefined
  >(secondQuery.data?.data)

  useEffect(() => {
    secondQuery.data?.data && setSecondStatistics(secondQuery.data?.data)
  }, [secondQuery])

  return (
    <Row gutter={[16, 16]} justify={'center'} align={'middle'}>
      <Col className={styles.col} span={6}>
        <Card
          title={
            <Row gutter={[16, 16]} justify={'space-between'}>
              <Col>{t('home.clients')}</Col>

              <Col>
                <RangePickerPopover
                  onChange={(fromClientDate, toClientDate) => {
                    setSecondQueryFilter &&
                      setSecondQueryFilter({
                        ...secondQueryFilter,
                        'client[start]': fromClientDate,
                        'client[end]': toClientDate,
                      })
                  }}
                />
              </Col>
            </Row>
          }
          bordered={false}
          style={{ width: '100%' }}
        >
          <Spin spinning={secondQuery.isLoading} tip={t('general.loading')}>
            <Statistic
              value={secondStatistics?.clients}
              valueStyle={{ color: '#f55a00' }}
              prefix={<TeamOutlined style={{ marginInlineEnd: '.5rem' }} />}
            />
          </Spin>
        </Card>
      </Col>
      <Col className={styles.col} span={6}>
        <Card
          title={
            <Row gutter={[16, 16]} justify={'space-between'}>
              <Col>{t('home.drivers')}</Col>

              <Col>
                <RangePickerPopover
                  onChange={(fromDriverDate, toDriverDate) => {
                    setSecondQueryFilter &&
                      setSecondQueryFilter({
                        ...secondQueryFilter,
                        'driver[start]': fromDriverDate,
                        'driver[end]': toDriverDate,
                      })
                  }}
                />
              </Col>
            </Row>
          }
          bordered={false}
          style={{ width: '100%' }}
        >
          <Spin spinning={secondQuery.isLoading} tip={t('general.loading')}>
            <Statistic
              value={secondStatistics?.drivers}
              valueStyle={{ color: '#f55a00' }}
              prefix={<CarOutlined style={{ marginInlineEnd: '.5rem' }} />}
            />
          </Spin>
        </Card>
      </Col>
      <Col className={styles.col} span={6}>
        <Card
          title={t('home.available_drivers')}
          bordered={false}
          style={{ width: '100%' }}
        >
          <Spin spinning={secondQuery.isLoading} tip={t('general.loading')}>
            <Statistic
              value={secondStatistics?.driverAvailable}
              valueStyle={{ color: '#52c41a' }}
              prefix={
                <CheckCircleTwoTone
                  style={{ marginInlineEnd: '.5rem' }}
                  twoToneColor='#52c41a'
                />
              }
            />
          </Spin>
        </Card>
      </Col>

      <Col className={styles.col} span={6}>
        <Card
          title={t('home.closed_requests')}
          bordered={false}
          style={{ width: '100%' }}
        >
          <Spin spinning={firstQuery.isLoading} tip={t('general.loading')}>
            <Statistic
              value={firstStatistics?.requestClosed}
              valueStyle={{ color: '#f55a00' }}
              prefix={
                <BorderlessTableOutlined style={{ marginInlineEnd: '.5rem' }} />
              }
            />
          </Spin>
        </Card>
      </Col>
    </Row>
  )
}

export default StatisticsCards
