import { Checkbox, Modal } from 'antd'
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import FileDownload from 'js-file-download'

interface IProps {
	visible: boolean
	setVisible: (visible: boolean) => void
	exportUrl: string
	columns: { label?: string; name: string }[]
}

const ExportToExcelModal: React.FC<IProps> = (props) => {
	const { t } = useTranslation()

	const [columns, setColumns] = useState<any>({})

	useEffect(() => {
		const cols: any = {}

		props.columns.forEach((col) => {
			cols[col.name] = true
		})

		setColumns(cols)
	}, [props.columns])

	const mutation = useMutation({
		mutationFn: () => {
			return axios.post(props.exportUrl, columns, { responseType: 'blob' })
		},
		onSuccess: (response) => {
			FileDownload(response.data, 'report.xlsx')
			props.setVisible(false)
		}
	})

	return (
		<Modal
			title={t('general.export_to_excel')}
			open={props.visible}
			onOk={() => mutation.mutate()}
			confirmLoading={mutation.isLoading}
			onCancel={() => props.setVisible(false)}
		>
			{props.columns.map((column) => {
				return (
					<div>
						<Checkbox
							checked={columns[column.name]}
							onChange={(e) =>
								setColumns({ ...columns, [column.name]: e.target.checked })
							}
						>
							{column.label ? t(column.label) : column.name}
						</Checkbox>
					</div>
				)
			})}
		</Modal>
	)
}

export default ExportToExcelModal
