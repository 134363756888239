import GMarker from '../marker'
import track1Icon from '../../../../assets/images/track-1.png'
import { FC, useMemo } from 'react'

interface IProps {
  heading: number
  position: google.maps.LatLng | google.maps.LatLngLiteral
  isMapLoaded: boolean
  onClick?: (e: google.maps.MapMouseEvent) => void
}

const GMarkerTrack1: FC<IProps> = (props) => {
  const { isMapLoaded, position, heading, onClick } = props

  // const track1 = useMemo(() => {
  //   if (!isMapLoaded) return undefined

  //   // Dimensions of the rectangle
  //   const w = 244
  //   const h = 88
  //   const canvasSize = 260 // Increased canvas size
  //   const halfSize = canvasSize / 2
  //   const svg = `
  //     <svg width="${canvasSize}" height="${canvasSize}" xmlns="http://www.w3.org/2000/svg">
  //       <image href="${track1Icon}" x="${halfSize - w / 2}" y="${
  //     halfSize - h / 2
  //   }" transform="rotate(${heading}, ${halfSize}, ${halfSize})" width="${w}" height="${h}"/>
  //     </svg>
  //   `

  //   return `data:image/svg+xml,${encodeURIComponent(svg)}`
  // }, [isMapLoaded, heading])

  const track1 = useMemo(
    () =>
      isMapLoaded
        ? {
            url: track1Icon, // url
            scaledSize: new window.google.maps.Size(30, 88), // size
          }
        : undefined,
    [isMapLoaded]
  )

  return <GMarker position={position} icon={track1} onClick={onClick} />
}

export default GMarkerTrack1
