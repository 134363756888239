import { createContext } from 'react'
import {
	IChangePasswordRequest,
	ILoginRequest,
	ILogoutRequest
} from '../../models/auth'
import { IUserDetails } from '../../models/user'
import { DEFAULT_ASYNC_FUNCTION, DEFAULT_FUNCTION } from '../../utils/constants'

export type AuthLoading = 'login' | 'get-me' | 'logout' | 'change-password'

export interface IState {
	loading: AuthLoading[]
	isAuth?: Boolean
	authUser?: IUserDetails
}

export const initState: IState = {
	loading: []
}

interface IActions {
	login: (request: ILoginRequest) => Promise<boolean>
	getMe: () => Promise<boolean>
	changePassword: (request: IChangePasswordRequest) => Promise<boolean>
	logout: (request: ILogoutRequest) => Promise<boolean>
	can: (permission: string) => boolean
}

const initActions: IActions = {
	login: DEFAULT_ASYNC_FUNCTION,
	getMe: DEFAULT_ASYNC_FUNCTION,
	changePassword: DEFAULT_ASYNC_FUNCTION,
	logout: DEFAULT_ASYNC_FUNCTION,
	can: () => false
}

const AuthContext = createContext({ ...initState, actions: { ...initActions } })

export default AuthContext
