import axios from 'axios'
import { Card, Col, List, Row, Spin } from 'antd'
import { DEFAULT_PAGE_SIZE } from '../../../../utils/constants'
import { IDriver } from '../../../../models/driver'
import { IPaginatedList } from '../../../../models/paginated-list'
import { Link } from 'react-router-dom'
import { StarOutlined } from '@ant-design/icons'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

const TopRatingDriversList = () => {
	const { t } = useTranslation()

	const query = useQuery({
		queryKey: ['top-rating-drivers'],
		queryFn: async () => {
			return axios.get<IPaginatedList<IDriver>>('/drivers', {
				params: {
					perPage: DEFAULT_PAGE_SIZE,

					orders: [
						{
							name: 'rating',
							direction: 'asc'
						}
					]
				}
			})
		}
	})

	return (
		<Spin
			spinning={false}
			// spinning={query.isFetching}
		>
			<Card
				title={
					<Row gutter={[16, 16]} justify={'space-between'} align={'middle'}>
						<Col>{t('home.top_rating_drivers')}</Col>
					</Row>
				}
				headStyle={{
					textAlign: 'center'
				}}
				bodyStyle={{
					height: '28rem',
					width: '100%',
					overflow: 'auto',
					padding: '0 24px'
				}}
			>
				<List
					bordered={false}
					dataSource={query.data?.data.data}
					renderItem={(item, index) => (
						<List.Item key={index}>
							<Row
								justify={'space-between'}
								gutter={[16, 16]}
								style={{
									width: '100%'
								}}
							>
								<Col span={20}>
									<List.Item.Meta
										// avatar={<Avatar src={item.user.imageUrl} />}
										title={
											<Link
												to={`/driver/${item.id}`}
											>{`${item.user?.firstName} ${item.user?.lastName}`}</Link>
										}
										description={
											<Link to={`/driver/${item.id}`}>{`${t('general.id')}: #${
												item.id
											}`}</Link>
										}
									/>
								</Col>

								{item?.rating && (
									<Col span={4}>
										<div
											style={{
												display: 'flex',
												alignItems: 'baseline',
												gap: '1rem'
											}}
										>
											<div>{`${t('general.rate')}: `}</div>
											<div>{item.sumRequest}</div>
											<StarOutlined />
										</div>
									</Col>
								)}
							</Row>
						</List.Item>
					)}
				></List>
			</Card>
		</Spin>
	)
}

export default TopRatingDriversList
