import { Button, Card, Form, Input } from 'antd'
import React from 'react'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { CheckOutlined } from '@ant-design/icons'
import { IAddVehicleCapacity } from '../../../models/vehicle-capacity'
import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const AddVehicleCapacityPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (data: IAddVehicleCapacity) => {
      return axios.post('/capacity', data)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['vehicle-capacities'] })
      queryClient.invalidateQueries({ queryKey: ['vehicle-capacities-light'] })
      navigate(-1)
    },
  })

  const onSubmit = (data: any) => {
    mutation.mutate({
      name: {
        ku: data['name.ku'],
        ar: data['name.ar'],
        en: data['name.en'],
      },
      note: data['note'],
    })
  }

  return (
    <Form name='form_item_path' layout='vertical' onFinish={onSubmit}>
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('vehicle_capacity.add')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Button
              type='default'
              disabled={mutation.isLoading}
              onClick={() => navigate(-1)}
            >
              {t('general.cancel')}
            </Button>
            <Button
              className={styles.saveButton}
              type='primary'
              htmlType='submit'
              icon={<CheckOutlined />}
              loading={mutation.isLoading}
            >
              {t('general.save')}
            </Button>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: '1rem' }}>
        <Form.Item
          name='name.ku'
          label={`${t('vehicle_capacity.name')} (${t('language.kurdish')})`}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='name.ar'
          label={`${t('vehicle_capacity.name')} (${t('language.arabic')})`}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='name.en'
          label={`${t('vehicle_capacity.name')} (${t('language.english')})`}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name='note' label={t('vehicle_capacity.note')}>
          <Input.TextArea />
        </Form.Item>
      </Card>
    </Form>
  )
}

export default AddVehicleCapacityPage
