import React from 'react'
import { Card, Button, Descriptions, Spin, Space, Tag } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import axios from 'axios'
import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '../../../utils/constants'
import { IUserDetails } from '../../../models/user'
import UserStatusTag from '../../../components/enums/user-status-tag'

const UserDetailsPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { id } = useParams()

  const query = useQuery({
    queryKey: ['user', id],
    queryFn: async () => {
      return axios.get<IUserDetails>(`/users/${id}`)
    },
  })

  const data = query.data?.data

  return (
    <div>
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('user.details')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Button type='default' onClick={() => navigate(-1)}>
              {t('general.cancel')}
            </Button>
            <Button
              className={styles.saveButton}
              type='primary'
              icon={<EditOutlined />}
              onClick={() => navigate(`/user/${id}/edit`)}
            >
              {t('general.edit')}
            </Button>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: 0 }}>
        <Spin tip={t('general.loading')} spinning={query.isFetching}>
          <Descriptions bordered column={1}>
            <Descriptions.Item label={t('general.id')}>
              {data?.id}
            </Descriptions.Item>
            <Descriptions.Item label={t('user.first_name')}>
              {data?.firstName}
            </Descriptions.Item>
            <Descriptions.Item label={t('user.last_name')}>
              {data?.lastName}
            </Descriptions.Item>
            <Descriptions.Item label={t('user.roles')}>
              <Space>
                {data?.roles?.map((r) => (
                  <Tag key={r.id}>{r.name}</Tag>
                ))}
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label={t('user.status')}>
              {data?.status && <UserStatusTag status={data.status} />}
            </Descriptions.Item>
            <Descriptions.Item label={t('user.mobile')}>
              {data?.mobile}
            </Descriptions.Item>
            <Descriptions.Item label={t('user.email')} span={1}>
              {data?.email}
            </Descriptions.Item>
            <Descriptions.Item label={t('user.address')} span={2}>
              {data?.address}
            </Descriptions.Item>
            <Descriptions.Item label={t('user.note')} span={3}>
              {data?.note}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.created_at')} span={1}>
              {dayjs(data?.createdAt).format(DATE_TIME_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.created_by')} span={2}>
              {data?.createdBy &&
                `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.updated_at')} span={1}>
              {dayjs(data?.updatedAt).format(DATE_TIME_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.updated_by')} span={2}>
              {data?.updatedBy &&
                `${data?.updatedBy?.firstName} ${data?.updatedBy?.lastName}`}
            </Descriptions.Item>
          </Descriptions>
        </Spin>
      </Card>
    </div>
  )
}

export default UserDetailsPage
