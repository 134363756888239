import React from 'react'
import { Card, Button, Descriptions, Spin } from 'antd'
import { EditOutlined, HistoryOutlined } from '@ant-design/icons'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import axios from 'axios'
import { ICouponDetails } from '../../../models/coupon'
import CouponStatusTag from '../../../components/enums/coupon-status-tag'
import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '../../../utils/constants'

const CouponDetailsPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { id } = useParams()

  const query = useQuery({
    queryKey: ['coupon', id],
    queryFn: async () => {
      return axios.get<ICouponDetails>(`/coupon/${id}`)
    },
  })

  var data = query.data?.data

  return (
    <div>
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('coupon.details')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Button type='default' onClick={() => navigate(-1)}>
              {t('general.cancel')}
            </Button>
            <Button
              className={styles.saveButton}
              type='primary'
              icon={<EditOutlined />}
              onClick={() => navigate(`/coupon/${id}/edit`)}
            >
              {t('general.edit')}
            </Button>
            <Button
              className={styles.saveButton}
              type='dashed'
              icon={<HistoryOutlined />}
              onClick={() => navigate(`/coupon/${id}/history`)}
            >
              {t('general.history')}
            </Button>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: 0 }}>
        <Spin tip={t('general.loading')} spinning={query.isFetching}>
          <Descriptions bordered column={1}>
            <Descriptions.Item label={t('general.id')}>
              {data?.id}
            </Descriptions.Item>
            <Descriptions.Item label={t('coupon.name')}>
              {data?.name}
            </Descriptions.Item>
            <Descriptions.Item label={t('coupon.code')}>
              {data?.code}
            </Descriptions.Item>
            <Descriptions.Item label={t('coupon.value')}>
              {data?.value}
            </Descriptions.Item>
            <Descriptions.Item label={t('coupon.status')}>
              {data?.status && <CouponStatusTag status={data?.status} />}
            </Descriptions.Item>
            <Descriptions.Item label={t('coupon.is_limit')}>
              {data?.isLimit ? t('general.yes') : t('general.no')}
            </Descriptions.Item>
            <Descriptions.Item label={t('coupon.max_of_use')}>
              {data?.maxOfUse}
            </Descriptions.Item>
            <Descriptions.Item label={t('coupon.num_of_use')}>
              {data?.numOfUse}
            </Descriptions.Item>
            <Descriptions.Item label={t('coupon.start_date')}>
              {data?.startDate?.toString()}
            </Descriptions.Item>
            <Descriptions.Item label={t('coupon.end_date')}>
              {data?.endDate?.toString()}
            </Descriptions.Item>
            <Descriptions.Item label={t('coupon.note')} span={3}>
              {data?.note}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.created_at')} span={1}>
              {dayjs(data?.createdAt).format(DATE_TIME_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.created_by')} span={2}>
              {data?.createdBy &&
                `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.updated_at')} span={1}>
              {dayjs(data?.updatedAt).format(DATE_TIME_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.updated_by')} span={2}>
              {data?.updatedBy &&
                `${data?.updatedBy?.firstName} ${data?.updatedBy?.lastName}`}
            </Descriptions.Item>
          </Descriptions>
        </Spin>
      </Card>
    </div>
  )
}

export default CouponDetailsPage
