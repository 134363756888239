import { Button, Card, Form, FormInstance, Input, Spin } from 'antd'
import React from 'react'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { CheckOutlined } from '@ant-design/icons'
import {
  IEditVehicleCapacity,
  IVehicleCapacityDetails,
} from '../../../models/vehicle-capacity'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

const EditVehicleCapacityPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { id } = useParams()

  const formRef = React.useRef<FormInstance>(null)

  const queryClient = useQueryClient()

  const query = useQuery({
    queryKey: ['vehicle-capacity', id],
    queryFn: async () => {
      return axios.get<IVehicleCapacityDetails>(`/capacity/${id}`)
    },
    onSuccess: (data) => {
      formRef.current?.setFieldsValue({
        'name.ku': data.data.translations.name.filter((n) => n.lang === 'ku')[0]
          .value,
        'name.ar': data.data.translations.name.filter((n) => n.lang === 'ar')[0]
          .value,
        'name.en': data.data.translations.name.filter((n) => n.lang === 'en')[0]
          .value,
        note: data.data.note,
      })
    },
    refetchOnWindowFocus: false,
  })

  const mutation = useMutation({
    mutationFn: (data: IEditVehicleCapacity) => {
      return axios.put(`/capacity/${id}`, data)
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['vehicle-capacities'] })
      queryClient.invalidateQueries({ queryKey: ['vehicle-capacities-light'] })
      queryClient.invalidateQueries({ queryKey: ['vehicle-capacity', id] })
      navigate(-1)
    },
  })

  const onSubmit = (data: any) => {
    mutation.mutate({
      name: {
        ku: data['name.ku'],
        ar: data['name.ar'],
        en: data['name.en'],
      },
      note: data['note'],
    })
  }

  return (
    <Form
      name='form_item_path'
      layout='vertical'
      ref={formRef}
      onFinish={onSubmit}
    >
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('vehicle_capacity.edit')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Button
              type='default'
              disabled={mutation.isLoading || query.isFetching}
              onClick={() => navigate(-1)}
            >
              {t('general.cancel')}
            </Button>
            <Button
              className={styles.saveButton}
              type='primary'
              htmlType='submit'
              icon={<CheckOutlined />}
              loading={mutation.isLoading}
              disabled={query.isFetching}
            >
              {t('general.save')}
            </Button>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: '1rem' }}>
        <Spin tip={t('general.loading')} spinning={query.isFetching}>
          <Form.Item
            name='name.ku'
            label={`${t('vehicle_capacity.name')} (${t('language.kurdish')})`}
            required
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='name.ar'
            label={`${t('vehicle_capacity.name')} (${t('language.arabic')})`}
            required
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='name.en'
            label={`${t('vehicle_capacity.name')} (${t('language.english')})`}
            required
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name='note' label={t('vehicle_capacity.note')}>
            <Input.TextArea />
          </Form.Item>
        </Spin>
      </Card>
    </Form>
  )
}

export default EditVehicleCapacityPage
