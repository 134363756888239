import {
	IRequestLocalizedProperty,
	LocalizedProperty
} from './localized-property'
import { IMedia } from './media'
import { IUserLight } from './user'
import { IVehicleCapacityLight } from './vehicle-capacity'

export enum VehicleCategoryType {
	TRANSPORT = 1,
	TIME_HOURS = 2,
	TIME_DAYS = 3
}

export interface IVehicleCategoryLight {
	id: number
	name: string
	type: VehicleCategoryType
}

export interface IVehicleCategory {
	id: number
	name: string
	type: VehicleCategoryType
	points: number
	capacity: IVehicleCapacityLight
}

export interface IVehicleCategoryDetails extends IVehicleCategory {
	capacity: IVehicleCapacityLight
	photo: IMedia
	createdAt?: Date
	createdBy?: IUserLight
	updatedAt?: Date
	updatedBy?: IUserLight
	note?: string
	translations: {
		name: LocalizedProperty
	}
}

export interface IAddVehicleCategory {
	name: IRequestLocalizedProperty
	note?: string
	type: VehicleCategoryType
	points: number
	capacityId: number
	photoId: number
}

export interface IEditVehicleCategory {
	name: IRequestLocalizedProperty
	note?: string
	type: VehicleCategoryType
	points: number
	capacityId: number
	photoId: number
}
