import React, { useContext, useState } from 'react'
import {
	Space,
	Table,
	Card,
	Button,
	Popconfirm,
	Row,
	Col,
	Form,
	Select
} from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import {
	EyeOutlined,
	EditOutlined,
	FileExcelOutlined,
	DeleteOutlined
} from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { IPaginatedList } from '../../../models/paginated-list'
import {
	DATE_TIME_FORMAT,
	DEFAULT_PAGE_SIZE,
	DEFAULT_SORT_FUNCTION,
	INITIAL_PAGE
} from '../../../utils/constants'
import axios from 'axios'
import { IClient } from '../../../models/client'
import dayjs from 'dayjs'
import UserStatusTag from '../../../components/enums/user-status-tag'
import HeaderCard from '../../../components/common/header-card'
import { IFilter, ISort } from '../../../models/shared'
import { SorterResult } from 'antd/es/table/interface'
import { UserStatus } from '../../../models/user'
import ExportToExcelModal from '../../../components/common/export-to-excel-modal'
import AuthContext from '../../../contexts/auth-context/context'
import {
	CAN_DELETE_CLIENT,
	CAN_EXPORT_CLIENTS,
	CAN_UPDATE_CLIENT,
	CAN_VIEW_CLIENTS_WITH_TRASH
} from '../../../utils/rbac/permissions'

const ClientsTrashPage: React.FC = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const {
		actions: { can }
	} = useContext(AuthContext)

	const [page, setPage] = useState(INITIAL_PAGE)
	const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE)
	const [search, setSearch] = useState<string | undefined>()
	const [sort, setSort] = useState<ISort | undefined>()
	const [exportModelVisible, setExportModalVisible] = useState(false)

	const queryClient = useQueryClient()

	const query = useQuery({
		queryKey: ['clients-trash', page, perPage, search, sort],
		queryFn: async () => {
			return axios.get<IPaginatedList<IClient>>('/clients', {
				params: {
					page,
					perPage,
					search,
					filters: [
						{
							name: 'deletedAt',
							operation: '!=',
							value: 'null'
						}
					],
					orders: [sort]
				}
			})
		}
	})

	const columns: ColumnsType<IClient> = [
		{
			title: t('general.id'),
			dataIndex: 'id',
			key: 'id',
			render: (id) => <Link to={`/client/${id}`}>{id}</Link>,
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('client.first_name'),
			dataIndex: 'user',
			key: 'user.firstName',
			render: (user) => user?.firstName,
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('client.last_name'),
			dataIndex: 'user',
			key: 'user.lastName',
			render: (user) => user?.lastName,
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('client.mobile'),
			dataIndex: 'user',
			key: 'user.mobile',
			render: (user) => user?.mobile
		},
		{
			title: t('client.status'),
			dataIndex: 'user',
			key: 'user.status',
			render: (user) => <UserStatusTag status={user?.status} />
		},
		{
			title: t('general.created_at'),
			dataIndex: 'user',
			key: 'user.createdAt',
			render: (user) => dayjs(user?.createdAt).format(DATE_TIME_FORMAT),
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('general.last_usage_date'),
			dataIndex: 'dateLastRequest',
			key: 'dateLastRequest',
			render: (dateLastRequest) =>
				dateLastRequest && dayjs(dateLastRequest).format(DATE_TIME_FORMAT),
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('client.sum_request'),
			dataIndex: 'sumRequest',
			key: 'sumRequest',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('general.deleted_at'),
			dataIndex: 'deletedAt',
			key: 'deletedAt',
			render: (deletedAt) => dayjs(deletedAt).format(DATE_TIME_FORMAT),
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('general.actions'),
			key: 'actions',
			render: (_, record) => (
				<Space>
					<Button
						type='link'
						icon={<EyeOutlined />}
						onClick={() => navigate(`/client/${record.id}`)}
					>
						{t('general.show')}
					</Button>
				</Space>
			)
		}
	]

	return (
		<>
			<HeaderCard
				title={t('sider.deleted_clients')}
				onSearch={(value) => {
					setSearch(value.length === 0 ? undefined : value)
				}}
				trailing={[
					can(CAN_EXPORT_CLIENTS) ? (
						<>
							<Button
								type='default'
								icon={<FileExcelOutlined />}
								onClick={() => setExportModalVisible(true)}
							>
								{t('general.export_to_excel')}
							</Button>
							<ExportToExcelModal
								visible={exportModelVisible}
								setVisible={setExportModalVisible}
								exportUrl={'/clients/export'}
								columns={[
									{ name: 'id', label: 'general.id' },
									{
										name: 'firstName',
										label: 'client.first_name'
									},
									{
										name: 'lastName',
										label: 'client.last_name'
									},
									{
										name: 'email',
										label: 'client.email'
									},
									{
										name: 'mobile',
										label: 'client.mobile'
									},
									{
										name: 'address',
										label: 'client.address'
									},
									{
										name: 'status',
										label: 'client.status'
									},
									{
										name: 'howKnowApp',
										label: 'client.how_know_app'
									},
									{
										name: 'sumRequest',
										label: 'client.sum_request'
									}
								]}
							/>
						</>
					) : null
				].filter((i) => i != null)}
			/>
			<Card bordered={false} bodyStyle={{ padding: 0 }}>
				<Table
					loading={query.isFetching}
					columns={columns}
					dataSource={query.data?.data.data}
					rowKey={(record) => record.id}
					style={{ overflowX: 'scroll' }}
					onChange={(_, __, sorter, ___) => {
						const { columnKey, order } = sorter as SorterResult<IClient>

						if (!order) {
							setSort(undefined)
						} else {
							setSort({
								name: columnKey as string,
								direction: order === 'ascend' ? 'asc' : 'desc'
							})
						}
					}}
					pagination={{
						current: page,
						pageSize: perPage,
						pageSizeOptions: [5, 10, 20, 50, 100],
						showSizeChanger: true,
						total: query.data?.data.total,
						position: ['bottomCenter'],
						onChange(page, pageSize) {
							setPage(page)
							setPerPage(pageSize)
						},
						showTotal: (total, range) => {
							return `${t('general.showing')} ${range[0]} - ${range[1]} ${t(
								'general.from'
							)} ${total} ${t('general.items')}`
						}
					}}
				/>
			</Card>
		</>
	)
}

export default ClientsTrashPage
