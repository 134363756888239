import MapsCard from '../../components/tracking/maps-card'
import SocketContextProvider from '../../contexts/socket-context/provider'

const Tracking = () => {
  return (
    <>
      <MapsCard />
    </>
  )
}

const TrackingPage = () => {
  return (
    <SocketContextProvider>
      <Tracking />
    </SocketContextProvider>
  )
}
export default TrackingPage
