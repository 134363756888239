import dayjs from 'dayjs'
import { Button, DatePicker, Popover, TimeRangePickerProps } from 'antd'
import { FC, useState } from 'react'
import { FilterOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import type { Dayjs } from 'dayjs'

const { RangePicker } = DatePicker

interface IProps {
  onChange: (fromDate?: string, toDate?: string) => void
}

const RangePickerPopover: FC<IProps> = (props) => {
  const { onChange } = props

  const { t } = useTranslation()

  const [openFilter, setOpenFilter] = useState(false)

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      // console.log('From: ', dates[0], ', to: ', dates[1])
      const from = dateStrings[0]
      const to = dateStrings[1]

      onChange(from, to)
      setOpenFilter(false)
    } else {
      onChange(undefined, undefined)

      setOpenFilter(false)
    }
  }

  const rangePresets: TimeRangePickerProps['presets'] = [
    { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
    { label: 'Last Year', value: [dayjs().add(-1, 'y'), dayjs()] },
  ]

  return (
    <Popover
      content={
        <>
          <RangePicker
            // size='small'
            presets={rangePresets}
            onChange={onRangeChange}
          />
        </>
      }
      title={t('general.filter')}
      open={openFilter}
      placement='bottom'
    >
      <Button
        type='text'
        onClick={() => {
          setOpenFilter(!openFilter)
        }}
      >
        <FilterOutlined />
      </Button>
    </Popover>
  )
}

export default RangePickerPopover
