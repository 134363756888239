import React, { useState } from 'react'
import { Table, Card } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { IPaginatedList } from '../../models/paginated-list'
import { DEFAULT_PAGE_SIZE, INITIAL_PAGE } from '../../utils/constants'
import axios from 'axios'
import { IPermission } from '../../models/permission'
import HeaderCard from '../../components/common/header-card'

const PermissionsPage: React.FC = () => {
	const { t } = useTranslation()

	const [page, setPage] = useState(INITIAL_PAGE)
	const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE)
	const [search, setSearch] = useState<string | undefined>()

	const query = useQuery({
		queryKey: ['permissions', page, perPage, search],
		queryFn: async () => {
			return axios.get<IPaginatedList<IPermission>>('/permissions', {
				params: {
					page,
					perPage,
					search
				}
			})
		}
	})

	const columns: ColumnsType<IPermission> = [
		{
			title: t('general.id'),
			dataIndex: 'id'
		},
		{
			title: t('permission.name'),
			dataIndex: 'name'
		},
		{
			title: t('permission.note'),
			dataIndex: 'note'
		}
	]

	return (
		<>
			<HeaderCard
				title={t('permission.label')}
				onSearch={(value) => {
					setSearch(value.length === 0 ? undefined : value)
				}}
			/>
			<Card bordered={false} bodyStyle={{ padding: 0 }}>
				<Table
					loading={query.isFetching}
					columns={columns}
					dataSource={query.data?.data.data}
					rowKey={(record) => record.id}
					style={{ overflowX: 'scroll' }}
					pagination={{
						current: page,
						pageSize: perPage,
						pageSizeOptions: [5, 10, 20, 50, 100],
						showSizeChanger: true,
						total: query.data?.data.total,
						position: ['bottomCenter'],
						onChange(page, pageSize) {
							setPage(page)
							setPerPage(pageSize)
						},
						showTotal: (total, range) => {
							return `${t('general.showing')} ${range[0]} - ${range[1]} ${t(
								'general.from'
							)} ${total} ${t('general.items')}`
						}
					}}
				/>
			</Card>
		</>
	)
}

export default PermissionsPage
