import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ar from './locales/ar.json'
import en from './locales/en.json'
import ku from './locales/ku.json'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      ku,
      en,
      ar,
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage'],
    },
  })

i18n.dir = function (lng) {
  if (!lng) lng = i18n.language // If no language provided, use the current language.
  switch (lng) {
    case 'ku': // Add more RTL languages if needed.
    case 'ar':
      return 'rtl'
    default:
      return 'ltr'
  }
}
