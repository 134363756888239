import { Button, Card, Form, FormInstance, Input, Select, Spin } from 'antd'
import React from 'react'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { CheckOutlined } from '@ant-design/icons'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { IEditUser, IUserDetails, UserStatus } from '../../../models/user'
import { IPaginatedList } from '../../../models/paginated-list'
import { IRoleLight } from '../../../models/role'
import { MAX_PAGE_SIZE } from '../../../utils/constants'

const EditUserPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { id } = useParams()

  const formRef = React.useRef<FormInstance>(null)

  const queryClient = useQueryClient()

  const rolesQuery = useQuery({
    queryKey: ['roles-light'],
    queryFn: async () => {
      return axios.get<IPaginatedList<IRoleLight>>('/roles', {
        params: {
          minimal: 1,
          perPage: MAX_PAGE_SIZE,
        },
      })
    },
  })

  const query = useQuery({
    queryKey: ['user', id],
    queryFn: async () => {
      return axios.get<IUserDetails>(`/users/${id}`)
    },
    onSuccess: (data) => {
      formRef.current?.setFieldsValue({
        firstName: data.data.firstName,
        lastName: data.data.lastName,
        roles: data.data.roles.map((r) => r.id),
        status: data.data.status,
        mobile: data.data.mobile,
        email: data.data.email,
        address: data.data.address,
        note: data.data.note,
      })
    },
    refetchOnWindowFocus: false,
  })

  const mutation = useMutation({
    mutationFn: (data: IEditUser) => {
      return axios.put(`/users/${id}`, data)
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['users'] })
      queryClient.invalidateQueries({ queryKey: ['users-light'] })
      queryClient.invalidateQueries({ queryKey: ['user', id] })
      navigate(-1)
    },
  })

  const onSubmit = (data: any) => {
    mutation.mutate({
      firstName: data['firstName'],
      lastName: data['lastName'],
      roles: data['roles'],
      status: data['status'],
      mobile: data['mobile'],
      password: data['password'],
      email: data['email'],
      address: data['address'],
      note: data['note'],
    })
  }

  return (
    <Form
      name="form_item_path"
      layout="vertical"
      ref={formRef}
      onFinish={onSubmit}
    >
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('user.edit')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Button
              type="default"
              disabled={mutation.isLoading || query.isFetching}
              onClick={() => navigate(-1)}
            >
              {t('general.cancel')}
            </Button>
            <Button
              className={styles.saveButton}
              type="primary"
              htmlType="submit"
              icon={<CheckOutlined />}
              loading={mutation.isLoading}
              disabled={query.isFetching}
            >
              {t('general.save')}
            </Button>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: '1rem' }}>
        <Spin tip={t('general.loading')} spinning={query.isFetching}>
          <Form.Item
            name="firstName"
            label={t('user.first_name')}
            required
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label={t('user.last_name')}
            required
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="roles"
            label={t('user.roles')}
            required
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Select
              mode="multiple"
              options={rolesQuery.data?.data?.data?.map((r) => {
                return {
                  value: r.id,
                  label: r.name,
                }
              })}
            />
          </Form.Item>
          <Form.Item
            name="status"
            label={t('user.status')}
            required
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Select
              options={[
                {
                  label: t('user.active'),
                  value: UserStatus.ACTIVE,
                },
                {
                  label: t('user.not_active'),
                  value: UserStatus.NOT_ACTIVE,
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="mobile"
            label={t('user.mobile')}
            required
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="email"
            label={t('user.email')}
            required
            rules={[
              { required: true, message: t('validation.required') },
              { type: 'email', message: t('validation.email') },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="password" label={t('user.password')}>
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="password_confirmation"
            label={t('user.password_confirmation')}
            rules={[
              {
                validator: (_, value, callback) => {
                  const password = formRef.current?.getFieldValue('password')
                  if (!password) callback()

                  if (value !== password) {
                    callback(t('validation.password_confirmation'))
                  } else {
                    callback()
                  }
                },
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="address"
            label={t('user.address')}
            required
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="note" label={t('user.note')}>
            <Input.TextArea />
          </Form.Item>
        </Spin>
      </Card>
    </Form>
  )
}

export default EditUserPage
