import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { HowKnowApp } from '../../../models/client'

interface IProps {
  how: HowKnowApp
}

const HowKnowAppTag: React.FC<IProps> = ({ how }) => {
  const { t } = useTranslation()

  let label = ''
  let color = ''

  switch (how) {
    case HowKnowApp.FRIEND:
      label = t('client.friend')
      color = 'orange'
      break
    case HowKnowApp.MARKETING:
      label = t('client.marketing')
      color = 'blue'
      break
    case HowKnowApp.SOCIAL_NETWORKING_SITES:
      label = t('client.social')
      color = 'cyan'
      break
  }

  return <Tag color={color}>{label}</Tag>
}

export default HowKnowAppTag
