import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { DriverStatus } from '../../../models/driver'

interface IProps {
  status: DriverStatus
}

const DriverStatusTag: React.FC<IProps> = ({ status }) => {
  const { t } = useTranslation()

  let label = ''
  let color = ''

  switch (status) {
    case DriverStatus.AVAILABLE:
      label = t('driver.available')
      color = 'blue'
      break
    case DriverStatus.UNAVAILABLE:
      label = t('driver.not_available')
      color = 'red'
      break
  }

  return <Tag color={color}>{label}</Tag>
}

export default DriverStatusTag
