import { Button, Card, Form, FormInstance, Input, Space } from 'antd'
import React from 'react'
import styles from './style.module.css'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const { Search } = Input

interface IProps {
	title: string
	onSearch?: (value: string) => void
	trailing?: React.ReactNode[]
	filters?: React.ReactNode
	onFilterChange?: (data: any) => void
}

const HeaderCard: React.FC<IProps> = (props) => {
	const { t } = useTranslation()

	const formRef = React.useRef<FormInstance>(null)

	return (
		<Card
			className={styles.card}
			bodyStyle={{ padding: 0 }}
			headStyle={{ padding: 0 }}
			title={
				<div className={styles.title}>
					<h3>{props.title}</h3>
					<Space>
						{props.onSearch && (
							<Search
								placeholder={t('general.search_hint')}
								onSearch={props.onSearch}
								allowClear
								enterButton
							/>
						)}
						{props.trailing && props.trailing}
					</Space>
				</div>
			}
		>
			{props.filters && (
				<Form
					name='form_item_path'
					layout='vertical'
					onFinish={props.onFilterChange}
					ref={formRef}
				>
					<div className={styles.title}>
						<h4>{t('general.available_filters')}</h4>
						<Space>
							<Button
								type='default'
								icon={<CloseOutlined />}
								onClick={() => {
									formRef.current?.resetFields()
									props.onFilterChange && props.onFilterChange({})
								}}
							>
								{t('general.clear')}
							</Button>
							<Button type='primary' htmlType='submit' icon={<CheckOutlined />}>
								{t('general.apply')}
							</Button>
						</Space>
					</div>

					{props.filters}
				</Form>
			)}
		</Card>
	)
}

export default HeaderCard
