import { Button, Card, Form, Input, Select } from 'antd'
import React from 'react'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { CheckOutlined } from '@ant-design/icons'
import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import {
  IAddPublicNotification,
  PublicNotificationTo,
} from '../../../models/public-notification'

const AddPublicNotificationPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (data: IAddPublicNotification) => {
      return axios.post('/notification-public', data)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['public-notifications'] })
      queryClient.invalidateQueries({
        queryKey: ['public-notifications-light'],
      })
      navigate(-1)
    },
  })

  const onSubmit = (data: any) => {
    mutation.mutate({
      title: data['title'],
      body: data['body'],
      to: data['to'],
      note: data['note'],
    })
  }

  return (
    <Form name="form_item_path" layout="vertical" onFinish={onSubmit}>
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('public_notification.add')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Button
              type="default"
              disabled={mutation.isLoading}
              onClick={() => navigate(-1)}
            >
              {t('general.cancel')}
            </Button>
            <Button
              className={styles.saveButton}
              type="primary"
              htmlType="submit"
              icon={<CheckOutlined />}
              loading={mutation.isLoading}
            >
              {t('general.save')}
            </Button>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: '1rem' }}>
        <Form.Item
          name="title"
          label={t('public_notification.title')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="body"
          label={t('public_notification.body')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="to"
          label={t('public_notification.to')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Select
            options={[
              {
                label: t('public_notification.client'),
                value: PublicNotificationTo.CLIENT,
              },
              {
                label: t('public_notification.driver'),
                value: PublicNotificationTo.DRIVER,
              },
              {
                label: t('public_notification.both'),
                value: PublicNotificationTo.BOTH,
              },
            ]}
          />
        </Form.Item>
        <Form.Item name="note" label={t('public_notification.note')}>
          <Input.TextArea />
        </Form.Item>
      </Card>
    </Form>
  )
}

export default AddPublicNotificationPage
