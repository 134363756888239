import {
  Button,
  Card,
  Form,
  FormInstance,
  Image,
  Input,
  Select,
  Spin,
  Upload,
} from 'antd'
import React, { useEffect, useState } from 'react'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { CheckOutlined } from '@ant-design/icons'
import { IVehicleCapacityLight } from '../../../models/vehicle-capacity'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import {
  IEditVehicleCategory,
  IVehicleCategoryDetails,
  VehicleCategoryType,
} from '../../../models/vehicle-category'
import { IPaginatedList } from '../../../models/paginated-list'
import { ACCESS_TOKEN_KEY, MAX_PAGE_SIZE } from '../../../utils/constants'

const EditVehicleCategoryPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { id } = useParams()

  const formRef = React.useRef<FormInstance>(null)

  const queryClient = useQueryClient()

  const capacitiesQuery = useQuery({
    queryKey: ['vehicle-capacities-light'],
    queryFn: async () => {
      return axios.get<IPaginatedList<IVehicleCapacityLight>>('/capacity', {
        params: {
          minimal: 1,
          perPage: MAX_PAGE_SIZE,
        },
      })
    },
  })

  const query = useQuery({
    queryKey: ['vehicle-category', id],
    queryFn: async () => {
      return axios.get<IVehicleCategoryDetails>(`/category/${id}`)
    },
    onSuccess: (data) => {
      formRef.current?.setFieldsValue({
        'name.ku': data.data.translations.name.filter((n) => n.lang === 'ku')[0]
          .value,
        'name.ar': data.data.translations.name.filter((n) => n.lang === 'ar')[0]
          .value,
        'name.en': data.data.translations.name.filter((n) => n.lang === 'en')[0]
          .value,
        type: data.data.type,
        points: data.data.points,
        capacityId: data.data.capacity.id,
        photoId: data.data.photo.id,
        note: data.data.note,
      })
    },
    refetchOnWindowFocus: false,
  })

  const mutation = useMutation({
    mutationFn: (data: IEditVehicleCategory) => {
      return axios.put(`/category/${id}`, data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vehicle-categories'] })
      queryClient.invalidateQueries({ queryKey: ['vehicle-categories-light'] })
      queryClient.invalidateQueries({ queryKey: ['vehicle-category', id] })
      navigate(-1)
    },
  })

  const onSubmit = (data: any) => {
    // let photoId = query.data?.data?.photo.id
    // if (data['photoId'].length > 0) {
    //   photoId = data['photoId'][0].response.id
    // }

    mutation.mutate({
      name: {
        ku: data['name.ku'],
        ar: data['name.ar'],
        en: data['name.en'],
      },
      type: data['type'],
      points: data['points'],
      capacityId: data['capacityId'],
      photoId: photoIdValue ?? 1,
      note: data['note'],
    })
  }

  // Uploaded Image state
  const [uploadedFileResponse, setUploadedFileResponse] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [photoIdValue, setPhotoIdValue] = useState<number>()

  useEffect(() => {
    setPhotoIdValue(uploadedFileResponse?.id ?? query.data?.data?.photo?.id)
  }, [query.data, uploadedFileResponse])

  return (
    <Form
      name='form_item_path'
      layout='vertical'
      ref={formRef}
      onFinish={onSubmit}
    >
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('vehicle_category.edit')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Button
              type='default'
              disabled={mutation.isLoading || query.isFetching}
              onClick={() => navigate(-1)}
            >
              {t('general.cancel')}
            </Button>
            <Button
              className={styles.saveButton}
              type='primary'
              htmlType='submit'
              icon={<CheckOutlined />}
              loading={mutation.isLoading}
              disabled={query.isFetching}
            >
              {t('general.save')}
            </Button>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: '1rem' }}>
        <Spin tip={t('general.loading')} spinning={query.isFetching}>
          <Form.Item
            name='photoId'
            label={t('vehicle_category.photo')}
            getValueFromEvent={(e) => {
              return photoIdValue
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyItems: 'center',
              }}
            >
              {(query.data?.data?.photo?.url ||
                uploadedFileResponse?.url ||
                loading) && (
                <div className={styles.photoContainer}>
                  {loading ? (
                    <div>{t('general.loading')}</div>
                  ) : (
                    <Image
                      src={
                        uploadedFileResponse?.url ??
                        query.data?.data?.photo?.url
                      }
                      alt='photo'
                      height={'100%'}
                      style={{
                        objectFit: 'contain',
                      }}
                    />
                  )}
                </div>
              )}

              <Upload
                action={`${process.env.REACT_APP_BASE_API_URL}/media`}
                headers={{
                  Authorization: `Bearer ${localStorage.getItem(
                    ACCESS_TOKEN_KEY
                  )}`,
                }}
                listType='picture-card'
                showUploadList={false}
                maxCount={1}
                beforeUpload={() => {
                  setLoading(true)
                }}
                onChange={(info) => {
                  if (info.file.status === 'done') {
                    setUploadedFileResponse(info.file.response)
                    setLoading(false)
                  }
                }}
                data={async (file) => {
                  return {
                    medium: file,
                    for: 'user-profile',
                  }
                }}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>{t('general.upload')}</div>
                </div>
              </Upload>
            </div>
          </Form.Item>
          <Form.Item
            name='name.ku'
            label={`${t('vehicle_category.name')} (${t('language.kurdish')})`}
            required
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='name.ar'
            label={`${t('vehicle_category.name')} (${t('language.arabic')})`}
            required
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='name.en'
            label={`${t('vehicle_category.name')} (${t('language.english')})`}
            required
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='type'
            label={t('vehicle_category.type')}
            required
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Select
              options={[
                {
                  label: t('vehicle_category.transport'),
                  value: VehicleCategoryType.TRANSPORT,
                },
                {
                  label: t('vehicle_category.time_hours'),
                  value: VehicleCategoryType.TIME_HOURS,
                },
                {
                  label: t('vehicle_category.time_days'),
                  value: VehicleCategoryType.TIME_DAYS,
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name='points'
            label={t('vehicle_category.points')}
            required
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input type='number' />
          </Form.Item>
          <Form.Item
            name='capacityId'
            label={t('vehicle_category.capacity')}
            required
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Select
              loading={capacitiesQuery.isLoading}
              options={capacitiesQuery.data?.data.data.map((capacity) => {
                return {
                  label: capacity.name,
                  value: capacity.id,
                }
              })}
            />
          </Form.Item>
          <Form.Item name='note' label={t('vehicle_category.note')}>
            <Input.TextArea />
          </Form.Item>
        </Spin>
      </Card>
    </Form>
  )
}

export default EditVehicleCategoryPage
