import axios from 'axios'
import dayjs from 'dayjs'
import HeaderCard from '../../../components/common/header-card'
import RewardGettingWayTag from '../../../components/enums/reward-getting-way-tag'
import RewardStatusTag from '../../../components/enums/reward-status-tag'
import { Card, Table, Tooltip, Typography } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { IPaginatedList } from '../../../models/paginated-list'
import { IUserLight } from '../../../models/user'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IRewardDetails,
  RewardGettingWay,
  RewardStatus,
} from '../../../models/reward'
import {
  DATE_TIME_FORMAT,
  DEFAULT_PAGE_SIZE,
  INITIAL_PAGE,
} from '../../../utils/constants'

const { Text } = Typography

const RewardHistoryPage = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  const [page, setPage] = useState(INITIAL_PAGE)
  const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE)
  const [search, setSearch] = useState<string | undefined>()

  const query = useQuery({
    queryKey: ['reward-history', page, perPage, search],
    queryFn: async () => {
      return axios.get<IPaginatedList<IRewardDetails>>('/reward-histories', {
        params: {
          page,
          perPage,
          search,
          filters: [
            {
              name: 'rewardId',
              operation: '=',
              value: id,
            },
          ],
        },
      })
    },
  })

  const columns: ColumnsType<IRewardDetails> = [
    {
      title: t('general.id'),
      dataIndex: 'id',
      render: (id) => <Link to={`/reward/${id}`}>{id}</Link>,
    },
    {
      title: t('reward.name'),
      dataIndex: 'name',
    },
    {
      title: t('reward.value'),
      dataIndex: 'value',
    },
    {
      title: t('reward.status'),
      dataIndex: 'status',
      render: (status: RewardStatus) => <RewardStatusTag status={status} />,
    },
    {
      title: t('reward.month'),
      dataIndex: 'month',
    },
    {
      title: t('reward.year'),
      dataIndex: 'year',
    },
    {
      title: t('reward.way_get_reward'),
      dataIndex: 'wayGetReward',
      render: (way: RewardGettingWay) => <RewardGettingWayTag way={way} />,
    },
    {
      title: t('reward.num_of_point'),
      dataIndex: 'numOfPoint',
    },
    {
      title: t('reward.num_of_rate'),
      dataIndex: 'numOfRate',
    },
    {
      title: t('vehicle_category.note'),
      dataIndex: 'note',
      render: (note: string) => {
        return (
          <Tooltip title={note}>
            <Text
              style={{
                width: 100,
              }}
              ellipsis
            >
              {note}
            </Text>
          </Tooltip>
        )
      },
    },
    {
      title: t('general.created_at'),
      dataIndex: 'createdAt',
      render: (createdAt: Date) => {
        return dayjs(createdAt).format(DATE_TIME_FORMAT)
      },
    },
    {
      title: t('general.created_by'),
      dataIndex: 'createdBy',
      render: (createdBy: IUserLight) => {
        return createdBy?.firstName
      },
    },
    {
      title: t('general.updated_at'),
      dataIndex: 'updatedAt',
      render: (updatedAt: Date) => {
        return dayjs(updatedAt).format(DATE_TIME_FORMAT)
      },
    },
    {
      title: t('general.updated_by'),
      dataIndex: 'updatedBy',
      render: (updatedBy: IUserLight) => {
        return updatedBy?.firstName
      },
    },
  ]

  return (
    <>
      <HeaderCard
        title={t('reward.label')}
        onSearch={(value) => {
          setSearch(value.length === 0 ? undefined : value)
        }}
      />
      <Card bordered={false} bodyStyle={{ padding: 0 }}>
        <Table
          loading={query.isFetching}
          columns={columns}
          dataSource={query.data?.data.data}
          rowKey={(record) => record.id}
          style={{ overflowX: 'scroll' }}
          pagination={{
            current: page,
            pageSize: perPage,
            pageSizeOptions: [5, 10, 20, 50, 100],
            showSizeChanger: true,
            total: query.data?.data.total,
            position: ['bottomCenter'],
            onChange(page, pageSize) {
              setPage(page)
              setPerPage(pageSize)
            },
            showTotal: (total, range) => {
              return `${t('general.showing')} ${range[0]} - ${range[1]} ${t(
                'general.from'
              )} ${total} ${t('general.items')}`
            },
          }}
        />
      </Card>
    </>
  )
}

export default RewardHistoryPage
