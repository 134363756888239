import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { UserStatus } from '../../../models/user'

interface IProps {
  status: UserStatus
}

const UserStatusTag: React.FC<IProps> = ({ status }) => {
  const { t } = useTranslation()

  let label = ''
  let color = ''

  switch (status) {
    case UserStatus.ACTIVE:
      label = t('user.active')
      color = 'green'
      break
    case UserStatus.NOT_ACTIVE:
      label = t('user.not_active')
      color = 'red'
      break
  }

  return <Tag color={color}>{label}</Tag>
}

export default UserStatusTag
