interface ICurrencies {
  USD: string
  EUR: string
  JPY: string
  GBP: string
  AUD: string
  CAD: string
  CHF: string
  CNY: string
  SEK: string
  NZD: string
  MXN: string
  SGD: string
  HKD: string
  NOK: string
  KRW: string
  TRY: string
  RUB: string
  INR: string
  BRL: string
  ZAR: string
  IQD: string
}

export const currencies: ICurrencies = {
  USD: 'United States Dollar',
  EUR: 'Euro',
  JPY: 'Japanese Yen',
  GBP: 'British Pound',
  AUD: 'Australian Dollar',
  CAD: 'Canadian Dollar',
  CHF: 'Swiss Franc',
  CNY: 'Chinese Yuan',
  SEK: 'Swedish Krona',
  NZD: 'New Zealand Dollar',
  MXN: 'Mexican Peso',
  SGD: 'Singapore Dollar',
  HKD: 'Hong Kong Dollar',
  NOK: 'Norwegian Krone',
  KRW: 'South Korean Won',
  TRY: 'Turkish Lira',
  RUB: 'Russian Ruble',
  INR: 'Indian Rupee',
  BRL: 'Brazilian Real',
  ZAR: 'South African Rand',
  IQD: 'Iraqi Dinar',
}

export const currencyOptions = Object.keys(currencies).map((e) => ({
  label: e,
  value: e,
}))
