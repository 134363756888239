import {
  EditOutlined,
  FileExcelOutlined,
  NodeIndexOutlined,
} from '@ant-design/icons'
import { Button, Card, Descriptions, Spin } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import DurationTypeTag from '../../../components/enums/duration-type-tag'
import RequestStatusTag from '../../../components/enums/request-status-tag'
import VehicleCategoryTypeTag from '../../../components/enums/vehicle-category-type-tag'
import RequestTrackModal from '../../../components/requests/request-track-modal'
import { IRequestDetails } from '../../../models/request'
import { DATE_TIME_FORMAT } from '../../../utils/constants'
import FileDownload from 'js-file-download'
import styles from './style.module.css'

const RequestDetailsPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { id } = useParams()

  const query = useQuery({
    queryKey: ['request', id],
    queryFn: async () => {
      return axios.get<IRequestDetails>(`/request/${id}`)
    },
  })

  const data = query.data?.data

  // Track Modal
  const [trackModalVisible, setTrackModalVisible] = useState(false)

  //
  const mutation = useMutation({
    mutationFn: () => {
      return axios.get(`/generate-pdf/${id}`, { responseType: 'blob' })
    },
    onSuccess: (response) => {
      FileDownload(response.data, 'template.pdf')
    },
    onError: () => {},
  })

  const handleGeneratePDF = (id: number) => {
    mutation.mutate()
  }

  return (
    <div>
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('request.details')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Button type='default' onClick={() => navigate(-1)}>
              {t('general.cancel')}
            </Button>
            <Button
              className={styles.saveButton}
              type='primary'
              icon={<NodeIndexOutlined />}
              onClick={() => {
                // Show dialog and track request on map (start, end, driver location).
                console.log('🚀 ~ file: index.tsx:52 ~ data:', data)

                setTrackModalVisible(true)
              }}
            >
              {t('request.track')}
            </Button>
            <Button
              className={styles.saveButton}
              type='primary'
              icon={<FileExcelOutlined />}
              onClick={() => data?.id && handleGeneratePDF(data.id)}
            >
              {t('general.generate_pdf')}
            </Button>
            <Button
              className={styles.saveButton}
              type='primary'
              icon={<EditOutlined />}
              onClick={() => navigate(`/request/${id}/edit`)}
            >
              {t('general.edit')}
            </Button>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: 0 }}>
        <Spin tip={t('general.loading')} spinning={query.isFetching}>
          <Descriptions bordered column={1}>
            <Descriptions.Item label={t('general.id')}>
              {data?.id}
            </Descriptions.Item>
            <Descriptions.Item label={t('request.client')}>
              {data?.client &&
                `${data.client.firstName} ${data.client.lastName}`}
            </Descriptions.Item>
            <Descriptions.Item label={t('request.vehicle_category')}>
              {data?.category?.name}
            </Descriptions.Item>
            <Descriptions.Item label={t('request.vehicle_category_type')}>
              {data?.category?.type && (
                <VehicleCategoryTypeTag type={data?.category?.type} />
              )}
            </Descriptions.Item>
            <Descriptions.Item label={t('request.driver')}>
              {data?.driver &&
                `${data.driver.firstName} ${data.driver.lastName}`}
            </Descriptions.Item>
            <Descriptions.Item label={t('request.status')}>
              {data?.status && <RequestStatusTag status={data?.status} />}
            </Descriptions.Item>
            <Descriptions.Item label={t('request.weight')}>
              {data?.weight}
            </Descriptions.Item>
            <Descriptions.Item label={t('request.required_duration')}>
              {data?.requiredDuration}
            </Descriptions.Item>
            <Descriptions.Item label={t('request.duration_type')}>
              {data?.durationType && (
                <DurationTypeTag type={data?.durationType} />
              )}
            </Descriptions.Item>
            <Descriptions.Item label={t('request.scheduled_date')}>
              {data?.scheduledDate &&
                dayjs(data?.scheduledDate).format(DATE_TIME_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.created_at')}>
              {dayjs(data?.createdAt).format(DATE_TIME_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label={t('request.price')}>
              {data?.price}
            </Descriptions.Item>
            <Descriptions.Item label={t('request.start_location')}>
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${data?.startLat},${data?.startLong}`}
                target='_blank'
                rel='noreferrer'
              >
                {t('general.view_on_map')}
              </a>
            </Descriptions.Item>
            <Descriptions.Item label={t('request.end_location')}>
              {data?.endLat && data?.endLong && (
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${data?.endLat},${data?.endLong}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {t('general.view_on_map')}
                </a>
              )}
            </Descriptions.Item>
            <Descriptions.Item label={t('request.description')}>
              {data?.description}
            </Descriptions.Item>
            <Descriptions.Item label={t('request.estimated_duration')}>
              {data?.estimatedDuration}
            </Descriptions.Item>
            <Descriptions.Item label={t('request.rate')}>
              {data?.rate}
            </Descriptions.Item>
            <Descriptions.Item label={t('request.notes')}>
              {data?.notes}
            </Descriptions.Item>
          </Descriptions>
        </Spin>
      </Card>

      {data && (
        <RequestTrackModal
          data={data}
          visible={trackModalVisible}
          setVisible={setTrackModalVisible}
          loading={query.isFetching}
        />
      )}
    </div>
  )
}

export default RequestDetailsPage
