import axios from 'axios'
import { ACCESS_TOKEN_KEY } from '../constants'

axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL

axios.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY)
  config.headers.Authorization = `Bearer ${accessToken}`

  config.headers['Accept-Language'] = localStorage.getItem('i18nextLng')

  return config
})
