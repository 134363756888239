import { Button, Card, Col, Form, Row, Select, Table } from 'antd'
import { SorterResult } from 'antd/es/table/interface'
import axios from 'axios'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { FileExcelOutlined } from '@ant-design/icons'

import HeaderCard from '../../../components/common/header-card'
import { ICouponLight, ICouponUsage } from '../../../models/coupon'
import { IDriverLight } from '../../../models/driver'
import { IPaginatedList } from '../../../models/paginated-list'
import { IFilter, ISort } from '../../../models/shared'
import {
	DATE_TIME_FORMAT,
	DEFAULT_PAGE_SIZE,
	DEFAULT_SORT_FUNCTION,
	INITIAL_PAGE,
	MAX_PAGE_SIZE
} from '../../../utils/constants'

import type { ColumnsType } from 'antd/es/table'
import ExportToExcelModal from '../../../components/common/export-to-excel-modal'
const CouponsUsagePage: React.FC = () => {
	const { t } = useTranslation()

	const [page, setPage] = useState(INITIAL_PAGE)
	const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE)
	const [search, setSearch] = useState<string | undefined>()
	const [filters, setFilters] = useState<IFilter[]>()
	const [sort, setSort] = useState<ISort | undefined>()
	const [exportModelVisible, setExportModalVisible] = useState(false)

	const query = useQuery({
		queryKey: ['coupons-usage', page, perPage, search, filters, sort],
		queryFn: async () => {
			return axios.get<IPaginatedList<ICouponUsage>>('/coupon-driver', {
				params: {
					page,
					perPage,
					search,
					filters,
					orders: [sort]
				}
			})
		}
	})

	const couponsQuery = useQuery({
		queryKey: ['coupons-light'],
		queryFn: async () => {
			return axios.get<IPaginatedList<ICouponLight>>('/coupon', {
				params: {
					minimal: 1,
					perPage: MAX_PAGE_SIZE
				}
			})
		}
	})

	const driversQuery = useQuery({
		queryKey: ['drivers-light'],
		queryFn: async () => {
			return axios.get<IPaginatedList<IDriverLight>>('/drivers', {
				params: {
					minimal: 1,
					perPage: MAX_PAGE_SIZE
				}
			})
		}
	})

	const columns: ColumnsType<ICouponUsage> = [
		{
			title: t('general.id'),
			dataIndex: 'id',
			key: 'id',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('coupon.name'),
			dataIndex: 'name',
			key: 'name',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('coupon.value'),
			dataIndex: 'value',
			key: 'value',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('coupon.code'),
			dataIndex: 'code'
		},
		{
			title: t('coupon.driver'),
			render: (_, record) => {
				return `${record.firstName} ${record.lastName}`
			}
		},
		{
			title: t('coupon.activation_date'),
			dataIndex: 'couponActivationDate',
			render: (date: Date) => dayjs(date).format(DATE_TIME_FORMAT),
			key: 'couponActivationDate',
			sorter: DEFAULT_SORT_FUNCTION
		}
	]

	return (
		<>
			<HeaderCard
				title={t('coupon.usage')}
				onSearch={(value) => {
					setSearch(value.length === 0 ? undefined : value)
				}}
				trailing={[
					<>
						<Button
							type='default'
							icon={<FileExcelOutlined />}
							onClick={() => setExportModalVisible(true)}
						>
							{t('general.export_to_excel')}
						</Button>
						<ExportToExcelModal
							visible={exportModelVisible}
							setVisible={setExportModalVisible}
							exportUrl={'/coupon/driver/export'}
							columns={[
								{ name: 'id', label: 'general.id' },
								{ name: 'name', label: 'coupon.name' },
								{ name: 'value', label: 'coupon.value' },
								{ name: 'firstName', label: 'coupon.driver_first_name' },
								{ name: 'lastName', label: 'coupon.driver_last_name' },
								{
									name: 'couponActivationDate',
									label: 'coupon.activation_date'
								},
								{ name: 'code', label: 'coupon.code' }
							]}
						/>
					</>
				]}
				onFilterChange={(data) => {
					var filters: IFilter[] = []
					data.driverId &&
						filters.push({
							name: 'driverId',
							operation: '=',
							value: data.driverId
						})
					data.couponId &&
						filters.push({
							name: 'couponId',
							operation: '=',
							value: data.couponId
						})
					setFilters(filters.length === 0 ? undefined : filters)
				}}
				filters={
					<Row style={{ padding: '0 1rem' }} gutter={16}>
						<Col span={6}>
							<Form.Item name='driverId' label={t('coupon.driver')}>
								<Select
									loading={driversQuery.isLoading}
									options={driversQuery.data?.data.data.map((driver) => {
										return {
											label: `#${driver.id}) ${driver.firstName} ${driver.lastName}`,
											value: driver.id
										}
									})}
									showSearch
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name='couponId' label={t('coupon.label')}>
								<Select
									loading={couponsQuery.isLoading}
									options={couponsQuery.data?.data.data.map((coupon) => {
										return {
											label: `#${coupon.id}) ${coupon.name} (${coupon.value})`,
											value: coupon.id
										}
									})}
									showSearch
								/>
							</Form.Item>
						</Col>
					</Row>
				}
			/>
			<Card bordered={false} bodyStyle={{ padding: 0 }}>
				<Table
					loading={query.isFetching}
					columns={columns}
					dataSource={query.data?.data.data}
					rowKey={(record) => record.id}
					style={{ overflowX: 'scroll' }}
					onChange={(_, __, sorter, ___) => {
						const { columnKey, order } = sorter as SorterResult<ICouponUsage>

						if (!order) {
							setSort(undefined)
						} else {
							setSort({
								name: columnKey as string,
								direction: order === 'ascend' ? 'asc' : 'desc'
							})
						}
					}}
					pagination={{
						current: page,
						pageSize: perPage,
						pageSizeOptions: [5, 10, 20, 50, 100],
						showSizeChanger: true,
						total: query.data?.data.total,
						position: ['bottomCenter'],
						onChange(page, pageSize) {
							setPage(page)
							setPerPage(pageSize)
						},
						showTotal: (total, range) => {
							return `${t('general.showing')} ${range[0]} - ${range[1]} ${t(
								'general.from'
							)} ${total} ${t('general.items')}`
						}
					}}
				/>
			</Card>
		</>
	)
}

export default CouponsUsagePage
