import GMarker from '../marker'
import BPinIcon from '../../../../assets/images/b-pin.png'
import { FC, useMemo } from 'react'

interface IProps {
  heading?: number
  position: google.maps.LatLng | google.maps.LatLngLiteral
  isMapLoaded: boolean
  onClick?: (e: google.maps.MapMouseEvent) => void
}

const GMarkerEnd: FC<IProps> = (props) => {
  const { isMapLoaded, position, onClick } = props

  const bPinIcon = useMemo(
    () =>
      isMapLoaded
        ? {
            url: BPinIcon, // url
            scaledSize: new window.google.maps.Size(24, 24), // size
          }
        : undefined,
    [isMapLoaded]
  )

  return <GMarker position={position} icon={bPinIcon} onClick={onClick} />
}

export default GMarkerEnd
