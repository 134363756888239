import { ICity } from './city'
import { IMedia } from './media'
import { IUserLight, UserStatus } from './user'
import { IVehicleCategory } from './vehicle-category'

export enum DriverStatus {
	AVAILABLE = 1,
	UNAVAILABLE = 2
}

export interface IDriverLight {
	id: number
	firstName: string
	lastName: string
}

export interface IDriver {
	id: number
	status: DriverStatus
	driverAccountStatus: UserStatus
	points: number
	rating?: number
	sumRequest: number
	dateLastRequest?: Date
	user: {
		id: number
		firstName: string
		lastName: string
		mobile: string
	}
	cities: ICity[]
	crane: {
		code: string
		number: string
		category: IVehicleCategory
	}
	wallet: {
		id: number
		value: number
	}
	createdAt: Date
	deletedAt?: Date
}

export interface IDriverDetails extends IDriver {
	user: {
		id: number
		firstName: string
		lastName: string
		mobile: string
		photo: IMedia
	}
	crane: {
		id: number
		photo: IMedia
		code: string
		number: string
		capacity: number
		category: IVehicleCategory
	}
	license: IMedia
	note?: string
	countRequest: number
	updatedAt?: Date
	updatedBy?: IUserLight
}
