import { CheckOutlined } from '@ant-design/icons'
import { Button, Card, Form, Input, Select } from 'antd'
import axios from 'axios'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { IDriverLight } from '../../../models/driver'
import { IPaginatedList } from '../../../models/paginated-list'
import { IAddPayment, PaymentType } from '../../../models/payment'
import { MAX_PAGE_SIZE } from '../../../utils/constants'
import styles from './style.module.css'

const AddPaymentPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const driversQuery = useQuery({
    queryKey: ['drivers-light'],
    queryFn: async () => {
      return axios.get<IPaginatedList<IDriverLight>>('/drivers', {
        params: {
          minimal: 1,
          perPage: MAX_PAGE_SIZE,
        },
      })
    },
  })

  const mutation = useMutation({
    mutationFn: (data: IAddPayment) => {
      return axios.post('/payment', data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['payments'] })
      navigate(-1)
    },
  })

  const onSubmit = (data: any) => {
    mutation.mutate({
      value: data['value'],
      type: data['type'],
      driverId: data['driverId'],
      note: data['note'],
    })
  }

  return (
    <Form name='form_item_path' layout='vertical' onFinish={onSubmit}>
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('payment.add')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Button
              type='default'
              disabled={mutation.isLoading}
              onClick={() => navigate(-1)}
            >
              {t('general.cancel')}
            </Button>
            <Button
              className={styles.saveButton}
              type='primary'
              htmlType='submit'
              icon={<CheckOutlined />}
              loading={mutation.isLoading}
            >
              {t('general.save')}
            </Button>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: '1rem' }}>
        <Form.Item
          name='value'
          label={t('payment.value')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input type='number' />
        </Form.Item>
        <Form.Item
          name='type'
          label={t('payment.type')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Select
            options={[
              {
                label: t('payment.incoming'),
                value: PaymentType.INCOMING,
              },
              {
                label: t('payment.outgoing'),
                value: PaymentType.OUTGOING,
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name='driverId'
          label={t('payment.driver')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Select
            loading={driversQuery.isLoading}
            options={driversQuery.data?.data.data.map((driver) => {
              return {
                label: `#${driver.id}) ${driver.firstName} ${driver.lastName}`,
                value: driver.id,
              }
            })}
            showSearch
          />
        </Form.Item>
        <Form.Item name='note' label={t('payment.note')}>
          <Input.TextArea />
        </Form.Item>
      </Card>
    </Form>
  )
}

export default AddPaymentPage
