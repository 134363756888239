import React, { useContext, useState } from 'react'
import { Space, Table, Card, Button, Popconfirm } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import {
	PlusOutlined,
	EyeOutlined,
	EditOutlined,
	DeleteOutlined,
	SendOutlined,
	FileExcelOutlined
} from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { IPaginatedList } from '../../models/paginated-list'
import { DEFAULT_PAGE_SIZE, INITIAL_PAGE } from '../../utils/constants'
import axios from 'axios'
import {
	IPublicNotification,
	PublicNotificationTo
} from '../../models/public-notification'
import PublicNotificationToTag from '../../components/enums/public-notification-to-tag'
import HeaderCard from '../../components/common/header-card'
import ExportToExcelModal from '../../components/common/export-to-excel-modal'
import AuthContext from '../../contexts/auth-context/context'
import {
	CAN_CREATE_NOTIFICATION_PUBLIC,
	CAN_DELETE_NOTIFICATION_PUBLIC,
	CAN_EXPORT_NOTIFICATION_PUBLIC,
	CAN_UPDATE_NOTIFICATION_PUBLIC
} from '../../utils/rbac/permissions'

const PublicNotificationsPage: React.FC = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const {
		actions: { can }
	} = useContext(AuthContext)

	const queryClient = useQueryClient()

	const [page, setPage] = useState(INITIAL_PAGE)
	const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE)
	const [search, setSearch] = useState<string | undefined>()
	const [exportModelVisible, setExportModalVisible] = useState(false)

	const query = useQuery({
		queryKey: ['public-notifications', page, perPage, search],
		queryFn: async () => {
			return axios.get<IPaginatedList<IPublicNotification>>(
				'/notification-public',
				{
					params: {
						page,
						perPage,
						search
					}
				}
			)
		}
	})

	const mutation = useMutation({
		mutationFn: (id: number) => {
			return axios.delete(`/notification-public/${id}`)
		},
		onSuccess: (_, id) => {
			if (query.data?.data.data.length === 1) {
				setPage(INITIAL_PAGE)
			}
			queryClient.invalidateQueries({ queryKey: ['public-notifications'] })
			queryClient.invalidateQueries({
				queryKey: ['public-notifications-light']
			})
			queryClient.invalidateQueries({ queryKey: ['public-notification', id] })
		}
	})

	const [selectToSendNotificationId, setSelectToSendNotificationId] =
		useState<number>()

	const sendNotificationMutation = useMutation({
		mutationFn: (id: number) => {
			return axios.get(`/send-notification/${id}`)
		}
	})

	const columns: ColumnsType<IPublicNotification> = [
		{
			title: t('general.id'),
			dataIndex: 'id',
			render: (id) => <Link to={`/public-notification/${id}`}>{id}</Link>
		},
		{
			title: t('public_notification.title'),
			dataIndex: 'title'
		},
		{
			title: t('public_notification.body'),
			dataIndex: 'body'
		},
		{
			title: t('public_notification.to'),
			dataIndex: 'to',
			render: (to: PublicNotificationTo) => <PublicNotificationToTag to={to} />
		},
		{
			title: t('public_notification.note'),
			dataIndex: 'note'
		},
		{
			title: t('general.actions'),
			render: (_, record) => (
				<Space>
					<Popconfirm
						title={t('general.send_notification_warning')}
						description={t('general.send_notification_confirmation')}
						onConfirm={() => {
							setSelectToSendNotificationId(record.id)
							sendNotificationMutation.mutate(record.id)
						}}
						okText={t('general.send')}
						cancelText={t('general.no')}
					>
						<Button
							type='link'
							icon={<SendOutlined />}
							loading={
								sendNotificationMutation.isLoading &&
								selectToSendNotificationId === record.id
							}
						>
							{t('general.send')}
						</Button>
					</Popconfirm>

					<Button
						type='link'
						icon={<EyeOutlined />}
						onClick={() => navigate(`/public-notification/${record.id}`)}
					>
						{t('general.show')}
					</Button>
					{can(CAN_UPDATE_NOTIFICATION_PUBLIC) && (
						<Button
							type='link'
							icon={<EditOutlined />}
							onClick={() => navigate(`/public-notification/${record.id}/edit`)}
						>
							{t('general.edit')}
						</Button>
					)}
					{can(CAN_DELETE_NOTIFICATION_PUBLIC) && (
						<Popconfirm
							title={t('general.delete_warning')}
							description={t('general.delete_confirmation')}
							onConfirm={() => mutation.mutate(record.id)}
							okText={t('general.yes')}
							cancelText={t('general.no')}
						>
							<Button type='link' icon={<DeleteOutlined />} danger>
								{t('general.delete')}
							</Button>
						</Popconfirm>
					)}
				</Space>
			)
		}
	]

	return (
		<>
			<HeaderCard
				title={t('public_notification.label')}
				onSearch={(value) => {
					setSearch(value.length === 0 ? undefined : value)
				}}
				trailing={[
					can(CAN_CREATE_NOTIFICATION_PUBLIC) ? (
						<Button
							type='primary'
							icon={<PlusOutlined />}
							onClick={() => navigate('/public-notification/add')}
						>
							{t('general.add')}
						</Button>
					) : null,
					can(CAN_EXPORT_NOTIFICATION_PUBLIC) ? (
						<>
							<Button
								type='default'
								icon={<FileExcelOutlined />}
								onClick={() => setExportModalVisible(true)}
							>
								{t('general.export_to_excel')}
							</Button>
							<ExportToExcelModal
								visible={exportModelVisible}
								setVisible={setExportModalVisible}
								exportUrl={'/notification-public/export'}
								columns={[
									{ name: 'id', label: 'general.id' },
									{ name: 'title', label: 'public_notification.title' },
									{ name: 'body', label: 'public_notification.body' },
									{ name: 'to', label: 'public_notification.to' },
									{ name: 'note', label: 'public_notification.note' }
								]}
							/>
						</>
					) : null
				].filter((i) => i != null)}
			/>
			<Card bordered={false} bodyStyle={{ padding: 0 }}>
				<Table
					loading={query.isFetching}
					columns={columns}
					dataSource={query.data?.data.data}
					rowKey={(record) => record.id}
					style={{ overflowX: 'scroll' }}
					pagination={{
						current: page,
						pageSize: perPage,
						pageSizeOptions: [5, 10, 20, 50, 100],
						showSizeChanger: true,
						total: query.data?.data.total,
						position: ['bottomCenter'],
						onChange(page, pageSize) {
							setPage(page)
							setPerPage(pageSize)
						},
						showTotal: (total, range) => {
							return `${t('general.showing')} ${range[0]} - ${range[1]} ${t(
								'general.from'
							)} ${total} ${t('general.items')}`
						}
					}}
				/>
			</Card>
		</>
	)
}

export default PublicNotificationsPage
