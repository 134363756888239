import { Button, Card, Form, FormInstance, Input, Select, Spin } from 'antd'
import React from 'react'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { CheckOutlined } from '@ant-design/icons'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { IEditRole, IRoleDetails } from '../../../models/role'
import { IPaginatedList } from '../../../models/paginated-list'
import { IPermissionLight } from '../../../models/permission'
import { MAX_PAGE_SIZE } from '../../../utils/constants'

const EditRolePage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { id } = useParams()

  const formRef = React.useRef<FormInstance>(null)

  const queryClient = useQueryClient()

  const permissionsQuery = useQuery({
    queryKey: ['permissions-light'],
    queryFn: async () => {
      return axios.get<IPaginatedList<IPermissionLight>>('/permissions', {
        params: {
          minimal: 1,
          perPage: MAX_PAGE_SIZE,
        },
      })
    },
  })

  const query = useQuery({
    queryKey: ['role', id],
    queryFn: async () => {
      return axios.get<IRoleDetails>(`/roles/${id}`)
    },
    onSuccess: (data) => {
      formRef.current?.setFieldsValue({
        name: data.data.name,
        permissions: data.data.permissions.map((p) => p.id),
        note: data.data.note,
      })
    },
    refetchOnWindowFocus: false,
  })

  const mutation = useMutation({
    mutationFn: (data: IEditRole) => {
      return axios.put(`/roles/${id}`, data)
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['roles'] })
      queryClient.invalidateQueries({ queryKey: ['roles-light'] })
      queryClient.invalidateQueries({ queryKey: ['role', id] })
      navigate(-1)
    },
  })

  const onSubmit = (data: any) => {
    mutation.mutate({
      name: data['name'],
      permissions: data['permissions'],
      note: data['note'],
    })
  }

  return (
    <Form
      name="form_item_path"
      layout="vertical"
      ref={formRef}
      onFinish={onSubmit}
    >
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('role.edit')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Button
              type="default"
              disabled={mutation.isLoading || query.isFetching}
              onClick={() => navigate(-1)}
            >
              {t('general.cancel')}
            </Button>
            <Button
              className={styles.saveButton}
              type="primary"
              htmlType="submit"
              icon={<CheckOutlined />}
              loading={mutation.isLoading}
              disabled={query.isFetching}
            >
              {t('general.save')}
            </Button>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: '1rem' }}>
        <Spin tip={t('general.loading')} spinning={query.isFetching}>
          <Form.Item
            name="name"
            label={t('role.name')}
            required
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="permissions"
            label={t('role.permissions')}
            required
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Select
              mode="multiple"
              options={permissionsQuery.data?.data?.data?.map((p) => {
                return {
                  value: p.id,
                  label: p.name,
                }
              })}
            />
          </Form.Item>
          <Form.Item name="note" label={t('role.note')}>
            <Input.TextArea />
          </Form.Item>
        </Spin>
      </Card>
    </Form>
  )
}

export default EditRolePage
