import React, { useContext, useState } from 'react'
import {
	Space,
	Table,
	Card,
	Button,
	Popconfirm,
	Tag,
	Select,
	Row,
	Col,
	Form
} from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import {
	PlusOutlined,
	EyeOutlined,
	EditOutlined,
	DeleteOutlined,
	FileExcelOutlined
} from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { IPaginatedList } from '../../models/paginated-list'
import {
	DEFAULT_PAGE_SIZE,
	DEFAULT_SORT_FUNCTION,
	INITIAL_PAGE,
	MAX_PAGE_SIZE
} from '../../utils/constants'
import axios from 'axios'
import { IUser, UserStatus } from '../../models/user'
import { IRoleLight } from '../../models/role'
import UserStatusTag from '../../components/enums/user-status-tag'
import { IFilter, ISort } from '../../models/shared'
import { SorterResult } from 'antd/es/table/interface'
import HeaderCard from '../../components/common/header-card'
import ExportToExcelModal from '../../components/common/export-to-excel-modal'
import AuthContext from '../../contexts/auth-context/context'
import {
	CAN_CREATE_USER,
	CAN_DELETE_USER,
	CAN_EXPORT_USERS,
	CAN_UPDATE_USER
} from '../../utils/rbac/permissions'

const UsersPage: React.FC = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const {
		actions: { can }
	} = useContext(AuthContext)

	const queryClient = useQueryClient()

	const [page, setPage] = useState(INITIAL_PAGE)
	const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE)
	const [search, setSearch] = useState<string | undefined>()
	const [filters, setFilters] = useState<IFilter[]>()
	const [sort, setSort] = useState<ISort | undefined>()
	const [exportModelVisible, setExportModalVisible] = useState(false)

	const query = useQuery({
		queryKey: ['users', page, perPage, search, filters, sort],
		queryFn: async () => {
			return axios.get<IPaginatedList<IUser>>('/users', {
				params: {
					page,
					perPage,
					search,
					filters,
					orders: [sort]
				}
			})
		}
	})

	const mutation = useMutation({
		mutationFn: (id: number) => {
			return axios.delete(`/users/${id}`)
		},
		onSuccess: (_, id) => {
			if (query.data?.data.data.length === 1) {
				setPage(INITIAL_PAGE)
			}
			queryClient.invalidateQueries({ queryKey: ['users'] })
			queryClient.invalidateQueries({ queryKey: ['users-light'] })
			queryClient.invalidateQueries({ queryKey: ['user', id] })
		}
	})

	const rolesQuery = useQuery({
		queryKey: ['roles-light'],
		queryFn: async () => {
			return axios.get<IPaginatedList<IRoleLight>>('/roles', {
				params: {
					minimal: 1,
					perPage: MAX_PAGE_SIZE
				}
			})
		}
	})

	const columns: ColumnsType<IUser> = [
		{
			title: t('general.id'),
			dataIndex: 'id',
			key: 'id',
			render: (id) => <Link to={`/user/${id}`}>{id}</Link>,
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('user.first_name'),
			dataIndex: 'firstName',
			key: 'firstName',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('user.last_name'),
			dataIndex: 'lastName',
			key: 'lastName',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('user.roles'),
			dataIndex: 'roles',
			render: (roles: IRoleLight[]) => (
				<Space>
					{roles?.map((r) => (
						<Tag key={r.id}>{r.name}</Tag>
					))}
				</Space>
			)
		},
		{
			title: t('user.status'),
			dataIndex: 'status',
			render: (status: UserStatus) => <UserStatusTag status={status} />
		},
		{
			title: t('user.mobile'),
			dataIndex: 'mobile'
		},
		{
			title: t('general.actions'),
			render: (_, record) => (
				<Space>
					<Button
						type='link'
						icon={<EyeOutlined />}
						onClick={() => navigate(`/user/${record.id}`)}
					>
						{t('general.show')}
					</Button>
					{can(CAN_UPDATE_USER) && (
						<Button
							type='link'
							icon={<EditOutlined />}
							onClick={() => navigate(`/user/${record.id}/edit`)}
						>
							{t('general.edit')}
						</Button>
					)}
					{can(CAN_DELETE_USER) && (
						<Popconfirm
							title={t('general.delete_warning')}
							description={t('general.delete_confirmation')}
							onConfirm={() => mutation.mutate(record.id)}
							okText={t('general.yes')}
							cancelText={t('general.no')}
						>
							<Button type='link' icon={<DeleteOutlined />} danger>
								{t('general.delete')}
							</Button>
						</Popconfirm>
					)}
				</Space>
			)
		}
	]

	return (
		<>
			<HeaderCard
				title={t('user.label')}
				onSearch={(value) => {
					setSearch(value.length === 0 ? undefined : value)
				}}
				trailing={[
					can(CAN_CREATE_USER) ? (
						<Button
							type='primary'
							icon={<PlusOutlined />}
							onClick={() => navigate('/user/add')}
						>
							{t('general.add')}
						</Button>
					) : null,
					can(CAN_EXPORT_USERS) ? (
						<>
							<Button
								type='default'
								icon={<FileExcelOutlined />}
								onClick={() => setExportModalVisible(true)}
							>
								{t('general.export_to_excel')}
							</Button>
							<ExportToExcelModal
								visible={exportModelVisible}
								setVisible={setExportModalVisible}
								exportUrl={'/users/export'}
								columns={[
									{ name: 'id', label: 'general.id' },
									{ name: 'status', label: 'user.status' },
									{ name: 'firstName', label: 'user.first_name' },
									{ name: 'lastName', label: 'user.last_name' },
									{ name: 'mobile', label: 'user.mobile' },
									{ name: 'email', label: 'user.email' },
									{ name: 'address', label: 'user.address' },
									{ name: 'createdAt', label: 'general.created_at' }
								]}
							/>
						</>
					) : null
				].filter((i) => i != null)}
				onFilterChange={(data) => {
					var filters: IFilter[] = []
					data.status &&
						filters.push({ name: 'status', operation: '=', value: data.status })
					data.role &&
						filters.push({
							name: 'role',
							operation: '=',
							value: data.role
						})
					setFilters(filters.length === 0 ? undefined : filters)
				}}
				filters={
					<Row style={{ padding: '0 1rem' }} gutter={16}>
						<Col span={6}>
							<Form.Item name='status' label={t('user.status')}>
								<Select
									options={[
										{
											label: t('user.active'),
											value: UserStatus.ACTIVE
										},
										{
											label: t('user.not_active'),
											value: UserStatus.NOT_ACTIVE
										}
									]}
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name='role' label={t('user.roles')}>
								<Select
									options={rolesQuery.data?.data?.data?.map((r) => {
										return {
											value: r.name,
											label: r.name
										}
									})}
									showSearch
								/>
							</Form.Item>
						</Col>
					</Row>
				}
			/>
			<Card bordered={false} bodyStyle={{ padding: 0 }}>
				<Table
					loading={query.isFetching}
					columns={columns}
					dataSource={query.data?.data.data}
					rowKey={(record) => record.id}
					style={{ overflowX: 'scroll' }}
					onChange={(_, __, sorter, ___) => {
						const { columnKey, order } = sorter as SorterResult<IUser>

						if (!order) {
							setSort(undefined)
						} else {
							setSort({
								name: columnKey as string,
								direction: order === 'ascend' ? 'asc' : 'desc'
							})
						}
					}}
					pagination={{
						current: page,
						pageSize: perPage,
						pageSizeOptions: [5, 10, 20, 50, 100],
						showSizeChanger: true,
						total: query.data?.data.total,
						position: ['bottomCenter'],
						onChange(page, pageSize) {
							setPage(page)
							setPerPage(pageSize)
						},
						showTotal: (total, range) => {
							return `${t('general.showing')} ${range[0]} - ${range[1]} ${t(
								'general.from'
							)} ${total} ${t('general.items')}`
						}
					}}
				/>
			</Card>
		</>
	)
}

export default UsersPage
