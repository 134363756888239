import React, { useContext, useState } from 'react'
import { Layout, Menu, MenuProps } from 'antd'
import {
	HomeOutlined,
	SettingOutlined,
	CarOutlined,
	EnvironmentOutlined,
	BarcodeOutlined,
	LockOutlined,
	NotificationOutlined,
	GiftOutlined,
	UserOutlined,
	UserSwitchOutlined,
	FlagOutlined,
	SwapOutlined,
	AppstoreOutlined,
	AimOutlined,
	DatabaseOutlined
} from '@ant-design/icons'
import cn from 'classnames'
import styles from './style.module.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import logo from '../../../../assets/images/logo.png'
import AuthContext from '../../../../contexts/auth-context/context'
import {
	CAN_VIEW_ACCIDENTS,
	CAN_VIEW_CAPACITIES,
	CAN_VIEW_CATEGORIES,
	CAN_VIEW_CITIES,
	CAN_VIEW_CLIENTS,
	CAN_VIEW_CLIENTS_WITH_TRASH,
	CAN_VIEW_CONFIG,
	CAN_VIEW_COUPONS,
	CAN_VIEW_DRIVERS,
	CAN_VIEW_DRIVERS_WITH_TRASH,
	CAN_VIEW_NOTIFICATION_PUBLIC,
	CAN_VIEW_PAYMENTS,
	CAN_VIEW_PERMISSIONS,
	CAN_VIEW_REQUESTS,
	CAN_VIEW_REWARDS,
	CAN_VIEW_ROLES,
	CAN_VIEW_USERS
} from '../../../../utils/rbac/permissions'

const { Sider: AntdSider } = Layout

type MenuItem = Required<MenuProps>['items'][number]

function getItem(
	path: React.Key,
	label: React.ReactNode,
	icon?: React.ReactNode,
	children?: MenuItem[]
): MenuItem {
	return {
		key: path,
		icon,
		children,
		label
	} as MenuItem
}

const Sider: React.FC = () => {
	const { t } = useTranslation()
	const { pathname } = useLocation()
	const navigate = useNavigate()
	const {
		actions: { can }
	} = useContext(AuthContext)

	const [collapsed, setCollapsed] = useState(false)

	const items: MenuItem[] = [
		null,
		getItem('/', t('sider.home'), <HomeOutlined />),
		getItem('/tracking', t('sider.tracking'), <AimOutlined />),
		can(CAN_VIEW_CONFIG)
			? getItem('/config', t('sider.config'), <SettingOutlined />)
			: null,
		can(CAN_VIEW_PERMISSIONS) || can(CAN_VIEW_ROLES) || can(CAN_VIEW_USERS)
			? getItem(
					'access-key',
					t('sider.access'),
					<LockOutlined />,
					[
						can(CAN_VIEW_PERMISSIONS)
							? getItem('/permission', t('sider.permission'))
							: null,
						can(CAN_VIEW_ROLES) ? getItem('/role', t('sider.role')) : null,
						can(CAN_VIEW_USERS) ? getItem('/user', t('sider.user')) : null
					].filter((i) => i != null)
			  )
			: null,
		can(CAN_VIEW_CITIES)
			? getItem('/city', t('sider.city'), <EnvironmentOutlined />)
			: null,
		can(CAN_VIEW_NOTIFICATION_PUBLIC)
			? getItem(
					'/public-notification',
					t('sider.public_notification'),
					<NotificationOutlined />
			  )
			: null,
		can(CAN_VIEW_CATEGORIES) || can(CAN_VIEW_CAPACITIES)
			? getItem(
					'vehicle-key',
					t('sider.vehicle'),
					<CarOutlined />,
					[
						can(CAN_VIEW_CATEGORIES)
							? getItem('/vehicle-category', t('sider.vehicle_category'))
							: null,
						can(CAN_VIEW_CAPACITIES)
							? getItem('/vehicle-capacity', t('sider.vehicle_capacity'))
							: null
					].filter((i) => i != null)
			  )
			: null,
		can(CAN_VIEW_CLIENTS)
			? getItem('/client', t('sider.client'), <UserOutlined />)
			: null,
		can(CAN_VIEW_CLIENTS_WITH_TRASH)
			? getItem('/client-trash', t('sider.deleted_clients'), <UserOutlined />)
			: null,
		can(CAN_VIEW_DRIVERS)
			? getItem('/driver', t('sider.driver'), <UserSwitchOutlined />)
			: null,
		can(CAN_VIEW_DRIVERS_WITH_TRASH)
			? getItem(
					'/driver-trash',
					t('sider.deleted_drivers'),
					<UserSwitchOutlined />
			  )
			: null,
		can(CAN_VIEW_PAYMENTS)
			? getItem('/payment', t('sider.payment'), <SwapOutlined />)
			: null,
		can(CAN_VIEW_REQUESTS)
			? getItem('/request', t('sider.request'), <AppstoreOutlined />)
			: null,
		can(CAN_VIEW_COUPONS)
			? getItem('coupon-key', t('sider.coupon'), <BarcodeOutlined />, [
					getItem('/coupon', t('sider.coupon_management')),
					getItem('/coupon-usage', t('sider.coupon_usage'))
			  ])
			: null,
		can(CAN_VIEW_REWARDS)
			? getItem('reward-key', t('sider.reward'), <GiftOutlined />, [
					getItem('/reward', t('sider.reward_management')),
					getItem('/reward-earning', t('sider.reward_earning'))
			  ])
			: null,
		can(CAN_VIEW_ACCIDENTS)
			? getItem('/accident', t('sider.accident'), <FlagOutlined />)
			: null
	].filter((i) => i != null)

	return (
		<AntdSider
			collapsible
			collapsed={collapsed}
			onCollapse={setCollapsed}
			className={cn(styles.sider)}
		>
			<div className={cn(styles.brandLogoContainer)}>
				<img className={cn(styles.brandLogo)} src={logo} alt='crane-logo' />
			</div>
			<Menu
				theme='dark'
				className={cn(styles.menu)}
				selectedKeys={[`/${pathname.split('/')[1]}`]}
				mode='inline'
				items={items}
				onSelect={(e) => {
					navigate(e.key)
				}}
			/>
		</AntdSider>
	)
}

export default Sider
