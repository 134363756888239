import { IUserLight } from './user'

export enum PublicNotificationTo {
  CLIENT = 1,
  DRIVER = 2,
  BOTH = 3,
}

export interface IPublicNotification {
  id: number
  title: string
  body: string
  to: PublicNotificationTo
  note?: string
}

export interface IPublicNotificationDetails extends IPublicNotification {
  createdAt?: Date
  createdBy?: IUserLight
  updatedAt?: Date
  updatedBy?: IUserLight
}

export interface IAddPublicNotification {
  title: string
  body: string
  to: PublicNotificationTo
  note?: string
}

export interface IEditPublicNotification {
  title: string
  body: string
  to: PublicNotificationTo
  note?: string
}
