import { Button, Card, Col, Form, Row, Select, Space, Table } from 'antd'
import { SorterResult } from 'antd/es/table/interface'
import axios from 'axios'
import dayjs from 'dayjs'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'

import { EyeOutlined, FileExcelOutlined } from '@ant-design/icons'

import BooleanTag from '../../components/boolean-tag'
import ExportToExcelModal from '../../components/common/export-to-excel-modal'
import HeaderCard from '../../components/common/header-card'
import { IAccident } from '../../models/accident'
import { IDriverLight } from '../../models/driver'
import { IPaginatedList } from '../../models/paginated-list'
import { IFilter, ISort } from '../../models/shared'
import {
	DATE_TIME_FORMAT,
	DEFAULT_PAGE_SIZE,
	DEFAULT_SORT_FUNCTION,
	INITIAL_PAGE,
	MAX_PAGE_SIZE
} from '../../utils/constants'

import type { ColumnsType } from 'antd/es/table'
import AuthContext from '../../contexts/auth-context/context'
import { CAN_EXPORT_ACCIDENTS } from '../../utils/rbac/permissions'
const AccidentsPage: React.FC = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const {
		actions: { can }
	} = useContext(AuthContext)

	const [page, setPage] = useState(INITIAL_PAGE)
	const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE)
	const [search, setSearch] = useState<string | undefined>()
	const [filters, setFilters] = useState<IFilter[]>()
	const [sort, setSort] = useState<ISort | undefined>()
	const [exportModelVisible, setExportModalVisible] = useState(false)

	const query = useQuery({
		queryKey: ['accidents', page, perPage, search, filters, sort],
		queryFn: async () => {
			return axios.get<IPaginatedList<IAccident>>('/accident', {
				params: {
					page,
					perPage,
					search,
					filters,
					orders: [sort]
				}
			})
		}
	})

	const driversQuery = useQuery({
		queryKey: ['drivers-light'],
		queryFn: async () => {
			return axios.get<IPaginatedList<IDriverLight>>('/drivers', {
				params: {
					minimal: 1,
					perPage: MAX_PAGE_SIZE
				}
			})
		}
	})

	const columns: ColumnsType<IAccident> = [
		{
			title: t('general.id'),
			dataIndex: 'id',
			key: 'id',
			render: (id) => <Link to={`/accident/${id}`}>{id}</Link>,
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('accident.driver'),
			dataIndex: 'driver'
		},
		{
			title: t('accident.date_time'),
			dataIndex: 'dateTime',
			key: 'dateTime',
			render: (dateTime) => dayjs(dateTime).format(DATE_TIME_FORMAT),
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('accident.physical_injury'),
			dataIndex: 'thereIsPhysicalInjury',
			render: (value) => <BooleanTag value={value} />
		},
		{
			title: t('accident.description'),
			dataIndex: 'description'
		},
		{
			title: t('accident.dealt'),
			dataIndex: 'hasBeenDealt',
			render: (value) => <BooleanTag value={value} />
		},
		{
			title: t('general.actions'),
			render: (_, record) => (
				<Space>
					<Button
						type='link'
						icon={<EyeOutlined />}
						onClick={() => navigate(`/accident/${record.id}`)}
					>
						{t('general.show')}
					</Button>
				</Space>
			)
		}
	]

	return (
		<>
			<HeaderCard
				title={t('accident.label')}
				onSearch={(value) => {
					setSearch(value.length === 0 ? undefined : value)
				}}
				trailing={[
					can(CAN_EXPORT_ACCIDENTS) ? (
						<>
							<Button
								type='default'
								icon={<FileExcelOutlined />}
								onClick={() => setExportModalVisible(true)}
							>
								{t('general.export_to_excel')}
							</Button>
							<ExportToExcelModal
								visible={exportModelVisible}
								setVisible={setExportModalVisible}
								exportUrl={'/accident/export'}
								columns={[
									{ name: 'id', label: 'general.id' },
									{
										name: 'thereIsPhysicalInjury',
										label: 'accident.physical_injury'
									},
									{
										name: 'hasBeenDealt',
										label: 'accident.dealt'
									},
									{ name: 'dateTime', label: 'accident.date_time' },
									{
										name: 'driver',
										label: 'accident.driver'
									},
									{ name: 'createdAt', label: 'general.created_at' }
								]}
							/>
						</>
					) : null
				].filter((i) => i != null)}
				onFilterChange={(data) => {
					var filters: IFilter[] = []
					data.driverId &&
						filters.push({
							name: 'driverId',
							operation: '=',
							value: data.driverId
						})
					data.thereIsPhysicalInjury != undefined &&
						filters.push({
							name: 'thereIsPhysicalInjury',
							operation: '=',
							value: data.thereIsPhysicalInjury
						})
					data.hasBeenDealt != undefined &&
						filters.push({
							name: 'hasBeenDealt',
							operation: '=',
							value: data.hasBeenDealt
						})
					setFilters(filters.length === 0 ? undefined : filters)
				}}
				filters={
					<Row style={{ padding: '0 1rem' }} gutter={16}>
						<Col span={6}>
							<Form.Item name='driverId' label={t('accident.driver')}>
								<Select
									loading={driversQuery.isLoading}
									options={driversQuery.data?.data.data.map((driver) => {
										return {
											label: `#${driver.id}) ${driver.firstName} ${driver.lastName}`,
											value: driver.id
										}
									})}
									showSearch
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								name='thereIsPhysicalInjury'
								label={t('accident.physical_injury')}
							>
								<Select
									options={[
										{ label: t('general.yes'), value: true },
										{ label: t('general.no'), value: false }
									]}
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name='hasBeenDealt' label={t('accident.dealt')}>
								<Select
									options={[
										{ label: t('general.yes'), value: true },
										{ label: t('general.no'), value: false }
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
				}
			/>
			<Card bordered={false} bodyStyle={{ padding: 0 }}>
				<Table
					loading={query.isFetching}
					columns={columns}
					dataSource={query.data?.data.data}
					rowKey={(record) => record.id}
					style={{ overflowX: 'scroll' }}
					onChange={(_, __, sorter, ___) => {
						const { columnKey, order } = sorter as SorterResult<IAccident>

						if (!order) {
							setSort(undefined)
						} else {
							setSort({
								name: columnKey as string,
								direction: order === 'ascend' ? 'asc' : 'desc'
							})
						}
					}}
					pagination={{
						current: page,
						pageSize: perPage,
						pageSizeOptions: [5, 10, 20, 50, 100],
						showSizeChanger: true,
						total: query.data?.data.total,
						position: ['bottomCenter'],
						onChange(page, pageSize) {
							setPage(page)
							setPerPage(pageSize)
						},
						showTotal: (total, range) => {
							return `${t('general.showing')} ${range[0]} - ${range[1]} ${t(
								'general.from'
							)} ${total} ${t('general.items')}`
						}
					}}
				/>
			</Card>
		</>
	)
}

export default AccidentsPage
