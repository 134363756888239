import { Button, Card, Checkbox, DatePicker, Form, Input, Select } from 'antd'
import React from 'react'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { CheckOutlined } from '@ant-design/icons'
import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { CouponStatus, IAddCoupon } from '../../../models/coupon'
import { DATE_FORMAT } from '../../../utils/constants'

const AddCouponPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (data: IAddCoupon) => {
      return axios.post('/coupon', data)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['coupons'] })
      queryClient.invalidateQueries({ queryKey: ['coupons-light'] })
      navigate(-1)
    },
  })

  const onSubmit = (data: any) => {
    mutation.mutate({
      name: {
        ku: data['name.ku'],
        ar: data['name.ar'],
        en: data['name.en'],
      },
      code: data['code'],
      value: data['value'],
      isLimit: data['isLimit'] ?? false,
      status: data['status'],
      maxOfUse: data['maxOfUse'],
      startDate: data['startDate'].format(DATE_FORMAT),
      endDate: data['endDate'].format(DATE_FORMAT),
      note: data['note'],
    })
  }

  return (
    <Form name='form_item_path' layout='vertical' onFinish={onSubmit}>
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('coupon.add')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Button
              type='default'
              disabled={mutation.isLoading}
              onClick={() => navigate(-1)}
            >
              {t('general.cancel')}
            </Button>
            <Button
              className={styles.saveButton}
              type='primary'
              htmlType='submit'
              icon={<CheckOutlined />}
              loading={mutation.isLoading}
            >
              {t('general.save')}
            </Button>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: '1rem' }}>
        <Form.Item
          name='name.ku'
          label={`${t('coupon.name')} (${t('language.kurdish')})`}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='name.ar'
          label={`${t('coupon.name')} (${t('language.arabic')})`}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='name.en'
          label={`${t('coupon.name')} (${t('language.english')})`}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='code'
          label={t('coupon.code')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='value'
          label={t('coupon.value')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input type='number' />
        </Form.Item>
        <Form.Item
          name='status'
          label={t('coupon.status')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Select
            options={[
              {
                label: t('coupon.active'),
                value: CouponStatus.ACTIVE,
              },
              {
                label: t('coupon.not_active'),
                value: CouponStatus.NOT_ACTIVE,
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name='isLimit'
          label={t('coupon.is_limit')}
          valuePropName='checked'
        >
          <Checkbox />
        </Form.Item>
        <Form.Item name='maxOfUse' label={t('coupon.max_of_use')}>
          <Input type='number' />
        </Form.Item>
        <Form.Item
          name='startDate'
          label={t('coupon.start_date')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <DatePicker style={{ display: 'block' }} format={DATE_FORMAT} />
        </Form.Item>
        <Form.Item
          name='endDate'
          label={t('coupon.end_date')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <DatePicker style={{ display: 'block' }} format={DATE_FORMAT} />
        </Form.Item>
        <Form.Item name='note' label={t('coupon.note')}>
          <Input.TextArea />
        </Form.Item>
      </Card>
    </Form>
  )
}

export default AddCouponPage
