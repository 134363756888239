import axios from 'axios'
import dayjs from 'dayjs'
import React from 'react'
import RewardGettingWayTag from '../../../components/enums/reward-getting-way-tag'
import RewardStatusTag from '../../../components/enums/reward-status-tag'
import styles from './style.module.css'
import { Button, Card, Descriptions, Spin } from 'antd'
import { DATE_TIME_FORMAT } from '../../../utils/constants'
import { EditOutlined, HistoryOutlined } from '@ant-design/icons'
import { IRewardDetails } from '../../../models/reward'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

const RewardDetailsPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { id } = useParams()

  const query = useQuery({
    queryKey: ['reward', id],
    queryFn: async () => {
      return axios.get<IRewardDetails>(`/reward/${id}`)
    },
  })

  const data = query.data?.data

  return (
    <div>
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('reward.details')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Button type='default' onClick={() => navigate(-1)}>
              {t('general.cancel')}
            </Button>
            <Button
              className={styles.saveButton}
              type='primary'
              icon={<EditOutlined />}
              onClick={() => navigate(`/reward/${id}/edit`)}
            >
              {t('general.edit')}
            </Button>
            <Button
              className={styles.saveButton}
              type='dashed'
              icon={<HistoryOutlined />}
              onClick={() => navigate(`/reward/${id}/history`)}
            >
              {t('general.history')}
            </Button>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: 0 }}>
        <Spin tip={t('general.loading')} spinning={query.isFetching}>
          <Descriptions bordered column={1}>
            <Descriptions.Item label={t('general.id')}>
              {data?.id}
            </Descriptions.Item>
            <Descriptions.Item label={t('reward.name')}>
              {data?.name}
            </Descriptions.Item>
            <Descriptions.Item label={t('reward.status')}>
              {data?.status && <RewardStatusTag status={data.status} />}
            </Descriptions.Item>
            <Descriptions.Item label={t('reward.month')}>
              {data?.month}
            </Descriptions.Item>
            <Descriptions.Item label={t('reward.year')}>
              {data?.year}
            </Descriptions.Item>
            <Descriptions.Item label={t('reward.way_getk_reward')}>
              {data?.wayGetReward && (
                <RewardGettingWayTag way={data.wayGetReward} />
              )}
            </Descriptions.Item>
            <Descriptions.Item label={t('reward.num_of_point')}>
              {data?.numOfPoint}
            </Descriptions.Item>
            <Descriptions.Item label={t('reward.num_of_rate')}>
              {data?.numOfRate}
            </Descriptions.Item>
            <Descriptions.Item label={t('reward.note')}>
              {data?.note}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.created_at')} span={1}>
              {dayjs(data?.createdAt).format(DATE_TIME_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.created_by')} span={2}>
              {data?.createdBy &&
                `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.updated_at')} span={1}>
              {dayjs(data?.updatedAt).format(DATE_TIME_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.updated_by')} span={2}>
              {data?.updatedBy &&
                `${data?.updatedBy?.firstName} ${data?.updatedBy?.lastName}`}
            </Descriptions.Item>
          </Descriptions>
        </Spin>
      </Card>
    </div>
  )
}

export default RewardDetailsPage
