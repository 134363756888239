import { Button, Card, Form, FormInstance, Input, Select, Spin } from 'antd'
import React, { useContext } from 'react'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { CheckOutlined } from '@ant-design/icons'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import {
	IEditRequest,
	IRequestDetails,
	RequestStatus
} from '../../../models/request'
import { IPaginatedList } from '../../../models/paginated-list'
import { IDriverLight } from '../../../models/driver'
import { MAX_PAGE_SIZE } from '../../../utils/constants'
import AuthContext from '../../../contexts/auth-context/context'
import {
	CAN_CHANGE_PRICE_REQUEST,
	CAN_SELECT_DRIVER_FOR_REQUEST
} from '../../../utils/rbac/permissions'

const EditRequestPage: React.FC = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const {
		actions: { can }
	} = useContext(AuthContext)

	const { id } = useParams()

	const formRef = React.useRef<FormInstance>(null)

	const queryClient = useQueryClient()

	const driversQuery = useQuery({
		queryKey: ['drivers-light'],
		queryFn: async () => {
			return axios.get<IPaginatedList<IDriverLight>>('/drivers', {
				params: {
					minimal: 1,
					perPage: MAX_PAGE_SIZE
				}
			})
		}
	})

	const query = useQuery({
		queryKey: ['request', id],
		queryFn: async () => {
			return axios.get<IRequestDetails>(`/request/${id}`)
		},
		onSuccess: (data) => {
			formRef.current?.setFieldsValue({
				driverId: data.data.driver?.id,
				status: data.data.status,
				price: data.data.price,
				notes: data.data.notes
			})
		},
		refetchOnWindowFocus: false
	})

	const mutation = useMutation({
		mutationFn: (data: IEditRequest) => {
			return axios.put(`/request/${id}`, data)
		},
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['requests'] })
			queryClient.invalidateQueries({ queryKey: ['request', id] })
			navigate(-1)
		}
	})

	const onSubmit = (data: any) => {
		mutation.mutate({
			driverId: data['driverId'],
			status: data['status'],
			price: data['price'],
			notes: data['notes']
		})
	}

	return (
		<Form
			name='form_item_path'
			layout='vertical'
			ref={formRef}
			onFinish={onSubmit}
		>
			<Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
				<div className={styles.headerCardTitle}>
					<h3>{t('request.edit')}</h3>
					<div className={styles.headerCardTitleTrailing}>
						<Button
							type='default'
							disabled={mutation.isLoading || query.isFetching}
							onClick={() => navigate(-1)}
						>
							{t('general.cancel')}
						</Button>
						<Button
							className={styles.saveButton}
							type='primary'
							htmlType='submit'
							icon={<CheckOutlined />}
							loading={mutation.isLoading}
							disabled={query.isFetching}
						>
							{t('general.save')}
						</Button>
					</div>
				</div>
			</Card>
			<Card bodyStyle={{ padding: '1rem' }}>
				<Spin tip={t('general.loading')} spinning={query.isFetching}>
					<Form.Item
						name='driverId'
						label={t('request.driver')}
						required
						rules={[{ required: true, message: t('validation.required') }]}
					>
						<Select
							loading={driversQuery.isLoading}
							options={driversQuery.data?.data.data.map((driver) => {
								return {
									label: `#${driver.id}) ${driver.firstName} ${driver.lastName}`,
									value: driver.id
								}
							})}
							showSearch
							disabled={!can(CAN_SELECT_DRIVER_FOR_REQUEST)}
						/>
					</Form.Item>
					<Form.Item
						name='status'
						label={t('request.status')}
						required
						rules={[{ required: true, message: t('validation.required') }]}
					>
						<Select
							options={[
								{
									label: t('request.suspended_by_driver'),
									value: RequestStatus.SUSPENDED_BY_DRIVER
								},
								{
									label: t('request.suspended_by_client'),
									value: RequestStatus.SUSPENDED_BY_CUSTOMER
								},
								{
									label: t('request.canceled_by_driver'),
									value: RequestStatus.CANCELED_BY_DRIVER
								},
								{
									label: t('request.canceled_by_client'),
									value: RequestStatus.CANCELED_BY_CUSTOMER
								},
								{
									label: t('request.approved'),
									value: RequestStatus.APPROVED
								},
								{
									label: t('request.on_the_way'),
									value: RequestStatus.ON_THE_WAY
								},
								{
									label: t('request.site_has_been_reached'),
									value: RequestStatus.SITE_HAS_BEEN_REACHED
								},
								{
									label: t('request.in_progress'),
									value: RequestStatus.IN_PROGRESS
								},
								{
									label: t('request.complete'),
									value: RequestStatus.COMPLETE
								},
								{
									label: t('request.closed'),
									value: RequestStatus.CLOSED
								}
							]}
						/>
					</Form.Item>
					<Form.Item
						name='price'
						label={t('request.price')}
						required
						rules={[{ required: true, message: t('validation.required') }]}
					>
						<Input type='number' disabled={!can(CAN_CHANGE_PRICE_REQUEST)} />
					</Form.Item>
					<Form.Item name='notes' label={t('request.notes')}>
						<Input.TextArea />
					</Form.Item>
				</Spin>
			</Card>
		</Form>
	)
}

export default EditRequestPage
