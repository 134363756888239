import { FC } from 'react'
import { Marker } from '@react-google-maps/api'

interface IProps {
  position: google.maps.LatLng | google.maps.LatLngLiteral
  icon?: string | google.maps.Icon | google.maps.Symbol
  onClick?: (e: google.maps.MapMouseEvent) => void
  draggable?: boolean
  onDragEnd?: ((e: google.maps.MapMouseEvent) => void) | undefined
}

const GMarker: FC<IProps> = (props) => {
  const { position, icon, onClick, onDragEnd, draggable = false } = props

  return (
    <Marker
      position={position}
      icon={icon}
      draggable={draggable}
      onDragEnd={onDragEnd}
      onClick={onClick}
    />
  )
}

export default GMarker
