import { EyeOutlined, PlusOutlined, FileExcelOutlined } from '@ant-design/icons'
import {
	Button,
	Card,
	Col,
	DatePicker,
	Form,
	Row,
	Select,
	Space,
	Table
} from 'antd'
import type { ColumnsType } from 'antd/es/table'
import axios from 'axios'
import dayjs from 'dayjs'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import PaymentMethodTag from '../../components/enums/payment-method-tag'
import PaymentTypeTag from '../../components/enums/payment-type-tag'
import { IPaginatedList } from '../../models/paginated-list'
import { IPayment, PaymentMethod, PaymentType } from '../../models/payment'
import {
	DATE_FORMAT,
	DATE_TIME_FORMAT,
	DEFAULT_PAGE_SIZE,
	DEFAULT_SORT_FUNCTION,
	INITIAL_PAGE,
	MAX_PAGE_SIZE
} from '../../utils/constants'
import HeaderCard from '../../components/common/header-card'
import { IFilter, ISort } from '../../models/shared'
import { SorterResult } from 'antd/es/table/interface'
import { IDriverLight } from '../../models/driver'
import ExportToExcelModal from '../../components/common/export-to-excel-modal'
import AuthContext from '../../contexts/auth-context/context'
import {
	CAN_CREATE_PAYMENT,
	CAN_EXPORT_PAYMENTS
} from '../../utils/rbac/permissions'

const PaymentsPage: React.FC = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const {
		actions: { can }
	} = useContext(AuthContext)

	const [page, setPage] = useState(INITIAL_PAGE)
	const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE)
	const [search, setSearch] = useState<string | undefined>()
	const [filters, setFilters] = useState<IFilter[]>()
	const [sort, setSort] = useState<ISort | undefined>()
	const [exportModelVisible, setExportModalVisible] = useState(false)

	const query = useQuery({
		queryKey: ['payments', page, perPage, search, filters, sort],
		queryFn: async () => {
			return axios.get<IPaginatedList<IPayment>>('/payment', {
				params: {
					page,
					perPage,
					search,
					filters,
					orders: [sort]
				}
			})
		}
	})

	const driversQuery = useQuery({
		queryKey: ['drivers-light'],
		queryFn: async () => {
			return axios.get<IPaginatedList<IDriverLight>>('/drivers', {
				params: {
					minimal: 1,
					perPage: MAX_PAGE_SIZE
				}
			})
		}
	})

	const columns: ColumnsType<IPayment> = [
		{
			title: t('general.id'),
			dataIndex: 'id',
			key: 'id',
			render: (id) => <Link to={`/payment/${id}`}>{id}</Link>,
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('payment.driver'),
			dataIndex: 'driver',
			key: 'driver.id',
			render: (driver) => `${driver.firstName} ${driver.lastName}`
		},
		{
			title: t('general.created_at'),
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (date) => dayjs(date).format(DATE_TIME_FORMAT),
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('payment.type'),
			dataIndex: 'type',
			key: 'type',
			render: (type) => <PaymentTypeTag type={type} />
		},
		{
			title: t('payment.value'),
			dataIndex: 'value',
			key: 'value',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('payment.method'),
			dataIndex: 'method',
			key: 'method',
			render: (method) => <PaymentMethodTag method={method} />
		},
		{
			title: t('payment.receiving_party'),
			dataIndex: 'receivingParty',
			key: 'receivingParty'
		},
		{
			title: t('general.created_by'),
			dataIndex: 'createdBy',
			key: 'createdBy',
			render: (createdBy) =>
				createdBy && `${createdBy?.firstName} ${createdBy?.firstName}`
		},
		{
			title: t('general.actions'),
			key: 'actions',
			render: (_, record) => (
				<Space>
					<Button
						type='link'
						icon={<EyeOutlined />}
						onClick={() => navigate(`/payment/${record.id}`)}
					>
						{t('general.show')}
					</Button>
				</Space>
			)
		}
	]

	return (
		<>
			<HeaderCard
				title={t('payment.label')}
				onSearch={(value) => {
					setSearch(value.length === 0 ? undefined : value)
				}}
				trailing={[
					can(CAN_CREATE_PAYMENT) ? (
						<Button
							type='primary'
							icon={<PlusOutlined />}
							onClick={() => navigate('/payment/add')}
						>
							{t('general.add')}
						</Button>
					) : null,
					can(CAN_EXPORT_PAYMENTS) ? (
						<>
							<Button
								type='default'
								icon={<FileExcelOutlined />}
								onClick={() => setExportModalVisible(true)}
							>
								{t('general.export_to_excel')}
							</Button>
							<ExportToExcelModal
								visible={exportModelVisible}
								setVisible={setExportModalVisible}
								exportUrl={'/payment/export'}
								columns={[
									{ name: 'id', label: 'general.id' },
									{ name: 'value', label: 'payment.value' },
									{ name: 'firstName', label: 'payment.driver_first_name' },
									{ name: 'lastName', label: 'payment.driver_last_name' },
									{ name: 'method', label: 'payment.method' },
									{ name: 'type', label: 'payment.type' },
									{ name: 'receivingParty', label: 'payment.receiving_party' },
									{ name: 'createdAt', label: 'general.created_at' }
								]}
							/>
						</>
					) : null
				].filter((i) => i != null)}
				onFilterChange={(data) => {
					var filters: IFilter[] = []
					data.driverId &&
						filters.push({
							name: 'driverId',
							operation: '=',
							value: data.driverId
						})
					data.createdAt &&
						filters.push({
							name: 'createdAt',
							operation: '=',
							value: data.createdAt.toISOString()
						})
					data.type &&
						filters.push({
							name: 'type',
							operation: '=',
							value: data.type
						})
					data.method &&
						filters.push({
							name: 'method',
							operation: '=',
							value: data.method
						})
					setFilters(filters.length === 0 ? undefined : filters)
				}}
				filters={
					<Row style={{ padding: '0 1rem' }} gutter={16}>
						<Col span={6}>
							<Form.Item name='driverId' label={t('payment.driver')}>
								<Select
									loading={driversQuery.isLoading}
									options={driversQuery.data?.data.data.map((driver) => {
										return {
											label: `#${driver.id}) ${driver.firstName} ${driver.lastName}`,
											value: driver.id
										}
									})}
									showSearch
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name='createdAt' label={t('general.created_at')}>
								<DatePicker style={{ width: '100%' }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name='type' label={t('payment.type')}>
								<Select
									options={[
										{
											label: t('payment.incoming'),
											value: PaymentType.INCOMING
										},
										{
											label: t('payment.outgoing'),
											value: PaymentType.OUTGOING
										}
									]}
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name='method' label={t('payment.method')}>
								<Select
									options={[
										{
											label: t('payment.electronic'),
											value: PaymentMethod.ELECTRONIC
										},
										{
											label: t('payment.deposit'),
											value: PaymentMethod.DEPOSIT
										},
										{
											label: t('payment.coupon'),
											value: PaymentMethod.COUPON
										},
										{
											label: t('payment.reward'),
											value: PaymentMethod.REWARD
										},
										{
											label: t('payment.manual'),
											value: PaymentMethod.MANUAL
										},
										{
											label: t('payment.request'),
											value: PaymentMethod.REQUEST
										}
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
				}
			/>
			<Card bordered={false} bodyStyle={{ padding: 0 }}>
				<Table
					loading={query.isFetching}
					columns={columns}
					dataSource={query.data?.data.data}
					rowKey={(record) => record.id}
					style={{ overflowX: 'scroll' }}
					onChange={(_, __, sorter, ___) => {
						const { columnKey, order } = sorter as SorterResult<IPayment>

						if (!order) {
							setSort(undefined)
						} else {
							setSort({
								name: columnKey as string,
								direction: order === 'ascend' ? 'asc' : 'desc'
							})
						}
					}}
					pagination={{
						current: page,
						pageSize: perPage,
						pageSizeOptions: [5, 10, 20, 50, 100],
						showSizeChanger: true,
						total: query.data?.data.total,
						position: ['bottomCenter'],
						onChange(page, pageSize) {
							setPage(page)
							setPerPage(pageSize)
						},
						showTotal: (total, range) => {
							return `${t('general.showing')} ${range[0]} - ${range[1]} ${t(
								'general.from'
							)} ${total} ${t('general.items')}`
						}
					}}
				/>
			</Card>
		</>
	)
}

export default PaymentsPage
