import { Bar } from 'react-chartjs-2'
import { Card, Col, Row, Spin } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
	IDriverWithRequest,
	IFirstStatisticsFilter
} from '../../../../models/home'
import RangePickerPopover from '../../rang-picker-popover'

interface IProps {
	data: IDriverWithRequest[]
	loading: boolean
	firstQueryFilter?: IFirstStatisticsFilter
	setFirstQueryFilter?: (filter: IFirstStatisticsFilter) => void
}
const DriverWithRequestChart: FC<IProps> = (props) => {
	const { t } = useTranslation()
	const { firstQueryFilter, setFirstQueryFilter } = props

	// Convert data into chart data
	const labels = props.data.map((_, index) => `${index + 1}`)
	const requestCounts = props.data.map((item) => item.request_count)
	const driverCounts = props.data.map((item) => item.driver_count)

	const data = {
		labels: labels,
		datasets: [
			{
				label: t('home.request_count'),
				data: requestCounts,
				backgroundColor: 'rgba(255, 99, 132, 0.5)'
			},
			{
				label: t('home.driver_count'),
				data: driverCounts,
				backgroundColor: 'rgba(53, 162, 235, 0.5)'
			}
		]
	}

	const options = {
		responsive: true
	}

	return (
		<Spin spinning={props.loading}>
			<Card
				title={
					<Row gutter={[16, 16]} justify={'space-between'} align={'middle'}>
						<Col>{t('home.driver_vs_request_analysis')}</Col>

						<Col>
							<RangePickerPopover
								onChange={(fromDate, toDate) => {
									setFirstQueryFilter &&
										setFirstQueryFilter({
											...firstQueryFilter,
											'driversWithRequest[start]': fromDate,
											'driversWithRequest[end]': toDate
										})
								}}
							/>
						</Col>
					</Row>
				}
				headStyle={{
					textAlign: 'center'
				}}
				bodyStyle={{
					height: '20rem'
				}}
			>
				<Bar data={data} options={options} />
			</Card>
		</Spin>
	)
}

export default DriverWithRequestChart
