import axios from 'axios'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'
import styles from './style.module.css'
import { ACCESS_TOKEN_KEY, TIME_FORMAT } from '../../utils/constants'
import { CheckOutlined } from '@ant-design/icons'
import { currencyOptions } from '../../utils/helpers/currencies'
import { IConfig } from '../../models/config'
import { IPaginatedList } from '../../models/paginated-list'
import { PlusOutlined } from '@ant-design/icons'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
	Button,
	Card,
	Form,
	FormInstance,
	Input,
	Select,
	Spin,
	TimePicker,
	Image,
	Upload,
	Row,
	Col,
	Switch
} from 'antd'
import AuthContext from '../../contexts/auth-context/context'
import { CAN_UPDATE_CONFIG } from '../../utils/rbac/permissions'

const arrayRange = (start: number, stop: number, step: number) =>
	Array.from(
		{ length: (stop - start) / step + 1 },
		(value, index) => start + index * step
	)

const ConfigPage: React.FC = () => {
	const { t } = useTranslation()

	const {
		actions: { can }
	} = useContext(AuthContext)

	const { id } = useParams()

	const formRef = React.useRef<FormInstance>(null)

	const queryClient = useQueryClient()

	const query = useQuery({
		queryKey: ['config', id],
		queryFn: async () => {
			const response = await axios.get<IPaginatedList<IConfig>>('/config')
			return response.data.data[0]
		},
		onSuccess: (data) => {
			formRef.current?.setFieldsValue({
				...data,
				rushTimeHours: [
					dayjs(`1900-01-01 ${data.startRushTime}:00`),
					dayjs(`1900-01-01 ${data.endRushTime}:00`)
				],
				dailyBackupTime: dayjs(`1900-01-01 ${data.dailyBackupTime}:00`),
				monthlyBackupTime: dayjs(`1900-01-01 ${data.monthlyBackupTime}:00`),
				yearlyBackupTime: dayjs(`1900-01-01 ${data.yearlyBackupTime}:00`)
			})
		},
		refetchOnWindowFocus: false
	})

	const mutation = useMutation({
		mutationFn: (data: IConfig) => {
			return axios.put(`/config/1`, data)
		},
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['config'] })
		}
	})

	const onSubmit = (data: any) => {
		if (!can(CAN_UPDATE_CONFIG)) return

		console.log(data)

		mutation.mutate({
			...data,
			logoId: logoIdValue,
			rushTimePercentage: isRushTimeChecked
				? data['rushTimePercentage']
				: undefined,
			startRushTime: isRushTimeChecked
				? data['rushTimeHours'][0]?.format(TIME_FORMAT)
				: undefined,
			endRushTime: isRushTimeChecked
				? data['rushTimeHours'][1]?.format(TIME_FORMAT)
				: undefined,
			dailyBackupTime: isDailyBackupChecked
				? data['dailyBackupTime']?.format(TIME_FORMAT)
				: undefined,
			monthlyBackupTime: isMonthlyBackupChecked
				? data['monthlyBackupTime']?.format(TIME_FORMAT)
				: undefined,
			yearlyBackupTime: isYearlyBackupChecked
				? data['yearlyBackupTime']?.format(TIME_FORMAT)
				: undefined
		})
	}

	const [isRushTimeChecked, setIsRushTimeChecked] = useState<boolean>(false)

	const [isDailyBackupChecked, setIsDailyBackupChecked] = useState(false)
	const [isMonthlyBackupChecked, setIsMonthlyBackupChecked] = useState(false)
	const [isYearlyBackupChecked, setIsYearlyBackupChecked] = useState(false)

	useEffect(() => {
		setIsRushTimeChecked(query.data?.rushTime ?? false)

		setIsDailyBackupChecked(query.data?.dailyBackupEnabled ?? false)
		setIsMonthlyBackupChecked(query.data?.monthlyBackupEnabled ?? false)
		setIsYearlyBackupChecked(query.data?.yearlyBackupEnabled ?? false)
	}, [query.data])

	// Uploaded Image state
	const [uploadedFileResponse, setUploadedFileResponse] = useState<any>()
	const [loading, setLoading] = useState(false)
	const [logoIdValue, setLogoIdValue] = useState<number>()

	useEffect(() => {
		setLogoIdValue(uploadedFileResponse?.id ?? query.data?.logo?.id)
	}, [query.data, uploadedFileResponse])

	return (
		<Form
			name='form_item_path'
			layout='vertical'
			ref={formRef}
			onFinish={onSubmit}
		>
			<Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
				<div className={styles.headerCardTitle}>
					<h3>{t('config.label')}</h3>
					<div className={styles.headerCardTitleTrailing}>
						{can(CAN_UPDATE_CONFIG) && (
							<Button
								className={styles.saveButton}
								type='primary'
								htmlType='submit'
								icon={<CheckOutlined />}
								loading={mutation.isLoading}
								disabled={query.isFetching}
							>
								{t('general.save')}
							</Button>
						)}
					</div>
				</div>
			</Card>
			<Spin tip={t('general.loading')} spinning={query.isFetching}>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Card
							title={
								<Row>
									<Col
										style={{
											display: 'flex',
											alignItems: 'center'
										}}
									>
										{t('config.info')}
									</Col>
								</Row>
							}
						>
							<Row gutter={[16, 16]}>
								<Col span={12}>
									<Form.Item
										name='email'
										label={t('config.email')}
										required
										rules={[
											{ required: true, message: t('validation.required') },
											{ type: 'email', message: t('validation.email') }
										]}
									>
										<Input />
									</Form.Item>
									<Form.Item
										name='firstPhone'
										label={t('config.first_phone')}
										required
										rules={[
											{ required: true, message: t('validation.required') }
										]}
									>
										<Input />
									</Form.Item>
									<Form.Item
										name='secondPhone'
										label={t('config.second_phone')}
										required
										rules={[
											{ required: true, message: t('validation.required') }
										]}
									>
										<Input />
									</Form.Item>
									<Form.Item
										name='requestOrientationMargin'
										label={t('config.request_orientation_margin')}
										required
										rules={[
											{ required: true, message: t('validation.required') }
										]}
									>
										<Input type='number' addonAfter={t('general.minute')} />
									</Form.Item>
									<Form.Item
										name='requestStartMargin'
										label={t('config.request_start_margin')}
										required
										rules={[
											{ required: true, message: t('validation.required') }
										]}
									>
										<Input type='number' addonAfter={t('general.minute')} />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name='thirdPhone'
										label={t('config.third_phone')}
										required
										rules={[
											{ required: true, message: t('validation.required') }
										]}
									>
										<Input />
									</Form.Item>
									<Form.Item
										name='percentage'
										label={t('config.percentage')}
										required
										rules={[
											{ required: true, message: t('validation.required') },
											{
												validator: (_, value, callback) => {
													if (value < 0 || value > 100) {
														callback(t('validation.percentage'))
													} else {
														callback()
													}
												}
											}
										]}
									>
										<Input type='number' addonAfter={'%'} />
									</Form.Item>

									<Form.Item
										name='currency'
										label={t('config.currency')}
										required
										rules={[
											{ required: true, message: t('validation.required') }
										]}
									>
										<Select options={currencyOptions} />
									</Form.Item>

									<Form.Item
										name='minimumWallet'
										label={t('config.minimum_wallet')}
										required
										rules={[
											{ required: true, message: t('validation.required') }
										]}
									>
										<Input type='number' />
									</Form.Item>
								</Col>
							</Row>
						</Card>
					</Col>

					<Col span={24}>
						<Card
							title={
								<Row gutter={[16, 16]}>
									<Col>
										<Form.Item
											name='rushTime'
											valuePropName='checked'
											style={{
												display: 'inline'
											}}
										>
											<Switch
												onChange={(e) => {
													setIsRushTimeChecked(e)
												}}
											/>
										</Form.Item>
									</Col>
									<Col
										style={{
											display: 'flex',
											alignItems: 'center'
										}}
									>
										{t('config.rush_time')}
									</Col>
								</Row>
							}
						>
							<Row gutter={[16, 16]}>
								<Col span={12}>
									<Form.Item
										name='rushTimePercentage'
										label={t('config.rush_time_percentage')}
										required={isRushTimeChecked}
										rules={[
											{
												required: isRushTimeChecked,
												message: t('validation.required')
											},
											{
												validator: (_, value, callback) => {
													if (value < 0 || value > 100) {
														callback(t('validation.percentage'))
													} else {
														callback()
													}
												}
											}
										]}
									>
										<Input
											type='number'
											addonAfter={'%'}
											disabled={!isRushTimeChecked}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name='rushTimeHours'
										label={t('config.rush_time_hours')}
										rules={[
											{
												required: isRushTimeChecked,
												message: t('validation.required')
											}
										]}
									>
										<TimePicker.RangePicker
											disabled={!isRushTimeChecked}
											// style={{ width: '100%' }}
											format={TIME_FORMAT}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Card>
					</Col>
					<Col span={24}>
						<Card
							title={
								<Row gutter={[16, 16]}>
									<Col>
										<Form.Item
											name='dailyBackupEnabled'
											valuePropName='checked'
											style={{
												display: 'inline'
											}}
										>
											<Switch
												onChange={(e) => {
													setIsDailyBackupChecked(e)
												}}
											/>
										</Form.Item>
									</Col>
									<Col
										style={{
											display: 'flex',
											alignItems: 'center'
										}}
									>
										{t('config.daily_backup_enabled')}
									</Col>
								</Row>
							}
						>
							<Form.Item
								name='dailyBackupTime'
								label={t('config.daily_backup_time')}
								required={isDailyBackupChecked}
								rules={[
									{
										required: isDailyBackupChecked,
										message: t('validation.required')
									}
								]}
							>
								<TimePicker
									disabled={!isDailyBackupChecked}
									format={TIME_FORMAT}
								/>
							</Form.Item>
						</Card>
					</Col>
					<Col span={24}>
						<Card
							title={
								<Row gutter={[16, 16]}>
									<Col>
										<Form.Item
											name='monthlyBackupEnabled'
											// label={t('config.monthly_backup_enabled')}
											valuePropName='checked'
											style={{
												display: 'inline'
											}}
										>
											<Switch
												onChange={(e) => {
													setIsMonthlyBackupChecked(e)
												}}
											/>
										</Form.Item>
									</Col>
									<Col
										style={{
											display: 'flex',
											alignItems: 'center'
										}}
									>
										{t('config.monthly_backup_enabled')}
									</Col>
								</Row>
							}
						>
							<Row gutter={[16, 16]}>
								<Col span={12}>
									<Form.Item
										name='monthlyBackupDay'
										label={t('config.monthly_backup_day')}
										required={isMonthlyBackupChecked}
										rules={[
											{
												required: isMonthlyBackupChecked,
												message: t('validation.required')
											}
										]}
									>
										<Select
											disabled={!isMonthlyBackupChecked}
											options={arrayRange(1, 31, 1).map((e) => {
												return {
													label: e,
													value: e
												}
											})}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name='monthlyBackupTime'
										label={t('config.monthly_backup_time')}
										required={isMonthlyBackupChecked}
										rules={[
											{
												required: isMonthlyBackupChecked,
												message: t('validation.required')
											}
										]}
									>
										<TimePicker
											format={TIME_FORMAT}
											disabled={!isMonthlyBackupChecked}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Card>
					</Col>
					<Col span={24}>
						<Card
							title={
								<Row gutter={[16, 16]}>
									<Col>
										<Form.Item
											name='yearlyBackupEnabled'
											// label={t('config.yearly_backup_enabled')}
											valuePropName='checked'
											style={{
												display: 'inline'
											}}
										>
											<Switch
												onChange={(e) => {
													setIsYearlyBackupChecked(e)
												}}
											/>
										</Form.Item>
									</Col>
									<Col
										style={{
											display: 'flex',
											alignItems: 'center'
										}}
									>
										{t('config.yearly_backup_enabled')}
									</Col>
								</Row>
							}
						>
							<Row gutter={[16, 16]}>
								<Col span={12}>
									<Form.Item
										name='yearlyBackupMonth'
										label={t('config.yearly_backup_month')}
										required={isYearlyBackupChecked}
										rules={[
											{
												required: isYearlyBackupChecked,
												message: t('validation.required')
											}
										]}
									>
										<Select
											disabled={!isYearlyBackupChecked}
											options={arrayRange(1, 12, 1).map((e) => {
												return {
													label: e,
													value: e
												}
											})}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name='yearlyBackupDay'
										label={t('config.yearly_backup_day')}
										required={isYearlyBackupChecked}
										rules={[
											{
												required: isYearlyBackupChecked,
												message: t('validation.required')
											}
										]}
									>
										<Select
											disabled={!isYearlyBackupChecked}
											options={arrayRange(1, 31, 1).map((e) => {
												return {
													label: e,
													value: e
												}
											})}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name='yearlyBackupTime'
										label={t('config.yearly_backup_time')}
										required={isYearlyBackupChecked}
										rules={[
											{
												required: isYearlyBackupChecked,
												message: t('validation.required')
											}
										]}
									>
										<TimePicker
											format={TIME_FORMAT}
											disabled={!isYearlyBackupChecked}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Card>
					</Col>
					<Col span={24}>
						<Card
							title={
								<Row>
									<Col
										style={{
											display: 'flex',
											alignItems: 'center'
										}}
									>
										{t('config.company_logo')}
									</Col>
								</Row>
							}
						>
							<Form.Item
								name='logoId'
								// label={t('config.company_logo')}
								rules={[
									{
										required: !logoIdValue,
										message: t('validation.required')
									}
								]}
								getValueFromEvent={(e) => {
									return logoIdValue
								}}
							>
								<div
									style={{
										display: 'flex',
										justifyItems: 'center'
									}}
								>
									{query.data?.logo.url && (
										<div className={styles.logoContainer}>
											{loading ? (
												<div>{t('general.loading')}</div>
											) : (
												<Image
													src={
														uploadedFileResponse?.url ?? query.data?.logo.url
													}
													alt='company-logo'
													height={'100%'}
													style={{
														objectFit: 'contain'
													}}
												/>
											)}
										</div>
									)}

									<Upload
										action={`${process.env.REACT_APP_BASE_API_URL}/media`}
										headers={{
											Authorization: `Bearer ${localStorage.getItem(
												ACCESS_TOKEN_KEY
											)}`
										}}
										listType='picture-card'
										showUploadList={false}
										maxCount={1}
										beforeUpload={() => {
											setLoading(true)
										}}
										onChange={(info) => {
											if (info.file.status === 'done') {
												setUploadedFileResponse(info.file.response)
												setLoading(false)
											}
										}}
										data={async (file) => {
											return {
												medium: file,
												for: 'user-profile'
											}
										}}
									>
										<div>
											<PlusOutlined />
											<div style={{ marginTop: 8 }}>{t('general.upload')}</div>
										</div>
									</Upload>
								</div>
							</Form.Item>
						</Card>
					</Col>
				</Row>
			</Spin>
		</Form>
	)
}

export default ConfigPage
