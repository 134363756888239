import { Tag } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { PaymentMethod } from '../../../models/payment'

interface IProps {
	method: PaymentMethod
}

const PaymentMethodTag: React.FC<IProps> = ({ method }) => {
	const { t } = useTranslation()

	let label = ''

	switch (method) {
		case PaymentMethod.ELECTRONIC:
			label = t('payment.electronic')
			break
		case PaymentMethod.DEPOSIT:
			label = t('payment.deposit')
			break
		case PaymentMethod.COUPON:
			label = t('payment.coupon')
			break
		case PaymentMethod.REWARD:
			label = t('payment.reward')
			break
		case PaymentMethod.MANUAL:
			label = t('payment.manual')
			break
		case PaymentMethod.REQUEST:
			label = t('payment.request')
			break
	}

	return <Tag>{label}</Tag>
}

export default PaymentMethodTag
