import React from 'react'
import { Card, Button, Descriptions, Spin } from 'antd'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import axios from 'axios'
import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '../../../utils/constants'
import { IPaymentDetails } from '../../../models/payment'
import PaymentTypeTag from '../../../components/enums/payment-type-tag'
import PaymentMethodTag from '../../../components/enums/payment-method-tag'

const PaymentDetailsPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { id } = useParams()

  const query = useQuery({
    queryKey: ['payment', id],
    queryFn: async () => {
      return axios.get<IPaymentDetails>(`/payment/${id}`)
    },
  })

  const data = query.data?.data

  return (
    <div>
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('payment.details')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Button type='default' onClick={() => navigate(-1)}>
              {t('general.cancel')}
            </Button>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: 0 }}>
        <Spin tip={t('general.loading')} spinning={query.isFetching}>
          <Descriptions bordered column={1}>
            <Descriptions.Item label={t('general.id')}>
              {data?.id}
            </Descriptions.Item>
            <Descriptions.Item label={t('payment.driver')}>
              {data?.driver &&
                `${data.driver.firstName} ${data.driver.firstName}`}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.created_at')}>
              {dayjs(data?.createdAt).format(DATE_TIME_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label={t('payment.type')}>
              {data?.type && <PaymentTypeTag type={data.type} />}
            </Descriptions.Item>
            <Descriptions.Item label={t('payment.value')}>
              {data?.value}
            </Descriptions.Item>
            <Descriptions.Item label={t('payment.method')}>
              {data?.method && <PaymentMethodTag method={data.method} />}
            </Descriptions.Item>
            <Descriptions.Item label={t('payment.receiving_party')}>
              {data?.receivingParty}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.created_by')}>
              {data?.createdBy &&
                `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`}
            </Descriptions.Item>
            <Descriptions.Item label={t('payment.note')}>
              {data?.note}
            </Descriptions.Item>
          </Descriptions>
        </Spin>
      </Card>
    </div>
  )
}

export default PaymentDetailsPage
