import { Button, Card, Form, Input, Select } from 'antd'
import React from 'react'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { CheckOutlined } from '@ant-design/icons'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { IAddRole } from '../../../models/role'
import { IPermissionLight } from '../../../models/permission'
import { IPaginatedList } from '../../../models/paginated-list'
import { MAX_PAGE_SIZE } from '../../../utils/constants'

const AddRolePage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const permissionsQuery = useQuery({
    queryKey: ['permissions-light'],
    queryFn: async () => {
      return axios.get<IPaginatedList<IPermissionLight>>('/permissions', {
        params: {
          minimal: 1,
          perPage: MAX_PAGE_SIZE,
        },
      })
    },
  })

  const mutation = useMutation({
    mutationFn: (data: IAddRole) => {
      return axios.post('/roles', data)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['roles'] })
      queryClient.invalidateQueries({ queryKey: ['roles-light'] })
      navigate(-1)
    },
  })

  const onSubmit = (data: any) => {
    mutation.mutate({
      name: data['name'],
      permissions: data['permissions'],
      note: data['note'],
    })
  }

  return (
    <Form name="form_item_path" layout="vertical" onFinish={onSubmit}>
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('role.add')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Button
              type="default"
              disabled={mutation.isLoading}
              onClick={() => navigate(-1)}
            >
              {t('general.cancel')}
            </Button>
            <Button
              className={styles.saveButton}
              type="primary"
              htmlType="submit"
              icon={<CheckOutlined />}
              loading={mutation.isLoading}
            >
              {t('general.save')}
            </Button>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: '1rem' }}>
        <Form.Item
          name="name"
          label={t('role.name')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="permissions"
          label={t('role.permissions')}
          required
          rules={[{ required: true, message: t('validation.required') }]}
        >
          <Select
            mode="multiple"
            options={permissionsQuery.data?.data?.data?.map((p) => {
              return {
                value: p.id,
                label: p.name,
              }
            })}
          />
        </Form.Item>
        <Form.Item name="note" label={t('role.note')}>
          <Input.TextArea />
        </Form.Item>
      </Card>
    </Form>
  )
}

export default AddRolePage
