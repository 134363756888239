import React from 'react'
import { Card, Button, Descriptions, Spin } from 'antd'
import { EditOutlined, HistoryOutlined } from '@ant-design/icons'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import axios from 'axios'
import { ICityDetails } from '../../../models/city'
import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '../../../utils/constants'

const CityDetailsPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { id } = useParams()

  const query = useQuery({
    queryKey: ['city', id],
    queryFn: async () => {
      return axios.get<ICityDetails>(`/city/${id}`)
    },
  })

  const data = query.data?.data

  return (
    <div>
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('city.details')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Button type='default' onClick={() => navigate(-1)}>
              {t('general.cancel')}
            </Button>
            <Button
              className={styles.saveButton}
              type='primary'
              icon={<EditOutlined />}
              onClick={() => navigate(`/city/${id}/edit`)}
            >
              {t('general.edit')}
            </Button>
            <Button
              className={styles.saveButton}
              type='dashed'
              icon={<HistoryOutlined />}
              onClick={() => navigate(`/city/${id}/history`)}
            >
              {t('general.history')}
            </Button>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: 0 }}>
        <Spin tip={t('general.loading')} spinning={query.isFetching}>
          <Descriptions bordered column={1}>
            <Descriptions.Item label={t('general.id')} span={1}>
              {data?.id}
            </Descriptions.Item>
            <Descriptions.Item label={t('city.name')} span={2}>
              {data?.name}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.created_at')} span={1}>
              {dayjs(data?.createdAt).format(DATE_TIME_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.created_by')} span={2}>
              {data?.createdBy &&
                `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.updated_at')} span={1}>
              {dayjs(data?.updatedAt).format(DATE_TIME_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.updated_by')} span={2}>
              {data?.updatedBy &&
                `${data?.updatedBy?.firstName} ${data?.updatedBy?.lastName}`}
            </Descriptions.Item>
          </Descriptions>
        </Spin>
      </Card>
    </div>
  )
}

export default CityDetailsPage
