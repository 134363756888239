import { IClientLight } from './client'
import { IDriverLight } from './driver'
import { IVehicleCategoryLight } from './vehicle-category'

export enum RequestStatus {
	SUSPENDED_BY_DRIVER = 1,
	SUSPENDED_BY_CUSTOMER = 2,
	APPROVED = 3,
	ON_THE_WAY = 4,
	SITE_HAS_BEEN_REACHED = 5,
	IN_PROGRESS = 6,
	COMPLETE = 7,
	CLOSED = 8,
	CANCELED_BY_DRIVER = 9,
	CANCELED_BY_CUSTOMER = 10
}

export enum DurationType {
	QUICKLY = 1,
	SCHEDULER = 2
}

export interface IRequest {
	id: number
	client: IClientLight
	category: IVehicleCategoryLight
	driver?: IDriverLight
	status: RequestStatus
	durationType: DurationType
	scheduledDate?: Date
	createdAt: Date
	price?: number
	percent: number
}

export interface IRequestDetails extends IRequest {
	notes?: string
	weight?: number
	requiredDuration?: number
	description?: string
	estimatedDuration?: any
	rate?: number
	startLong: number
	startLat: number
	endLong: number
	endLat: number
}

export interface IEditRequest {
	driverId: number
	status: RequestStatus
	price?: number
	notes?: string
}
