import React, { useContext, useState } from 'react'
import { Space, Table, Card, Button, Popconfirm } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import {
	PlusOutlined,
	EyeOutlined,
	EditOutlined,
	DeleteOutlined,
	FileExcelOutlined
} from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import { IVehicleCapacity } from '../../models/vehicle-capacity'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { IPaginatedList } from '../../models/paginated-list'
import {
	DEFAULT_PAGE_SIZE,
	DEFAULT_SORT_FUNCTION,
	INITIAL_PAGE
} from '../../utils/constants'
import axios from 'axios'
import HeaderCard from '../../components/common/header-card'
import { ISort } from '../../models/shared'
import { SorterResult } from 'antd/es/table/interface'
import ExportToExcelModal from '../../components/common/export-to-excel-modal'
import AuthContext from '../../contexts/auth-context/context'
import {
	CAN_CREATE_CAPACITY,
	CAN_DELETE_CAPACITY,
	CAN_EXPORT_CAPACITIES,
	CAN_UPDATE_CAPACITY
} from '../../utils/rbac/permissions'

const VehicleCapacitiesPage: React.FC = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const {
		actions: { can }
	} = useContext(AuthContext)

	const queryClient = useQueryClient()

	const [page, setPage] = useState(INITIAL_PAGE)
	const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE)
	const [search, setSearch] = useState<string | undefined>()
	const [sort, setSort] = useState<ISort | undefined>()
	const [exportModelVisible, setExportModalVisible] = useState(false)

	const query = useQuery({
		queryKey: ['vehicle-capacities', page, perPage, search, sort],
		queryFn: async () => {
			return axios.get<IPaginatedList<IVehicleCapacity>>('/capacity', {
				params: {
					page,
					perPage,
					search,
					orders: [sort]
				}
			})
		}
	})

	const mutation = useMutation({
		mutationFn: (id: number) => {
			return axios.delete(`/capacity/${id}`)
		},
		onSuccess: (_, id) => {
			if (query.data?.data.data.length === 1) {
				setPage(INITIAL_PAGE)
			}
			queryClient.invalidateQueries({ queryKey: ['vehicle-capacities'] })
			queryClient.invalidateQueries({ queryKey: ['vehicle-capacities-light'] })
			queryClient.invalidateQueries({ queryKey: ['vehicle-capacity', id] })
		}
	})

	const columns: ColumnsType<IVehicleCapacity> = [
		{
			title: t('general.id'),
			dataIndex: 'id',
			key: 'id',
			render: (id) => <Link to={`/vehicle-capacity/${id}`}>{id}</Link>,
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('vehicle_capacity.name'),
			dataIndex: 'name',
			key: 'name',
			sorter: DEFAULT_SORT_FUNCTION
		},
		{
			title: t('vehicle_capacity.note'),
			dataIndex: 'note',
			key: 'note'
		},
		{
			title: t('general.actions'),
			key: 'actions',
			render: (_, record) => (
				<Space>
					<Button
						type='link'
						icon={<EyeOutlined />}
						onClick={() => navigate(`/vehicle-capacity/${record.id}`)}
					>
						{t('general.show')}
					</Button>
					{can(CAN_UPDATE_CAPACITY) && (
						<Button
							type='link'
							icon={<EditOutlined />}
							onClick={() => navigate(`/vehicle-capacity/${record.id}/edit`)}
						>
							{t('general.edit')}
						</Button>
					)}
					{can(CAN_DELETE_CAPACITY) && (
						<Popconfirm
							title={t('general.delete_warning')}
							description={t('general.delete_confirmation')}
							onConfirm={() => mutation.mutate(record.id)}
							okText={t('general.yes')}
							cancelText={t('general.no')}
						>
							<Button type='link' icon={<DeleteOutlined />} danger>
								{t('general.delete')}
							</Button>
						</Popconfirm>
					)}
				</Space>
			)
		}
	]

	return (
		<>
			<HeaderCard
				title={t('vehicle_capacity.label')}
				onSearch={(value) => {
					setSearch(value.length === 0 ? undefined : value)
				}}
				trailing={[
					can(CAN_CREATE_CAPACITY) ? (
						<Button
							type='primary'
							icon={<PlusOutlined />}
							onClick={() => navigate('/vehicle-capacity/add')}
						>
							{t('general.add')}
						</Button>
					) : null,
					can(CAN_EXPORT_CAPACITIES) ? (
						<>
							<Button
								type='default'
								icon={<FileExcelOutlined />}
								onClick={() => setExportModalVisible(true)}
							>
								{t('general.export_to_excel')}
							</Button>
							<ExportToExcelModal
								visible={exportModelVisible}
								setVisible={setExportModalVisible}
								exportUrl={'/capacity/export'}
								columns={[
									{ name: 'id', label: 'general.id' },
									{ name: 'name', label: 'vehicle_capacity.name' },
									{ name: 'note', label: 'vehicle_capacity.note' }
								]}
							/>
						</>
					) : null
				].filter((i) => i != null)}
			/>
			<Card bordered={false} bodyStyle={{ padding: 0 }}>
				<Table
					loading={query.isFetching}
					columns={columns}
					dataSource={query.data?.data.data}
					rowKey={(record) => record.id}
					style={{ overflowX: 'scroll' }}
					onChange={(_, __, sorter, ___) => {
						const { columnKey, order } =
							sorter as SorterResult<IVehicleCapacity>

						if (!order) {
							setSort(undefined)
						} else {
							setSort({
								name: columnKey as string,
								direction: order === 'ascend' ? 'asc' : 'desc'
							})
						}
					}}
					pagination={{
						current: page,
						pageSize: perPage,
						pageSizeOptions: [5, 10, 20, 50, 100],
						showSizeChanger: true,
						total: query.data?.data.total,
						position: ['bottomCenter'],
						onChange(page, pageSize) {
							setPage(page)
							setPerPage(pageSize)
						},
						showTotal: (total, range) => {
							return `${t('general.showing')} ${range[0]} - ${range[1]} ${t(
								'general.from'
							)} ${total} ${t('general.items')}`
						}
					}}
				/>
			</Card>
		</>
	)
}

export default VehicleCapacitiesPage
