import { createContext } from 'react'
import { DEFAULT_ASYNC_FUNCTION } from '../../utils/constants'
import { IDriverLight } from '../../models/driver'

export type SocketLoading =
  | 'drivers-have-request'
  | 'available-drivers-with-no-request'

export interface IState {
  loading: SocketLoading[]
  driversHaveRequest?: IDriverLight[]
  availableDriversWithNoRequest?: IDriverLight[]
}

export const initState: IState = {
  loading: [],
  driversHaveRequest: [],
  availableDriversWithNoRequest: [],
}

interface IActions {
  getDriversHaveRequest: () => Promise<boolean>
  getAvailableDriversWithNoRequest: () => Promise<boolean>
}

const initActions: IActions = {
  getDriversHaveRequest: DEFAULT_ASYNC_FUNCTION,
  getAvailableDriversWithNoRequest: DEFAULT_ASYNC_FUNCTION,
}

const SocketContext = createContext({
  ...initState,
  actions: { ...initActions },
})

export default SocketContext
