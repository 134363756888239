import {
	IRequestLocalizedProperty,
	LocalizedProperty
} from './localized-property'
import { IUserLight } from './user'

export enum CouponStatus {
	ACTIVE = 1,
	NOT_ACTIVE = 2
}

export interface ICoupon {
	id: number
	name: string
	code: string
	value: number
	status: CouponStatus
	isLimit: boolean
	maxOfUse?: number
	numOfUse: number
	startDate: string
	endDate: string
}

export interface ICouponLight {
	id: number
	name: string
	value: number
}

export interface ICouponDetails extends ICoupon {
	note?: string
	createdAt?: Date
	createdBy?: IUserLight
	updatedAt?: Date
	updatedBy?: IUserLight
	translations: {
		name: LocalizedProperty
	}
}

export interface IAddCoupon {
	name: IRequestLocalizedProperty
	note?: string
	code: string
	value: number
	status: CouponStatus
	isLimit: boolean
	maxOfUse?: number
	startDate: string
	endDate: string
}

export interface IEditCoupon {
	name: IRequestLocalizedProperty
	note?: string
	code: string
	value: number
	status: CouponStatus
	isLimit: boolean
	maxOfUse?: number
	startDate: string
	endDate: string
}

export interface ICouponUsage {
	id: number
	name: string
	value: number
	code: string
	firstName: string
	lastName: string
	couponActivationDate: Date
}
