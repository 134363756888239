import { IUserDetails } from '../../models/user'
import { toggleLoading } from '../../utils/helpers/functions'
import { AuthLoading, IState } from './context'

export type Action =
  | {
      type: 'LOADING'
      payload: { loading: AuthLoading | AuthLoading[] }
    }
  | { type: 'SET_IS_AUTH'; payload: { isAuth: boolean } }
  | { type: 'GET_ME'; payload: { user: IUserDetails } }
  | { type: 'LOGIN'; payload: { user: IUserDetails } }
  | { type: 'LOGOUT' }

const reducer = (state: IState, action: Action): IState => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      }
    case 'SET_IS_AUTH':
      return { ...state, isAuth: action.payload.isAuth }
    case 'GET_ME':
      return { ...state, authUser: action.payload.user }
    case 'LOGIN':
      return { ...state, authUser: action.payload.user }
    case 'LOGOUT':
      return {
        ...state,
        authUser: undefined,
        isAuth: false,
      }
    default:
      return state
  }
}

export default reducer
