import { Card, Spin } from 'antd'
import { FC } from 'react'
import { IStatisticsKnowApp } from '../../../../models/home'
import { Pie } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

interface IProps {
  knowApp?: IStatisticsKnowApp
  loading: boolean
}

const AppKnowledgeChart: FC<IProps> = (props) => {
  const { t } = useTranslation()

  const data = {
    // HowKnowApp enum from models/client
    // 	FRIEND = 1,
    //  MARKETING = 2,
    //  SOCIAL_NETWORKING_SITES = 3
    labels: [
      t('home.FRIEND'),
      t('home.MARKETING'),
      t('home.SOCIAL_NETWORKING_SITES'),
    ],

    datasets: [
      {
        data: [props.knowApp?.[1], props.knowApp?.[2], props.knowApp?.[3]],
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
        ],
        hoverBackgroundColor: [
          'rgba(255, 99, 132, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 206, 86, 0.8)',
        ],
      },
    ],
  }

  return (
    <Spin spinning={props.loading}>
      <Card
        title={t('home.app_known_analysis')}
        headStyle={{
          textAlign: 'center',
        }}
        bodyStyle={{
          height: '20rem',
        }}
      >
        <Pie
          data={data}
          options={{
            responsive: true,
            maintainAspectRatio: false,
          }}
        />
      </Card>
    </Spin>
  )
}

export default AppKnowledgeChart
